.box {
  box-shadow: 0 0 rem-calc(20) rgba($black, .2);

  &.box--padding {
    padding: #{$global-padding *2};

    @include breakpoint(phablet down) {
      padding: $global-padding;
    }
  }

  &.box--no-shadow {
    box-shadow: 0 0 0 0;
  }

  &.box--small {
    max-width: 336px;
  }

  &.box--margin-left {
    margin-left: $global-margin;

    @include breakpoint(phablet down) {
      margin-left: 0;
    }
  }

  &.box--padding-bottom {
    padding-bottom: #{$global-padding * 2};
  }

  &.box--center-small {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
}

.box--small-text {
  p {
    font-size: rem-calc(15);
    line-height: 1.9;
  }
}

.box--border-top {
  border-top: 8px solid $light-brown;
}

.box--hidden-small {
  @include breakpoint(phablet down) {
    box-shadow: 0 0 0 0 !important;
    border-radius: 0 !important;
  }
}
