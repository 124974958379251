/* YAPL
name: Tabs Horizontal
partial: sg-tabs-horizontal
selector: .basic-tabs.tabs
*/

/* YAPL
name: Tabs Vertical
partial: sg-tabs-vertical
selector: .basic-tabs.tabs.tabs--vertical
*/

.basic-tabs {
  border: 0;

  .tabs-content,
  .tabs-title {
    box-shadow: 0 0 1.25rem rgba(10, 10, 10, .2);
  }

  .tabs-title {
    margin-right: rem-calc(10);

    a {
      background-color: $white;
      box-shadow: inset 0 10px 0 0 $primary-color;
      font-weight: 600;
      height: rem-calc(60);
      text-align: center;
      text-transform: uppercase;

      @include breakpoint(phablet up) {
        font-size: rem-calc(18);
        min-width: rem-calc(154);
      }

      @include breakpoint(small only) {
        font-size: rem-calc(13);
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    &.is-active a {
      box-shadow: inset 0 10px 0 0 $dark-blue;
      position: relative;
      z-index: 1;

      // shadow cover shim
      &::after {
        background-color: $tab-background-active;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
      }
    }
  }

  .tabs-content {
    position: relative;
  }

  &.tabs--contact-form {
    .tabs-title {
      a {
        text-transform: capitalize;
      }
    }

    .tabs-panel {
      padding: 1rem 2rem 2rem;

      @include breakpoint(medium) {
        padding: 1rem 2.5rem 2.5rem;
      }
    }
  }

  &.tabs--vertical {


    .tabs-content {
      justify-content: flex-start;
      min-height: 400px;
    }

    .tabs-title {
      @extend %vertical-margin-half;
      align-items: center;
      border-left: rem-calc(10px) solid $medium-blue;
      box-shadow: 0 0 rem-calc(20) rgba($black, .2);
      display: flex;
      min-height: 76px;
      transition: border-left-color .25s;
      width: 100%;
      //right: rem-calc(-$tab-shadow-weight);

      &:first-child {
        @extend %vertical-margin-0;
      }

      &.is-active,
      &:hover,
      &:focus {
        border-left-color: $dark-blue;

        > a {
          color: $dark-blue;
        }
      }

      &.is-active {
        box-shadow: 0 0 rem-calc(20) rgba($black, .2);
        width: calc(100% + 1px);
      }

      > a {
        border-top: 0;
        box-shadow: 0 0 0 0;
        color: $medium-blue;
        display: flex;
        flex-direction: column;
        font-size: rem-calc(16);
        justify-content: center;
        min-height: 76px;
        padding: $global-padding;
        text-align: left;
        text-transform: capitalize;
        width: 100%;

        &::after {
          //background-color: $tab-background-active;
          content: '';
          height: 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 100%;
        }
      }
    }
  }
}
