.rounded-corner {
  border-bottom-left-radius: 60px;
}

.rounded-corner--small {
  border-bottom-left-radius: 30px;
}

.rounded-corner--top-right {
  border-top-right-radius: 60px;

  @include breakpoint(medium down) {
    border-top-right-radius: 0;
  }
}
