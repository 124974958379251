/* YAPL
name: Hero
notes: parallax scroll disabled in styleguide; z-index changes for styleGuide at bottom (look for .sg-block at bottom of this file)
partial: sg-hero
context: hero.homepage
selector: .hero
*/

/* YAPL
name: Hero Mastersub
notes: parallax scroll disabled in styleguide
partial: sg-hero
context: hero.mastersub
selector: .hero--sub
*/

/* YAPL
name: Hero Mastersub Message
notes: parallax scroll disabled in styleguide
partial: sg-hero
context: hero.locationDetail
selector: .hero--sub.hero--sub--message
*/

/* YAPL
name: Hero Campaign
notes: z-index changes and removed negative top margin for styleguide
partial: sg-hero
context: hero.campaign
selector: .hero--campaign
*/

/* YAPL
name: Hero Microsite
notes: z-index changes and removed negative top margin for styleguide
partial: sg-hero
context: hero.microsite
selector: .hero--campaign.hero--microsite
*/


.hero {
  display: flex;
  flex-flow: column nowrap;
  min-height: rem-calc(250);
  position: relative;

  @include breakpoint(large up) {
    height: 550px;
  }

  @include breakpoint(phablet down) {
    height: 178px;
    min-height: auto;
    padding: 0;
  }

  .hero--sub & {
    @include breakpoint(large up) {
      height: 336px;
    }

    @include breakpoint(medium only) {
      height: 240px;
      min-height: 240px;
    }

    @include breakpoint(phablet down) {
      height: auto;
      min-height: auto;
      padding: 0;
    }
  }

  .hero--sub--message & {
    @include breakpoint(medium only) {
      height: auto;
    }
  }

  .hero--campaign & {
    @include breakpoint(large up) {
      height: 685px;
    }

    @include breakpoint(phablet down) {
      height: auto;
      min-height: auto;
      padding: 0;
    }

  }
}

.hero__wrap {
  @include breakpoint(large up) {
    height: 550px;
  }
  position: relative;

  &.hero--sub {
    height: 336px;

    @include breakpoint(medium only) {
      height: 240px;
      min-height: 240px;

      &.hero--sub--message {
        height: auto;
      }
    }

    @include breakpoint(phablet down) {
      height: auto;
      min-height: 130px;
    }
  }

  &.hero--campaign {
    height: 685px;
    margin-top: -118px;



    @include breakpoint(medium down) {
      height: auto;
      min-height: 210px;
    }

    &.hero--microsite {
      margin-top: -157px;
    }
  }
}

.hero__image {
  font-family: 'object-fit: cover; object-position: top';
  height: 550px;
  min-height: 550px;
  object-fit: cover;
  object-position: top;
  z-index: -2;


  @include breakpoint(large up) {
    opacity: 0;
    transition: opacity 1.5s;

    &.is-animating {
      opacity: 1;
    }
  }

  @include breakpoint(medium only) {
    height: 400px;
    min-height: 400px;
  }

  @include breakpoint(phablet down) {
    height: auto;
    min-height: 178px;
  }

  .hero--sub & {
    height: 336px;
    min-height: 336px;

    @include breakpoint(medium only) {
      height: 240px;
      min-height: 240px;
    }

    @include breakpoint(phablet down) {
      height: 130px;
      min-height: 130px;
    }
  }

  .hero--campaign & {
    height: 685px;
    min-height: 685px;

    @include breakpoint(phablet down) {
      height: 210px;
      min-height: 210px;
    }
  }
}

.hero__pos-state-wrap {
  position: relative;
  top: -85%;
  width: 100%;
  z-index: 5;

  @include breakpoint(medium only) {
    position: absolute;
    top: 30px;

    &.hero__pos-state-wrap--sub {
      position: relative;
    }

    .hero--sub--message & {
      top: 0;
    }
  }

  @include breakpoint(phablet down) {
    position: absolute;
    top: 0;

    &.hero__pos-state-wrap--sub {
      position: relative;
    }
  }

  .hero--campaign & {
    top: -68%;

    @include breakpoint(medium down) {
      background-color: $white;
      position: relative;
    }
  }

  .hero--microsite &,
  .hero--campaign & {
    @include breakpoint(medium only) {
      background-color: transparent;
      position: absolute;
      top: 220px;
    }
  }
}

.hero__pos-state {
  color: $dark-blue;
  font-family: $body-font-family-alt;
  font-size: rem-calc(45);
  font-style: italic;
  font-weight: $global-weight-medium;
  height: 100%;
  line-height: $subheader-lineheight;
  opacity: 1;
  position: relative;
  transition: margin-top #{$global-transition-speed * 3} ease-in, opacity #{$global-transition-speed * 3} ease-in;
  width: 45%;
  z-index: 3;

  &::after {
    background-color: $orange;
    bottom: -27px;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: relative;
    width: 142px;
  }

  @include breakpoint(large up) {
    opacity: 0;
    transition: opacity 2s;

    &.is-animating {
      opacity: 1;
    }
  }

  @include breakpoint(medium only) {
    font-size: rem-calc(40);
    margin-top: 24px !important; // sass-lint:disable-line no-important

    &::after {
      bottom: -18px;
      width: 100px;
    }
  }

  @include breakpoint(phablet down) {
    font-size: rem-calc(21);
    left: 24px;
    margin-top: 12px !important; // sass-lint:disable-line no-important
    top: 16px;
    width: 60%;

    &::after {
      bottom: -10px;
      height: 6px;
      width: 64px;
    }
  }
}

.hero__textbox {
  background-color: rgba($green-blue, .8);
  height: 100%;
  padding: #{$global-padding * 1.5} #{$global-padding * 2};
  width: 40%;

  h2 {
    font-size: 37px;
    font-weight: 600;

    .hero--campaign & {
      color: $white;
      font-size: 42px;

      @include breakpoint(phablet down) {
        color: $black;
        font-size: 28px;
      }
    }
  }

  p {
    font-weight: 600;

    .hero--campaign & {
      color: $white;
      font-size: 20px;

      @include breakpoint(phablet down) {
        color: $black;
        font-size: 14px;
      }
    }
  }

  &.hero__textbox--large-text {
    h2 {
      font-size: 59px;

      @include breakpoint(medium only) {
        font-size: 42px;
      }

      @include breakpoint(phablet down) {
        font-size: 31px;
      }
    }

    p {
      font-size: 24px;

      @include breakpoint(medium only) {
        font-size: 20px;
      }

      @include breakpoint(phablet down) {
        font-size: 16px;
      }
    }
  }
  @include breakpoint(medium down) {
    background-color: $green-blue;
    border-bottom-left-radius: 20px;
    outline: 4px solid rgba($white, .4);
    position: relative;
    top: -26px;
    width: 100%;
  }

  @include breakpoint(small only) {
    top: -26px;
  }

  &.hero__textbox--transparent {
    background: transparent;
    width: 90%;

    @include breakpoint(medium down) {
      outline: 0;
      width: 100%;
    }

    @include breakpoint(phablet down) {
      padding: 1rem;
      top: 0;
    }

    .button {

      &:hover {
        background-color: #14679e;
        color: $white;
      }
    }

    h2,
    p {
      @include breakpoint (phablet down) {
        color: $caption-gray !important;
      }
    }
  }

  &.hero__textbox--center {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.hero__textbox-border {
  background-color: $green-blue;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}

.hero__directions-wrap {
  position: relative;
}

.hero-directions {
  align-items: center;
  background-color: rgba($white, .7);
  bottom: 0;
  display: flex;
  padding: #{$global-padding};
  position: absolute;
  right: 0;
  z-index: 5;

  p {
    font-size: rem-calc(15);
    margin-bottom: 0;

    @include breakpoint(phablet down) {
      font-size: rem-calc(13);
    }
  }

  a {
    font-size: rem-calc(15);

    @include breakpoint(phablet down) {
      font-size: rem-calc(13);
    }
  }

  .icon {
    flex-shrink: 0;
    font-size: rem-calc(28);

    @include breakpoint(medium down) {
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }

  @include breakpoint(medium down) {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px;
    position: relative;
    top: -18px;
    width: 100%;
  }
}

.hero-directions__link {
  margin-left: $global-margin;

  @include breakpoint(phablet down) {
    margin-left: #{$global-margin / 2};
  }
}

.hero__overlay {
  margin-top: -150px;
  position: relative;
  z-index: 1;

  &.hero__overlay--small {
    margin-top: -46px;
  }

  &.hero__overlay--microsite {
    @include breakpoint(medium down) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint(phablet down) {
    margin-top: 0;
  }
}

//fixes for styleGuide
.sg-block .hero__image {
  z-index: 0;
}

.sg-block .hero .diagonals {
  opacity: .5;
  z-index: 2;
}

.sg-block .hero .diagonals--darken {
  z-index: 1;
}

.sg-block .hero-nav__container.hero-nav--campaign {
  z-index: 3;
}

.sg-block .hero__wrap.hero--campaign {
  margin-top: 0;
}
