/* YAPL
name: Padded Box
notes: 1rem padding around content
partial: sg-padded-box
selector: .padded-box-1
*/

/* YAPL
name: Padded Box 1.5
notes: 1.5rem padding around content
partial: sg-padded-box-1-5
selector: .padded-box-1-5
*/

/* YAPL
name: Padded Box 2
notes: 2rem padding around content
partial: sg-padded-box-2
selector: .padded-box-2
*/

.padded-box-1 {
  padding: $global-padding;
}

.padded-box-1-5 {
  padding: #{$global-padding * 1.5};
}

.padded-box-2 {
  padding: #{$global-padding * 2};

  @include breakpoint(phablet down) {
    padding: #{$global-padding * 1.5};
  }
}
