.hot-jobs__item {
  border-bottom: 1px solid $border-gray;
  display: flex;
  padding: .75rem 0 1.25rem;
}

.hot-jobs__job {
  .hot-jobs__category {
    color: $light-brown;
    font-weight: $global-weight-normal;
    margin-bottom: 0;
  }

  .hot-jobs__job-name {
    margin-bottom: 0;
    margin-top: .25rem;
  }
}

.hot-jobs__location {
  align-items: flex-end;
  display: flex;
  margin-left: auto;
  margin-right: 0
}
