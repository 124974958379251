.nav-utility {
  background-color: $dark-blue;
  font-weight: $global-weight-semibold;
  height: 38px;
  position: relative;
  transition: height $global-transition-speed;

  .is-stuck & {
    height: 12px;

    .is-expanded & {
      height: 38px;
    }
  }
}

.nav-utility__inner {
  position: relative;
}

.nav-utility__links {
  color: $pure-white;
  font-size: rem-calc(12);
  padding-right: 267px;

  .nav-utility__link {
    color: inherit;

    &:hover {
      color: $light-blue;
    }

    @include breakpoint (large up) {
      height: 38px;
      line-height: 38px;
      padding: 0 .75rem;

      .is-stuck & {
        pointer-events: none;
      }
    }
  }
}

.nav-utility__expand-tab {
  background-color: $dark-blue;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: none;
  font-size: rem-calc(13);
  height: 0;
  left: calc(50% - 30px);
  position: absolute;
  top: 10px;
  transition: height $global-transition-speed;
  width: 60px;

  .is-stuck & {
    display: block;
    height: 18px;

    .is-expanded & {
      display: none;
    }
  }

  .icon {
    color: $white;
    left: calc(50% - 6px);
    position: absolute;
    top: -2px;
  }
}
