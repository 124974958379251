/* YAPL
name: Breadcrumb Navigation
partial: breadcrumbs
context: breadcrumbs.mastersub
selector: .breadcrumbs
*/

.breadcrumbs {
  align-items: center;
  background-color: $medium-blue;
  display: flex;
  font-size: 16px;
  height: 56px;
  list-style-type: none;
  margin-bottom: 0;

  @include breakpoint(medium down) {
    display: none;
  }

  ul {
    list-style-type: none;

    li {
      font-size: 14px;
      text-transform: capitalize;

      &::after {
        content: '' !important; // sass-lint:disable-line no-important
      }

      &:last-child {
        a {
          text-decoration: none;
        }
      }

      .icon {
        font-size: 10px;
      }
    }

    a {
      color: $white;
      padding-right: 20px;
      text-decoration: underline;
      text-decoration-color: $light-blue;
      transition: $global-transition-speed;

      &:hover {
        color: $light-blue;
      }
    }
  }
}
