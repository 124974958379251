.contact {
  background-color: $medium-blue;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: $white;
  font-family: $body-font-family-alt;
  font-size: rem-calc(17);
  font-weight: $global-weight-bold;
  height: 51px;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  transition: height $global-transition-speed;
  width: 239px;

  &:hover {
    color: $white;
  }

  @include breakpoint(large up) {
    pointer-events: none;
  }

  .footer-main & {

    @include breakpoint(phablet down) {
      left: calc(50% - 120px);
      right: auto;
    }
  }

  .is-stuck & {
    height: 0;

    .is-expanded & {
      height: 51px;
    }
  }

  .nav-main & {
    background-color: $medium-blue;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    color: $white;
    height: 51px;
    line-height: 51px;
    margin-bottom: 0;
    margin-top: 30px;
    padding: 0 !important; // sass-lint:disable-line no-important
    position: relative;
    width: 239px;

    .icon {
      display: inline;
    }
  }

  .sublocation & {
    background-color: $green-blue;
  }
}
