.back-to-top {
  color: $caption-gray;
  display: block;
  font-weight: $global-weight-semibold;
  text-align: right;

  .icon {
    color: $medium-blue;
  }

  &:hover {

    .icon {
      color: $orange;
    }
  }
}
