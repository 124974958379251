.diagonals {
  background-image: url('../img/diagonals.png');
  background-repeat: repeat;
  height: 100%;
  left: 0;
  position: absolute;
  transition: opacity $global-transition-speed;
  width: 100%;
  z-index: 1;

  &.is-active {
    opacity: .5;
  }

  &.diagonals--blue {
    background-color: rgba($dark-blue, .75);
  }

  &.diagonals--transparent {
    opacity: .5;
    transition: opacity $global-transition-speed;

    .hero & {
      opacity: 0;

      &.is-animating  {
        opacity: .5;
      }
    }
  }

  &.diagonals--very-transparent {
    opacity: .2;
  }

  .hero & {
    z-index: -1;
  }
}

.diagonals--darken {
  background-color: $black;
  background-repeat: repeat;
  height: 100%;
  left: 0;
  opacity: .35;
  position: absolute;
  transition: opacity $global-transition-speed;
  width: 100%;
  z-index: 1;
  
  .hero & {
    z-index: -1;
  }
}
