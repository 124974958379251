/* YAPL
name: List
partial: sg-list
selector: .rtf ul
wrapperClasses: rtf
*/

/* YAPL
name: List No Bullets
notes: Often used in conjunction with List No Indent
partial: sg-list-no-bullets
selector: .list--no-bullets
*/

/* YAPL
name: List No Indent
notes: Often used in conjunction with List No Bullets
partial: sg-list-no-indent
selector: .list--no-indent
*/

/* YAPL
name: List Double Spaced
partial: sg-list-double-spaced
selector: .list--double-spaced
*/

/* YAPL
name: List Portlet
partial: sg-list-portlet
selector: .list--portlet
*/

/* YAPL
name: List with Carets
notes: Use in conjunction with List No Bullets
partial: sg-list-carets
selector: .list--carets
*/


.list--no-bullets {
  list-style-type: none;

  @include breakpoint (medium down) {
    margin-left: 0;
  }
}

.list--no-indent {
  margin-left: 0;
}

.list--double-spaced {
  li {
    margin-bottom: #{$global-margin * 2};
  }
}

.list--portlet {
  li {
    border-bottom: 1px solid $light-gray;
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    margin-bottom: $global-margin;
    padding-bottom: $global-padding;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.list--carets {

  li {
    background-image: url('../img/icon-list-caret.png');
    background-position: 0 5px;
    background-repeat: no-repeat;
    font-weight: $global-weight-semibold;
    margin-bottom: $global-margin;
    padding-left: 20px;

    a {
      color: $text-color;

      &:hover {
        color: $orange;
      }
    }

    @include breakpoint (phablet down) {
      font-size: rem-calc(14);
    }
  }

  @include breakpoint (medium down) {
    margin-left: 0;
  }
}

.list--columns-2 {
  columns: 2;

  @include breakpoint(phablet down) {
    columns: 1;
  }
}

// Event Landing list items

.event__list {
  h3 {
    color: $off-black;
  }
}

.event__item {
  border-bottom: 1px solid $border-gray;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
}

.event__buttons {
  a {
    margin-bottom: 0;

    @include breakpoint(phablet down) {
      display: block;
      margin-top: 1rem;
    }

    &:first-child {
      margin-right: 1rem;

      @include breakpoint(phablet) {
        margin-right: 2rem;
      }
    }

    &.button {
      margin-bottom: 0;
    }
  }
}

.event__date {
  align-items: center;
  background-color: $light-brown;
  border-radius: 50%;
  color: $white;
  display: flex;
  font-family: $body-font-family-alt;
  font-size: rem-calc(17);
  font-weight: $global-weight-bold;
  height: rem-calc(60);
  justify-content: center;
  line-height: 1.2;
  margin-right: 1rem;
  padding: 0 .75rem;
  text-align: center;
  text-transform: uppercase;
  width: rem-calc(60);

  @include breakpoint(medium) {
    font-size: rem-calc(20);
    height: rem-calc(78);
    margin-right: 2rem;
    padding: 0 1rem;
    width: rem-calc(78);
  }
}

.event__details {
  @include breakpoint(medium) {
    margin-left: 1.5rem;
  }

  p {
    margin: 0;
  }
}
