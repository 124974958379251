.careers-results {
  border-bottom: 1px solid $light-brown;

  table {
    margin-top: $global-margin;
    min-width: 600px;

    thead {
      background-color: transparent;
      border-bottom: 1px solid $light-brown;
      border-top: 1px solid $light-brown;
      color: $medium-brown;
    }

    tbody {
      tr {
        background-color: transparent !important;
        border-bottom: 1px solid $border-gray !important;
      }

      td {
        padding-bottom: .9rem;
        padding-top: .9rem;
      }

      h6 {
        margin-bottom: 0;
      }

      p {
        line-height: 1.2;
        margin: 0;
      }
    }
  }
}
