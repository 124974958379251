.dark-blue {
  color: $dark-blue !important; // sass-lint:disable-line no-important
}

.medium-blue {
  color: $medium-blue !important; // sass-lint:disable-line no-important
}

.green-blue {
  color: $green-blue !important; // sass-lint:disable-line no-important
}

.light-brown {
  color: $light-brown !important; // sass-lint:disable-line no-important
}

.medium-light-gray {
  color: $medium-light-gray !important; // sass-lint:disable-line no-important
}

.dark-gray {
  color: $caption-gray !important; // sass-lint:disable-line no-important
}

.white {
  color: $white !important; // sass-lint:disable-line no-important
}
