.tile {

  overflow: hidden;
  position: relative;

  @include breakpoint(phablet down) {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;

    &.cell {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:hover {
    .tile__image {
      transform: scale(1.1);
    }

    .tile__text {
      color: $orange;

      .icon--arrow-right {
        color: #505050;
      }
    }
  }

  &.tile--shadow {
    box-shadow: 0 0 rem-calc(10) rgba($black, .25);
  }
}

.tile--max-width {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 300px;
}

.tile__header {
  color: $off-black;
  font-size: rem-calc(36);

  @include breakpoint(phablet down) {
    font-size: rem-calc(28);
    text-align: center;
  }
}

.tile__image {
  overflow: hidden;
  position: relative;
  transition: transform $global-transition-speed;
}

.tile__tag {
  background-color: rgba(0, 0, 0, .2);
  border: 1px solid $white;
  color: $white;
  font-size: rem-calc(12);
  font-weight: 600;
  left: 18px;
  padding: #{$global-padding / 4} #{$global-padding / 2};
  position: absolute;
  text-transform: uppercase;
  top: 20px;
  z-index: 9;

  &.tile__tag--blue {
    background-color: rgba($medium-blue, .9);
  }

  &.tile__tag--orange {
    background-color: rgba($orange, .9);
  }

  &.tile__tag--green {
    background-color: rgba($green-blue, .9);
  }

  &.tile__tag--brown {
    background-color: rgba($medium-brown, .9);
  }
}

.tile__text {
  background-color: $white;
  color: $text-color;
  font-weight: 600;
  padding: 44px #{$global-padding * 2} 10px;
  position: relative;
  text-align: center;
  transition: color $global-transition-speed;

  .icon--arrow-right {
    transition: color $global-transition-speed;
  }

  &.tile__text--equal-padding {
    font-weight: 400;
    padding: #{$global-padding * 1.5};
    text-align: left;
  }

  h4 {
    color: $orange;
    font-size: rem-calc(20);
    font-weight: 700;
  }

  p {
    font-size: rem-calc(14);
  }
}

.tile__text-link {
  font-size: rem-calc(14);
  font-weight: 600;
  text-transform: uppercase;

  .icon {
    margin-right: #{$global-margin / 2};
  }
}

.tile__icon {
  color: $white;
  left: calc(50% - 28px);
  position: absolute;
  top: -28px;
  z-index: 1;

  &::before {
    left: 15px;
    position: absolute;
    top: 15px;
  }

  &.icon--patient-story,
  &.icon--treatment {
    &::before {
      left: 16px;
    }
  }

  &.icon--treatment {
    &::before {
      top: 16px;
    }
  }
}

.tile__list {
  margin: $global-margin 0;

  > a {
    border-bottom: 1px solid $border-gray;
    display: block;
    padding: rem-calc(12) 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  &.tile__list--semibold  {

    > a {
      font-weight: $global-weight-semibold;
    }
  }

  &.tile__list--border-bottom  {

    > a {
      &:last-child {
        border-bottom: 1px solid $border-gray;
      }
    }
  }
}
