.future {
  position: relative;
}

.future__header {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 $global-margin;
  overflow: hidden;
  padding: 0 100px;
  position: relative;
  text-align: center;

  @include breakpoint(medium up) {
    height: 300px;
  }

  @include breakpoint(phablet down) {
    background-position: 100% 0;
    padding: #{$global-padding * 2} $global-padding;
  }
}

.future__header-text {
  position: relative;
  z-index: 2;

  h2 {
    color: $white;
    font-size: rem-calc(42);
    font-weight: $global-weight-bold;
  }

  p {
    font-size: rem-calc(19);
  }

  @include breakpoint(phablet down) {
    h2 {
      font-size: rem-calc(32);
    }

    p {
      font-size: rem-calc(14);
    }
  }
}

.future__item {
  margin-bottom: $global-margin;
  opacity: 0;
  position: relative;
  transform: scale(0);
  transform-origin: 50% 50% 0;
  transition: transform .5s, opacity .5s;

  .is-animating & {
    opacity: 1;
    transform: scale(1);
  }

  @include breakpoint(phablet down) {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
    opacity: 1;
    text-align: center;
    transform: scale(1);
  }

  &.future__item--video {
    cursor: pointer;

    &::before {
      background-color: $orange;
      border-radius: 25px;
      content: '';
      height: 50px;
      left: calc(50% - 25px);
      position: absolute;
      top: calc(50% - 25px);
      transition: opacity $global-transition-speed;
      width: 50px;

      @include breakpoint(phablet down) {
        display: none;
      }
    }

    &:hover {
      &::before {
        opacity: 0;
      }

      .icon {
        opacity: 0;
      }
    }

    .icon {
      color: $white;
      left: calc(50% - 7px);
      position: absolute;
      top: calc(50% - 10px);
      transition: opacity $global-transition-speed;
      z-index: 4;

      @include breakpoint (phablet down) {
        display: none;
      }
    }
  }

  &.future__item--story {
    cursor: pointer;
    overflow: hidden;

    img {
      transition: transform $global-transition-speed;
    }

    .icon--future-story {
      background-image: url('../img/icons/icon-patient_story.svg');
      background-repeat: no-repeat;
      height: 48px;
      margin-bottom: $global-margin;
      width: 56px;
    }

    .icon--arrow-right {
      position: relative;

      @include breakpoint (large up) {
        top: 80px;
        transition: $global-transition-speed;
      }
    }

    .icon--news,
    .icon--patient-story {
      border-radius: 28px;
      height: 56px;
      margin-bottom: $global-margin;
      position: relative;
      width: 56px;

      &::before {
        left: 15px;
        position: absolute;
        top: 15px;
      }
    }

    .icon--patient-story {
      &::before {
        left: 17px;
      }
    }

    &:hover {
      @include breakpoint (large up) {
        img {
          transform: scale(1.2);
        }

        .icon--arrow-right {
          transform: translateY(-80px);
        }
      }
    }
  }

  &:hover {

    .future__hidden-overlay {
      opacity: 1;

      .icon {
        opacity: 1;
      }
    }
  }
}

.future__video {
  display: none;
  max-width: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;

  &.is-visible {
    display: block;
  }

  .close-button {
    background-color: rgba($black, .5);
    border-radius: 28px;
    color: $orange;
    font-size: 44px;
    height: 56px;
    right: 20px;
    top: 40px;
    width: 56px;
  }
}

.future__hidden-overlay {
  align-items: center;
  background-color: rgba($dark-blue, .8);
  bottom: 0;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: $global-padding;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity .5s;
  width: 100%;

  @include breakpoint (phablet down) {
    opacity: 1;

    .icon {
      display: block !important; // sass-lint:disable-line no-important
    }
  }
}

.future__grid {

  .grid-container {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-margin-x > .cell {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .grid-margin-x > .medium-3 {
    width: calc(25% - 1rem);
  }

  @include breakpoint(small only) {
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.25rem);
    }
  }
}
