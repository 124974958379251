/* YAPL
name: More Section
partial: more-section
selector: .more-section
*/

/* YAPL
name: More Section - Microsite
partial: more-section-microsite
selector: .more-section.more-section--microsite
*/

.more-section__block {
  align-items: center;
  background-color: $medium-blue;
  border: 2px solid $medium-blue;
  display: flex;
  margin-bottom: 8px;
  margin-top: 0;
  padding: $global-padding;
  transition: background-color $global-transition-speed, border $global-transition-speed;

  &:hover {
    background-color: $white;
    border: 2px solid $orange;

    a {
      color: $orange;

      .icon {
        color: $orange;
      }
    }
  }

  a {
    align-items: center;
    color: $white;
    display: flex;
    font-size: rem-calc(15);
    font-weight: $global-weight-semibold;
    padding-right: 30px;
    width: 100%;
  }

  .icon {
    color: $white;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    right: -30px;
    top: 2px;

  }

  .more-section--microsite & {
    border-radius: 32px;
    min-height: 64px;
  }
}
