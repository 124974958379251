.grid-container {
  @include breakpoint(medium down) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.grid-full-small {
  @include breakpoint(medium down) {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-margin-wide {

  @include breakpoint(medium up) {
    .cell {
      &.medium-6 {
        width: calc(50% - 2rem);

        &:first-child {
          margin-left: 0;
          margin-right: 2rem;
        }

        &:last-child {
          margin-left: 2rem;
          margin-right: 0;
        }
      }
    }
  }
}
