/* YAPL
name: Doc Profile
partial: sg-doc-profile
selector: .doc-profile
*/

/* YAPL
name: Doc Profile Result
partial: sg-doc-profile-result
selector: .doc-profile.doc-profile-result
*/

/* YAPL
name: Doc Profile Small
partial: sg-doc-profile-small
selector: .doc__item
*/

/* YAPL
name: Doc Profile Small - 3 across
partial: sg-doc-profile-small-3
selector: .doc__item
wrapper: .doc__row.doc__row-3
*/

.doc-profile {
  @include clearfix;

  @include breakpoint(medium down) {
    padding-bottom: .5rem !important; // sass-lint:disable-line no-important
    padding-left: 0 !important; // sass-lint:disable-line no-important
    padding-right: 0 !important; // sass-lint:disable-line no-important
    padding-top: .5rem !important; // sass-lint:disable-line no-important

    &.background--gray {
      padding: 1rem !important; // sass-lint:disable-line no-important
    }
  }
}

.doc-profile__image {
  float: left;
  margin-bottom: $global-margin;
  margin-right: 24px;
  max-width: 150px;
}

.doc-profile__image-link {

  &:hover {
    color: $orange;
  }
}

.doc-profile__body {
  float: left;
  width: calc(100% - 174px);

  @include breakpoint(small only) {
    float: none;
    width: 100%;
  }
}

.doc-profile__col {
  float: left;
  margin-right: 1%;
  width: 49%;

  @include breakpoint(phablet down) {
    float: none;
    width: 100%;
  }

  &.doc-profile__col--small {
    margin-right: 1%;
    width: 30%;

    @include breakpoint(medium down) {
      width: 49%;
    }

    @include breakpoint(small only) {
      margin-right: 0;
      width: 100%;
    }
  }

  &.doc-profile__col--badges {
    width: 37%;

    @include breakpoint(medium down) {
      margin-top: $global-margin;
      width: 100%;
    }
  }
}

.doc-profile__badge {
  float: left;
  margin-bottom: #{$global-margin / 2};
  margin-right: #{$global-margin / 2};
}

.doc-profile__toggle {
  display: block;
  position: relative;

  @include breakpoint(large up) {
    pointer-events: none;

    .icon {
      display: none;
    }
  }

  .icon {
    font-size: rem-calc(14);
    position: absolute;
    right: 0;
    top: 2px;

    .expanded & {
      &::before {
        content: 'd' !important; // sass-lint:disable-line no-important
      }
    }
  }
}

.doc-profile-result {
  @include breakpoint(medium up) {
    padding: 2rem 2rem 1rem !important; // sass-lint:disable-line no-important
  }
}

.doc-profile__filter-inner {
  @include breakpoint(medium down) {
    display: none;

    .expanded & {
      display: block;
    }
  }
}

.doc-profile__refine-col {
  background-color: #f5f5f5;
  height: 100%;
}

//used on center landing
.doc__item {
  display: flex;
  float: left;
  margin-right: $global-margin;
  width: calc(50% - 1rem);

  .doc-profile__image {
    margin-right: 12px;
  }

  @include breakpoint(medium down) {
    float: none;
    width: 100%;
  }

  .doc__row-3 & {
    margin-bottom: #{$global-margin * 2};
    width: calc(33% - 1rem);

    &:nth-child(3n + 1) {
      clear: both;
    }

    @include breakpoint(medium only) {
      float: left;
      width: calc(50% - 1rem);

      &:nth-child(3n + 1) {
        clear: none;
      }

      &:nth-child(2n + 1) {
        clear: both;
      }
    }

    @include breakpoint(phablet down) {
      float: none;
      width: 100%;
    }
  }
}
