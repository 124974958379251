
.icon--phone:before { content: '\e800'; } /* '' */
.icon--search:before { content: '\e801'; } /* '' */
.icon--minus:before { content: '\e802'; } /* '' */
.icon--plus:before { content: '\e803'; } /* '' */
.icon--th:before { content: '\e804'; } /* '' */
.icon--twitter:before { content: '\e805'; } /* '' */
.icon--instagram:before { content: '\e806'; } /* '' */
.icon--youtube-play:before { content: '\e807'; } /* '' */
.icon--linkedin:before { content: '\e808'; } /* '' */
.icon--briefcase:before { content: '\e809'; } /* '' */
.icon--times:before { content: '\e80a'; } /* '' */
.icon--appt:before { content: '\e80b'; } /* '' */
.icon--arrow-right:before { content: '\e80c'; } /* '' */
.icon--instagram-1:before { content: '\e80d'; } /* '' */
.icon--location:before { content: '\e80e'; } /* '' */
.icon--make-an-appt:before { content: '\e80f'; } /* '' */
.icon--news:before { content: '\e810'; } /* '' */
.icon--physician:before { content: '\e811'; } /* '' */
.icon--facebook:before { content: '\e812'; } /* '' */
.icon--play:before { content: '\e813'; } /* '' */
.icon--view-all-event:before { content: '\e814'; } /* '' */
.icon--chevron-right:before { content: '\e815'; } /* '' */
.icon--find-a-doc:before { content: '\e816'; } /* '' */
.icon--patient-story:before { content: '\e817'; } /* '' */
.icon--chevron-down:before { content: '\e818'; } /* '' */
.icon--envelope-o:before { content: '\e819'; } /* '' */
.icon--treatment:before { content: '\e81a'; } /* '' */
.icon--mail:before { content: '\e81b'; } /* '' */
.icon--chevron-left:before { content: '\e81c'; } /* '' */
.icon--check:before { content: '\e81d'; } /* '' */
.icon--reset:before { content: '\e81e'; } /* '' */
.icon--arrow-left:before { content: '\e81f'; } /* '' */
.icon--chevron-up:before { content: '\e820'; } /* '' */
.icon--print:before { content: '\e821'; } /* '' */

@font-face {
  font-family: 'lifebridge';
  src: url('../fonts/fontello.eot?36924966');
  src: url('../fonts/fontello.eot?36924966#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?36924966') format('woff2'),
       url('../fonts/fontello.woff?36924966') format('woff'),
       url('../fonts/fontello.ttf?36924966') format('truetype'),
       url('../fonts/fontello.svg?36924966#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon-character {
  // sass-lint:disable-block no-important no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'lifebridge' !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 1;
  speak: none;
  text-transform: none !important;
}

@mixin icon($name: null) {
  display: inline-block;
  font-size: 1em;
  margin-right: .3em;
  vertical-align: middle;

  &::before {
    @include icon-character;
  }
}

%icon,
.icon {
  @include icon;

  .cta &,
  .rtf &,
  p & {
    display: inline-block;
  }
}

.icon--small {
  font-size: rem-calc(12);
}

.icon--large {
  font-size: rem-calc(24);
}

.icon--very-large {
  font-size: rem-calc(48);
}

.icon--center {
  margin-right: 0;
}

.icon--circle {
  border-radius: 28px;
  height: 56px;
  width: 56px;
}

.icon--margin-right-half {
  margin-right: #{$global-margin / 2} !important;
}

.icon--reverse {
  transform: rotate(180deg);
}

.icon__background {
  background-color: $orange;
  color: $white;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: background-color .3s;

  &::before {
    position: absolute;
  }
}

.icon__background--large {
  border-radius: 25px;
  height: 50px;
  width: 50px;

  &::before {
    left: calc(50% - 5px);
    top: calc(50% - 5px);
  }
}

.icon__background--medium {
  border-radius: 21px;
  height: 42px;
  width: 42px;

  &::before {
    left: calc(50% - 14px);
    position: absolute;
    top: calc(50% - 14px);
  }
}

.icon__background--small {
  border-radius: 18px;
  height: 36px;
  width: 36px;

  &::before {
    left: calc(50% - 7px);
    top: calc(50% - 7px);
  }
}

.icon__background--very-small {
  border-radius: 12px;
  height: 24px;
  width: 24px;

  &::before {
    font-size: rem-calc(10);
    left: calc(50% - 4px);
    top: calc(50% - 5px);
  }
}

.icon--position-center {
  left: 50%;
  margin-right: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.icon--play {
    padding-left: 3px;
    padding-top: 4px;
  }
}
