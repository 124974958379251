.search__toggle {
  background-color: $light-gray;
  border-radius: 40px;
  display: block;
  height: 40px;
  position: relative;
  transition: background-color $global-transition-speed, color $global-transition-speed;
  width: 40px;

  @include breakpoint(large up) {
    &:hover {
      background-color: $orange;

      .icon {
        color: $white;
      }
    }
  }

  .icon {
    font-size: 1.15em;
    left: 11px;
    position: absolute;
    top: 8px;
  }

  &.is-close {
    background-color: $white;

    .icon {
      &::before {
        content: 's' !important; // sass-lint:disable-line no-important
      }
    }
  }

  &.search--sublocation {
    background-color: $medium-blue;
    color: $white !important;  // sass-lint:disable-line no-important

    &:hover {
      background-color: $orange;
    }
  }

  &.search__toggle--orange {
    background-color: $orange;
    color: $white !important;  // sass-lint:disable-line no-important

    &:hover {
      background-color: $medium-blue;
    }
  }
}

.search {

  &.search--main {
    background-color: $white;
    border-top: 10px solid $dark-blue;
    display: none;
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: height $global-transition-speed;
    width: 100%;
    z-index: 4;

    .close-button {
      font-size: 70px;
      position: absolute;
      right: 0;
      top: 30px;

      &:hover {
        color: $orange;
      }
    }

    &.is-expanded {
      display: block;
      height: 146px;
      top: 0;
    }

    .header--microsite & {
      border-top: 0;
    }
  }

  &.search--mobile {
    background-color: $white;
    display: none;
    position: absolute;
    top: 70px;
    width: 100%;

    &.is-expanded {
      display: block;
      left: 0;
    }
  }
}

.search__input-group {
  position: relative;
  width: 100%;

  .header-main & {
    width: 90%;
  }

  label {
    width: 100%;
  }

  .search__input {
    background-color: $white;
    border: 1px solid $border-gray;
    border-radius: 10px;
    height: 64px;
    padding: $global-padding #{$global-padding * 1.5};

    &:focus {
      border: 1px solid $border-gray;
      box-shadow: 0 0 0 0;
    }

    .header-main & {
      height: 71px;
      position: relative;
      top: 30px;
    }

    .search--mobile & {
      height: 57px;
      left: 5%;
      top: 0;
      width: 90%;
    }
  }

  .search__button {
    background-color: $light-gray;
    border: 0;
    border-radius: 20px;
    color: $orange;
    height: 40px;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 40px;

    &:focus,
    &:hover {
      background-color: $orange;
      color: $white;
    }

    .header-main & {
      top: 8px;
    }

    .search--mobile & {
      right: -8px;
      top: 4px;
    }


    .icon--search {
      font-size: 1em;
      position: relative;
      right: -3px;
      top: 0;

      &::before {
        font-size: 1.15em;
      }
    }
  }
}

.input-group-button {
  .search--main & {
    position: absolute;
    right: 10%;
    top: 40px;
  }

  .search--mobile & {
    right: 0;
    top: 5px;
  }
}
