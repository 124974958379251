.locations__gmap {
  height: 300px;
  position: relative;

  @include breakpoint(phablet only) {
    height: 450px;
  }

  @include breakpoint(medium up) {
    height: 600px;
  }
}

.locations__gmap-overlay,
.locations__gmap-render {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.locations__gmap-overlay {
  display: none;
}

.locations__gmap-overlay-container {
  @include breakpoint(phablet up) {
    display: none;
  }
}

.locations__list-results {
  margin-top: rem-calc(32);

  @include breakpoint(medium up) {
    margin-top: rem-calc(64);
  }
}

.locations__list-results-count {
  border-bottom: 1px solid #ccc;
  border-top: 8px solid $green-blue;
  font-size: rem-calc(16);
  font-weight: 600;
  padding: 1em 0;
}

.locations__list-results-list {
  margin-left: 0;
}

.locations__list-result {
  border-bottom: 1px solid #ccc;
  padding: rem-calc(32) 0;

  @include breakpoint(phablet down) {
    flex-direction: column;
  }

  @include breakpoint(medium up) {
    align-items: flex-start;
  }
}

.locations__list-result-address,
.locations__list-result-phone {
  margin-bottom: 1em;
}

.locations__list-result-address {
  font-style: normal;
}

.locations__list-result-content {
  @include breakpoint(phablet down) {
    font-size: 14px;
  }

  @include breakpoint(medium up) {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
  }
}

.locations__list-result-desc {
  p {
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium up) {
    flex: 1 1 70%;
  }
}

.locations__list-result-dir-link {
  font-size: rem-calc(14);
  font-weight: 600;
  text-transform: uppercase;

  .icon {
    margin-right: #{$global-margin / 2};
  }
}

.locations__list-result-img {
  flex: 0 0 auto;

  img {
    border-bottom: 8px solid $green-blue;
  }

  @include breakpoint(phablet down) {
    margin-bottom: rem-calc(18);
  }

  @include breakpoint(medium up) {
    border-bottom-width: 8px;
  }
}

.locations__list-result-meta {
  line-height: 1.2;
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    flex: 0 0 30%;
    min-width: 210px;
    padding-right: 20px
  }
}

.locations__list-result-name {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2;

  > a {
    color: inherit;
  }

  @include breakpoint(medium up) {
    font-size: 28px;
    margin-bottom: 18px;
    width: 100%;
  }
}

.locations__list-search-connector {
  align-items: center;
  display: flex;
  font-weight: 600;

  @include breakpoint(phablet up) {
    margin-left: .75rem;
    margin-right: .75rem;
    margin-top: 2rem;
  }

  @include breakpoint(small down) {
    margin-top: 1rem;
  }
}

.locations__list-search-fields {
  max-width: 870px;
}

.locations__list-search-field {
  @include breakpoint(phablet up) {
    flex: 0 1 260px;

    &--lg {
      flex: 0 1 500px;
    }

    &--md {
      flex: 0 1 330px;
    }

    & + & {
      margin-left: 1.5rem;
    }
  }
}

.locations__list-search-row {
  @include breakpoint(phablet up) {
    align-items: center;
    display: flex;
  }
}

.locations__map {
  border-top: 8px solid $green-blue;
  flex: 1 1 auto;
  padding-top: rem-calc(20);
}

.locations__map-key {
  margin-top: rem-calc(20);
}

.locations__map-key-items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: 0;
}

.locations__map-key-item {
  align-items: center;
  display: flex;
  flex: 0 0 230px;
  margin-top: rem-calc(10);
  padding-right: rem-calc(40);

  @include breakpoint(medium down) {
    flex: 0 1 50%;
  }
}

.locations__map-key-item-icon {
  display: inline-block;
  flex: 0 0 auto;
  height: rem-calc(53);
  margin-right: rem-calc(10);
  width: rem-calc(53);

  @include breakpoint(medium down) {
    &:not(&--sm) {
      height: rem-calc(40);
      width: rem-calc(40);
    }
  }

  &--sm {
    height: rem-calc(32);
    width: rem-calc(32);
  }
}

.locations__map-key-item-label {
  font-weight: 600;
  line-height: 1.3;

  @include breakpoint(medium down) {
    &:not(&--sm) {
      font-size: rem-calc(12);
    }
  }
}

.locations__map-loc-filter {
  border-bottom: 1px solid #cecece;
  margin-bottom: rem-calc(38);
  padding-bottom: rem-calc(4);

  @include breakpoint(phablet down) {
    flex-direction: column;
  }

  @include breakpoint(medium up) {
    align-items: center;
  }
}

.locations__map-loc-filter-address {
  @include breakpoint(medium up) {
    flex: 0 1 56%;
  }
}

.locations__map-loc-filter-address,
.locations__map-loc-filter-title {
  margin-right: 1rem;
}

.locations__map-loc-filter-distance {
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    flex: 0 1 31%;
  }
}

.locations__map-loc-filter-title {
  margin-bottom: 1rem;
  white-space: nowrap;
}

.locations__map-refine {
  background-color: $green-blue;
  border-bottom-left-radius: rem-calc(30);
  color: #fff;
  margin-bottom: rem-calc(22);
  padding: rem-calc(30);
}

.locations__map-refine-btn.button {
  margin: 0;

  &:hover {
    color: $medium-blue;
  }
}

.locations__map-refine-title {
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: rem-calc(20);
  text-transform: uppercase;
}

.locations__map-results {
  flex: 1 1 auto;
  position: relative;
}

.locations__map-result {
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 1.2;
  padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-calc(30);
  position: relative;
  transition: background-color 200ms;

  &--active {
    background-color: #f9e0d2;
  }

  &:not(:first-child) {
    margin-top: rem-calc(4);
  }
}

.locations__map-result-arrow {
  color: $medium-blue;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

  @include breakpoint(medium up) {
    display: none;
  }
}

.locations__map-result-address,
.locations__map-result-phone {
  font-style: normal;
  margin-bottom: 1em;
}

.locations__map-result-links {
  list-style: none;
  margin: 0;

  li {
    display: inline-block;

    &:not(:last-child)::after {
      content: '|';
    }
  }
}

.locations__map-result-name {
  font-size: 16px;
  margin-bottom: .25em;
}

.locations__map-results-list {
  list-style: none;
  margin: 0;
}

.locations__map-results-count {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: rem-calc(20);
}

.locations__map-search {
  background-color: #f5f5f5;
  border-bottom-left-radius: rem-calc(30);
  flex: 0 0 auto;
}

.locations__map-search-fields {
  padding: 0 rem-calc(25) rem-calc(25);
}

.locations__list-search-title,
.locations__map-search-title {
  color: $green-blue;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.locations__map-search-title {
  margin: 0;

  button {
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    padding: rem-calc(25);
    position: relative;
    text-align: left;
    text-transform: inherit;
    width: 100%;

    i {
      color: $medium-blue;
      font-size: 13px;
      position: absolute;
      right: rem-calc(20);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.locations__map-window {
  height: 0;
  position: absolute;
  width: 0;

  @include breakpoint(small only) {
    display: none;
  }
}

.locations__map-window-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  i {
    color: $orange;
    font-size: 18px;
    margin-right: 0;
  }
}

.locations__map-window-content {
  background: rgba(255, 255, 255, .9);
  font-family: $body-font-family;
  font-size: 12px;
  line-height: 1.2;
  position: relative;

  .rtf {
    font-size: inherit;
  }

  .locations__gmap-overlay & {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .locations__gmap-render & {
    box-shadow: 0 0 12px rgba(0, 0, 0, .25);
    margin-top: -80px;
    transform: translate(-50%, -100%);
    width: 400px;

    &::before {
      @include css-triangle(42px, rgba(255, 255, 255, .9), down);
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 100%;
    }
  }
}

ul.locations__map-window-list {
  margin-left: 0;
  margin-top: .25rem;

  li {
    margin-bottom: 0;
    padding-left: 1.5rem;
    overflow: hidden;
  }
}

.locations__map-window-inner {
  padding: 5%;
}

.locations__map-window-meta-address {
  font-style: normal;
}

.locations__map-window-meta-hours {
  color: #50940f;
}

.locations__map-window-meta-name {
  font-size: 1.1667em;
  font-weight: 600;
}

.locations__map-window-content--lg {
  font-size: 14px;

  .locations__map-window-desc {
    flex: 0 1 auto;
  }

  .locations__map-window-inner {
    display: flex;
  }

  .locations__map-window-list {
    column-count: 2;
    column-gap: 20px;
    margin-bottom: 0;
  }

  .locations__map-window-meta {
    flex: 0 0 175px;
    padding-right: 20px
  }

  .locations__map-window-meta-hours-container {
    margin-top: 1rem;
  }
}

.locations__map-window-content--sm {
  .locations__map-window-desc {
    margin-top: 1rem;
  }

  .locations__map-window-hide-sm {
    display: none;
  }

  .locations__map-window-meta {
    display: flex;
    flex-wrap: wrap;
  }

  .locations__map-window-meta-link,
  .locations__map-window-meta-name {
    flex: 1 0 100%;
  }

  .locations__map-window-meta-address-hours {
    flex: 0 1 40%;
  }

  .locations__map-window-meta-address-phone {
    flex: 0 1 60%;
    padding-right: 15px;
  }
}


@include breakpoint(phablet down) {
  .locations__map-search-fields {
    .locations__map-search--collapsed & {
      display: none;
    }
  }

  .locations__map-view {
    flex-direction: column;
  }

  .locations__sidebar {
    margin-bottom: rem-calc(25);
  }

}

@include breakpoint(medium up) {
  .locations__map {
    margin-left: rem-calc(70);
  }

  .locations__map-results-count,
  .locations__list-search-title,
  .locations__map-search-title {
    font-size: 22px;
  }

  .locations__map-results-list {
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0;
  }

  .locations__map-search-title {
    i {
      display: none;
    }
  }

  .locations__sidebar {
    flex: 0 0 rem-calc(300);
  }

}