html,
body {
  min-height: 100%;
  overflow-x: hidden;
}

html {
  // &.is-nav-open {
  //   overflow: hidden;
  //
  //   body {
  //     overflow: hidden;
  //   }
  // }
}

h2,
h3,
ul {
  &:only-child {
    margin-bottom: 0;
  }
}

h4,
h5,
h6 {
  margin-bottom: #{$global-margin / 2};
}

em {
  display: inline-block;
}

small {
  //color: $legal-gray;
}

button {
  border-radius: 0;
  color: inherit;
  font-family: $body-font-family;
}

img {
  &:not([src]) {
    visibility: hidden;
  }

  &[data-original] {
    opacity: 0;
    transition: opacity .5s;
  }

  &.is-loaded {
    opacity: 1;
  }
}

iframe {
  max-width: 100%;
}

hr {
  border-color: #ccc;

  &.hr--margin-small {
    margin: 0;
  }
}

// sass-lint:disable no-vendor-prefixes
@-moz-document url-prefix() {
  img {
    &:-moz-loading {
      visibility: hidden;
    }
  }
}
// sass-lint:enable no-vendor-prefixes
