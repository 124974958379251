.rounded-box {
  height: 100%;

  &.rounded-box__shadow {
    border-bottom-left-radius: 60px;
    box-shadow: 0 0 rem-calc(10) rgba($black, .2);
  }
}

.rounded-box__heading {
  background-color: $medium-blue;
  height: 173px;
  padding: #{$global-padding * 2};
  text-align: center;

  .rounded-box--green & {
    background-color: $green-blue;
  }

  @include breakpoint(phablet down) {
    height: auto;
    padding: #{$global-padding * 1.5};
  }

  .rounded-box--microsite & {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid #cecece;
    border-top: 8px solid $medium-blue;
    color: $medium-blue;
    display: flex;
    height: 77px;
    padding: $global-padding #{$global-padding * 2};
    text-align: left;
  }

  .rounded-box--microsite__green-blue & {
    border-top: 8px solid $green-blue;
  }
}

.rounded-box__body {
  background: $white;
  border-bottom-left-radius: 60px;
  height: calc(100% - 173px);
  padding: #{$global-padding * 2};

  @include breakpoint(phablet down) {
    padding: #{$global-padding * 2} $global-padding;
  }

  &.rounded-box__shadow {
    box-shadow: 0 0 rem-calc(10) rgba($black, .2);
  }

  .rounded-box--microsite & {
    padding-top: 0;
  }
}

.rounded-box__body-title {
  font-size: rem-calc(22);
  font-weight: $global-weight-semibold;
  text-transform: uppercase;

  @include breakpoint(phablet down) {
    font-size: rem-calc(19);
  }
}

.rounded-box__title {
  color: $white;
  font-size: rem-calc(28);
  font-weight: $global-weight-bold;
  text-transform: uppercase;

  @include breakpoint(phablet down) {
    font-size: rem-calc(24);
  }

  .rounded-box--microsite & {
    font-size: rem-calc(24);
  }
}
