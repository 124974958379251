/* YAPL
name: Featured Stories
partial: hp-featured-stories
selector: .featured-stories
*/

.featured-stories {
  height: 100%;
}

.featured-stories__body {
  padding: $global-padding #{$global-padding * 2};
  @include breakpoint(phablet down) {
    padding: $global-padding #{$global-padding * 1.5};
  }
}

.featured-stories__main {
  background-color: #f5f5f5;
  border-bottom-left-radius: 60px;
  margin-top: #{$global-margin * 2};

  img {
    width: 100%;
  }

  h5 {
    font-size: rem-calc(20);
    font-weight: $global-weight-bold;

    a {
      &:hover {
        color: $orange;
      }
    }
  }

  @include breakpoint(phablet down) {
    margin-top: $global-margin;
  }
}

.featured-stories__side {
  margin-top: #{$global-margin * 2};

  @include breakpoint(phablet down) {
    padding-left: #{$global-padding * 2};
    padding-right: #{$global-padding * 2};
  }
}

.featured-stories__item {
  border-top: 8px solid $light-brown;
  font-size: rem-calc(18);
  font-weight: $global-weight-bold;
  margin-bottom: #{$global-margin * 2.5};
  padding-top: $global-padding;

  @include breakpoint(phablet down) {
    font-size: rem-calc(16);
    margin-bottom: #{$global-margin * 2};
  }

  a {
    &:hover {
      color: $orange;
    }
  }
}

.featured-stories__title {
  font-size: 36px;
  font-weight: $global-weight-semibold;
  text-transform: uppercase;

  @include breakpoint(phablet down) {
    font-size: rem-calc(25);
  }
}
