/* YAPL
name: Image Border
partial: sg-image-border
selector: .image--border
*/

/* YAPL
name: Image Portrait
partial: sg-image-portrait
selector: .image--portrait
*/

/* YAPL
name: Image Portrait Small
partial: sg-image-portrait-small
selector: .image--portrait--small
*/

/* YAPL
name: Image Max Width and Diagonals
notes: Same as example from figure component
partial: sg-image-max-diagonals
selector: .image--max-diagonals
*/

/* YAPL
name: Image Fit
notes: For use in cases where the image needs to fill the container; can be used in conjunction with image--max-540 to limit height; you could create new max classes for different situations
partial: sg-image-fit
selector: .image--fit
*/

/* YAPL
name: Image Max Width 540
notes: Used to limit max height of image
partial: sg-image-max-540
selector: .image--max-540
*/

.image--border {
  border-bottom: 8px solid $light-brown;
}

.image--float-left {
  float: left;
  margin-bottom: $global-margin;
  margin-right: $global-margin;
}

.image--portrait {
  max-width: 150px;
}

.image--portrait--small {
  max-width: 114px;
}

.image--max-diagonals {
  max-width: 348px !important;
}

.image--overflow-hidden {
  overflow: hidden;
  position: relative;

  &.image--no-hover {
    &:hover {

      > img {
        transform: scale(1);
      }
    }
  }

  img {
    transition: transform $global-transition-speed;
  }

  &:hover {

    +div > span {
      color: $medium-blue;
    }

    > img {
      transform: scale(1.1);
    }
  }
}

.image--fill {
  img {
    width: 100%;
  }
}

.image--fit {
  font-family: 'object-fit: cover; object-position: top';
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

.image--max-540 {
  max-height: 540px;
}
