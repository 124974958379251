.reveal {

  &.full {
    background-color: rgba($black, .8);
  }

  iframe {
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .close-button {
    background-color: rgba(10, 10, 10, 0.5);
    border-radius: 28px;
    color: #e36018;
    font-size: 44px;
    height: 56px;
    right: 20px;
    top: 40px;
    width: 56px;
  }
}
