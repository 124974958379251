/* YAPL
name: Follow
partial: sg-follow
selector: .follow__icons
*/

/* YAPL
name: Follow - Footer
notes: .footer__social wrapping class makes icon background color blue
partial: sg-follow-footer
selector: .follow__icons
*/

/* YAPL
name: Follow - Share
notes: Share functionality javascript is in src/assets/js/share.js
partial: sg-follow-share
selector: .follow__icons
*/


.follow {
  padding: 0 #{$global-padding * 2} $global-padding;
}

.follow__title {
  background-color: $dark-blue;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: $white;
  font-size: rem-calc(19);
  font-weight: $global-weight-bold;
  padding: $global-padding;
  text-align: center;
}

.follow__icons {
  margin: $global-margin 0;

  .icon {
    color: $white;
    left: 8px;
    margin-right: 0;
    position: absolute;
    top: 2px;
  }
}

.follow__icon {
  background-color: $green-blue;
  border-radius: 18px;
  display: flex;
  font-size: 1.2em;
  height: 36px;
  justify-content: center;
  line-height: 36px;
  margin: 0 6px $global-margin;
  position: relative;
  transition: background-color $global-transition-speed;
  width: 36px;

  .footer__social & {
    background-color: #0071b9;
  }
}

.follow__icon--facebook {
  background-color: #47639c;

  &:hover {
    background-color: saturate(#47639c, 25%);
  }
}

.follow__icon--twitter {
  background-color: #2aa3ef;

  &:hover {
    background-color: saturate(#2aa3ef, 25%);
  }
}

.follow__icon--instagram {
  background-color: #d2327e;

  &:hover {
    background-color: saturate(#d2327e, 25%);
  }
}

.follow__icon--youtube {
  background-color: #dc301c;

  &:hover {
    background-color: saturate(#dc301c, 25%);
  }
}

.follow__icon--linkedin {
  background-color: #1178b3;

  &:hover {
    background-color: saturate(#1178b3, 25%);
  }
}

.follow__facebook {
  align-items: center;
  display: flex;
  justify-content: center;


}


.fb-page,
.fb-page span,
.fb-page span iframe[style] {
   width: 100% !important;
}
