.u-block {
  display: block;
}

.u-block--small {
  @include breakpoint(small only) {
    display: block;
  }
}

.u-clearfix {

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
}
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-no-margin {
  margin: 0 !important
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-float-right--medium {
  @include breakpoint(medium up) {
    float: right;
  }
}
