/* YAPL
name: Alphabet List
partial: alphabet-list
selector: .alphabet-list
*/

/* YAPL
name: Alphabet List Wide
partial: alphabet-list
context: alphabetList.wide
selector: alphabet-list.alphabet-list--wide
*/

/* YAPL
name: Alphabet List Mid
partial: alphabet-list
context: alphabetList.mid
selector: alphabet-list.alphabet-list--mid
*/

.alphabet-list {
  @include clearfix;
  align-items: center;
  background: $white;
  justify-content: space-around;
  margin-left: 0;
  padding: 20px 14px 2px;

  li {
    background-color: $medium-blue;
    border: 2px solid $medium-blue;
    border-radius: 18px;
    color: $white;
    float: left;
    height: 36px;
    list-style: none;
    margin-bottom: 8px;
    margin-right: 7px;
    text-align: center;
    width: 36px;

    &.is-active {
      background-color: $orange;
      border: 2px solid $orange;

      &:hover {
        background-color: $orange;
        border: 2px solid $orange;
        color: $white;
      }
    }

    a {
      color: inherit;
      display: block;
      line-height: 32px;
    }

    &:hover {
      background: transparent;
      color: $medium-blue;
    }

    &:last-child { //for xyz
      font-size: 13px;
    }
  }

  &.alphabet-list--wide {
    li {
      border-radius: 50%;
      height: 48px;
      margin-bottom: 18px;
      margin-right: 18px;
      width: 48px;

      a {
        line-height: 44px;
      }

      @include breakpoint(phablet down) {
        border-radius: 18px;
        height: 36px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: 36px;

        a {
          line-height: 32px;
        }
      }
    }
  }

  &.alphabet-list--mid {
    li {
      border-radius: 50%;
      height: 44px;
      margin-bottom: 18px;
      margin-right: 14px;
      width: 44px;

      a {
        line-height: 40px;
      }

      @include breakpoint(phablet down) {
        border-radius: 18px;
        height: 36px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: 36px;

        a {
          line-height: 32px;
        }
      }
    }
  }
}
