.order-1-medium {
  @include breakpoint(medium up) {
    order: 1;
  }
}

.order-2-medium {
  @include breakpoint(medium up) {
    order: 2;
  }
}
