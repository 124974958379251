/* YAPL
name: CTA Primary
partial: sg-cta
selector: .cta.cta--primary
*/

/* YAPL
name: CTA Secondary
partial: sg-cta-secondary
selector: .cta.cta--secondary
*/

/* YAPL
name: CTA Primary Microsite
partial: sg-cta-microsite
selector: .cta.cta--primary.cta--microsite
*/

/* YAPL
name: CTA Secondary Microsite
partial: sg-cta-secondary-microsite
selector: .cta.cta--secondary.cta--microsite
*/

.cta {
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, .2);
  display: block;
  padding: 26px 44px;

  .cta__title {
    font-size: rem-calc(24);
    font-weight: 700;
    margin-top: 0;

    @include breakpoint(phablet down) {
      font-size: rem-calc(22);
    }
  }

  .cta__rtf {
    font-size: rem-calc(15);
  }

  .cta__link {
    color: $white;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    .icon {
      border-radius: 12px;
      font-size: 10px;
      height: 24px;
      margin-right: 8px;
      position: relative;
      width: 24px;

      &::before {
        left: 8px;
        position: absolute;
        top: 7px;
      }
    }
  }

  &.cta--microsite {
    border-radius: 30px;
  }
}

.cta--primary {
  background-color: $green-blue;
  transition: background-color $global-transition-speed;

  .cta__title {
    color: $white;
  }

  .cta__rtf {
    color: $white;
  }

  .icon {
    background-color: $medium-blue;
    color: $white;
    transition: background-color $global-transition-speed, color $global-transition-speed;
  }

  &:hover {
    background-color: $medium-blue;

    .icon {
      background-color: $white;
      color: $medium-blue;
    }
  }
}

.cta--secondary {
  background-color: $white;

  .cta__title {
    color: $orange;
  }

  .cta__rtf {
    color: $text-color
  }

  .cta__link {
    color: $text-color;
  }

  .icon {
    background-color: $orange;
    color: $white;
  }

  &:hover {
    .cta__link {
      color: $orange;
    }
  }
}

.cta-grid {
  @include xy-grid;
  @include xy-grid-layout(2, '.cta');
}

.cta-grid > .cta-grid-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(50% - 3rem);

  @include breakpoint(phablet down) {
    margin: #{$global-margin * 2} 0 0;
    width: 100%;

    &:first-child {
      margin-top: #{$global-margin / 2};
    }
  }
}
