.filter {
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: $global-margin;
  padding: $global-padding;

  p {
    margin-bottom: 0;
  }
}
