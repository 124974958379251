/* YAPL
name: Figure
notes: This can be floated either way with the addition or removal of .float-right and .float-left
partial: sg-figure
selector: .figure
*/

/* YAPL
name: Figure with Video
partial: sg-figure-video
selector: .figure.figure__video
*/

/* YAPL
name: Figure with Diagonals
notes: The diagonals div can be removed if needed. If so you can remove .image--overflow-hidden and .image--max-diagonals from the containing div
partial: sg-figure-small
selector: .figure.image--overflow-hidden.image--max-diagonals
*/

/* YAPL
name: Figure Right with Border
notes: Add .figure--no-border if you do not want the border
partial: sg-figure-right-border
selector: .figure.float-right.figure__image
*/

/* YAPL
name: Figure Max Width
notes: Max width is set in this file; this can be floated left or right with .float-left or .float-right on the containing div
partial: sg-figure-max-width
selector: .figure.figure--max-width
*/


.figure {
  margin-left: auto;
  margin-right: auto;

  &.float-left {
    float: left;
    margin-bottom: #{$global-margin * 2};
    margin-left: 0;
    margin-right: #{$global-margin * 2};
    max-width: 100%;
    width: rem-calc(720);
  }

  &.float-right {
    float: right;
    margin-bottom: #{$global-margin * 2};
    margin-left: #{$global-margin * 2};
    margin-right: 0;
    max-width: 100%;
    width: rem-calc(720);
  }

  @include breakpoint(large down) {
    &.float-left {
      .medium-8 & {
        margin-left: 0;
      }
    }
  }

  @include breakpoint(medium down) {
    &.float-left {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }

    &.float-right {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
}

.figure__video {

  position: relative;

  img {
    border-bottom: 0;
  }

  &::before {
    background-color: $orange;
    border-radius: 30px;
    content: '';
    height: 60px;
    left: calc(50% - 30px);
    pointer-events: none;
    position: absolute;
    top: calc(50% - 30px);
    transition: opacity $global-transition-speed;
    width: 60px;
  }

  @include breakpoint(medium down) {
    &.float-left,
    &.float-right {
      float: none;
      margin-left: auto !important; // sass-lint:disable-line no-important
      margin-right: auto !important; // sass-lint:disable-line no-important
    }
  }

  .icon--play {
    font-size: 1.25rem;
    padding-left: 6px;
    padding-top: 7px;
    pointer-events: none;
  }

  &.figure__video--max-width {
    @include breakpoint(large up) {
      max-width: 66%;
    }
  }
}

.figure__image {
  display: block;
  width: 100%;

  img {
    border-bottom: 8px solid $light-brown;
  }

  &.figure--max-width {
    max-width: 348px;
  }

  &.figure--no-border {
    border: 0;
  }

  &.figure--flexible {
    width: auto;
  }
}

.figure__caption {
  background: $very-light-gray;
  border-bottom-left-radius: 30px;
  color: $caption-gray;
  font-size: rem-calc(14);
  padding: 22px $global-padding;

  p {
    margin-bottom: 0;
  }

  &.figure__caption--white {
    background-color: $white;
  }
}
