.mailing-list__icon {
  align-items: center;
  background-color: $orange;
  border-radius: 50%;
  color: $white;
  display: flex;
  font-family: $body-font-family-alt;
  font-size: rem-calc(28);
  font-weight: $global-weight-bold;
  height: rem-calc(60);
  justify-content: center;
  line-height: 1.2;
  margin-right: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  width: rem-calc(60);
}
