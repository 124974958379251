.divider {
  background-color: #ccc;
  height: 100%;
  left: 50%;
  position: relative;
  text-align: center;
  width: 1px;

  span {
    display: block;
    height: 50px;
    left: 100%;
    line-height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    transform: translateX(-50px);
    width: 100px;
  }

  @include breakpoint(phablet down) {
    height: 1px;
    left: 0;
    margin-bottom: $global-margin;
    margin-top: $global-margin;
    width: 100%;

    span {
      left: 50%;
      top: -25px;
      transform: translateX(-25px);
      width: 50px;
    }
  }

  &.divider--horizontal {
    height: 1px;
    left: 0;
    margin-bottom: #{$global-margin * 3};
    margin-top: #{$global-margin * 3};
    width: 100%;

    @include breakpoint(phablet down) {
      margin-bottom: $global-margin;
      margin-top: $global-margin;
    }
  }

  &.divider--small-margin {
    margin-bottom: #{$global-margin * 1.5};
    margin-top: #{$global-margin * 1.5};
  }
}
