.hero-nav__container {

  @include breakpoint(large up) {
    bottom: 154px;
    position: relative;
  }

  @include breakpoint (medium down) {
    background-color: $dark-blue;
    border-bottom-left-radius: 20px;
    bottom: auto;
    min-height: 156px;
    outline: 4px solid rgba($white, .4);
    padding: $global-padding 0;
    position: relative;
    top: -36px;
    width: 92%;
  }

  &.hero-nav--campaign {
    z-index: 1;

    @include breakpoint(medium down) {
      background-color: $green-blue;
      margin-top: -80px;
      top: 0;
    }

    @include breakpoint(phablet down) {
      margin-top: 0px;
    }
  }
}

.hero-nav {
  align-items: center;
  background-color: rgba($white, .75);
  display: flex;
  justify-content: space-between;
  padding: 0 $global-padding;
  transition: background-color $global-transition-speed;
  width: 100%;

  @include breakpoint(medium down) {
    background-color: $dark-blue;
    padding: 0 $global-padding;
  }

  .hero-nav--campaign & {
    background-color: rgba(#3e9790, .9);

    @include breakpoint (medium down) {
      align-items: flex-start;
      background-color: $green-blue;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}

.hero-nav__section {
  align-items: center;
  border: 0;
  display: flex;
  height: 110px;
  justify-content: space-between;

  &:hover {
    .hero-nav__link {
      color: $green-blue;
    }

    .hero-nav__icon {
      background-color: $white;
      border: 2px solid $green-blue;
      color: $green-blue;

      &::after {
        background: $green-blue;
        opacity: 0;
        transform: scale(1.4);
        transition: transform .3s, opacity .2s;
      }
    }
  }

  @include breakpoint(medium down) {
    background-color: $dark-blue;
    border: 0;
    color: $white;
    height: auto;
    justify-content: flex-start;
    margin-bottom: $global-margin;
    width: 25%;
  }

  @include breakpoint(phablet down) {
    width: 47%;
  }

  .hero-nav--campaign & {
    @include breakpoint(medium down) {
      background-color: $green-blue;
      border-bottom: 1px solid rgba($white, .5);
      padding-bottom: $global-padding;
      padding-top: $global-padding;

      &:last-child {
        border-bottom: 0;
      }
    }

    p {
      color: $white;
      line-height: 1.3;
      margin: 0;
    }

    a {
      color: $white;
      padding: 0;
      text-decoration: underline;
    }
  }

  &.hero-nav__section--description {
    width: 40%;

    @include breakpoint(medium down) {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &.hero-nav__section--info {
    padding-left: 10px;
    padding-right: 10px;
    width: 30%;

    @include breakpoint(medium down) {
      margin-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  .hero-nav__link {
    color: $dark-blue;
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    line-height: 1.3em;
    padding-left: 8px;
    transition: color $global-transition-speed;

    @include breakpoint (medium down) {
      color: $white;
      font-size: rem-calc(14);
    }

    @include breakpoint (phablet down) {
      padding-left: 6px;
      padding-right: 12px;
    }

    .hero-nav--campaign & {
      color: $white;
      font-size: rem-calc(14);
    }
  }

  .hero-nav__icon {
    background-color: $green-blue;
    border: 2px solid $green-blue;
    border-radius: 27px;
    color: $white;
    display: block;
    flex-shrink: 0;
    float: left;
    height: 54px;
    position: relative;
    width: 54px;
    z-index: 1;

    &::before {
      font-size: 1.8rem;
      left: 11px;
      position: absolute;
      top: 10px;
      z-index: 2;
    }

    &.icon--location {
      &::before {
        top: 11px;
      }
    }

    &.icon--briefcase {
      &::before {
        top: 13px;
      }
    }

    @include breakpoint (large up) {
      &::after {
        background-color: $green-blue;
        border-radius: 50%;
        content: '';
        height: 54px;
        left: -2px;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: -2px;
        transition: transform .3s, opacity .4s;
        width: 54px;
        z-index: 0;
      }
    }

    @include breakpoint (medium down) {
      box-shadow: 0 0 0 0;
      height: 42px;
      width: 42px;

      &::before {
        font-size: 1.4rem;
        left: 8px;
        position: absolute;
        top: 7px;
      }

      &.icon--location {
        &::before {
          top: 8px;
        }
      }

      &.icon--briefcase {
        &::before {
          top: 9px;
        }
      }
    }
  }
}

.hero-nav__section-container {

  &:hover {
    .hero-nav {
      @include breakpoint(large up) {
        background-color: $white;

        .hero-nav--campaign & {
          background-color: $green-blue;
        }
      }
    }
  }

  @include breakpoint(large only) {
    width: 100% !important; // sass-lint:disable-line no-important
  }
  @include breakpoint (medium only) {
    flex-direction: column;
  }

  @include breakpoint (phablet only) {
    flex-direction: row;
  }

  @include breakpoint (small only) {
    flex-direction: column;
  }
}

.hero-nav__section--looking {
  align-items: center;
  background-color: $dark-blue;
  border-bottom-left-radius: 20px;
  color: $white;
  display: flex;
  font-size: rem-calc(27);
  font-weight: $global-weight-bold;
  height: 110px;
  justify-content: flex-end;
  margin-left: 0;
  padding-right: 31px;
  text-align: right;
  width: 240px;

  span {
    display: inline-block;

    @include breakpoint (medium down) {
      display: inline;
    }
  }

  @include breakpoint (medium down) {
    border-bottom-left-radius: 0;
    font-size: rem-calc(22);
    font-weight: $global-weight-bold;
    height: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: $global-padding;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    width: 100%;
  }

  @include breakpoint (phablet only) {
    font-size: 20px;
    padding-right: 20px;
    text-align: right;
  }

  .hero-nav--campaign & {
    background-color: $green-blue;

    @include breakpoint (medium down) {
      justify-content: flex-start;
      padding: 0 $global-padding;
    }
  }
}

.hero-nav__left {
  @include breakpoint (phablet only) {
    width: 25% !important; // sass-lint:disable-line no-important
  }
}

.hero-nav__right {
  @include breakpoint (phablet only) {
    width: 75% !important; // sass-lint:disable-line no-important
  }
}
