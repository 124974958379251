.hp-portlets {
  .hp-portlets-cell {
    margin-bottom: 64px;
    transition: margin-top 1s;

    &.is-animating {
      margin-top: -90px;
    }

    @include breakpoint(phablet down) {
      margin-bottom: $global-margin;

      &.is-animating {
        margin-top: #{$global-margin * 2};
      }
    }
  }
}
