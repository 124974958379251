.infographic {
  padding: 0;
  position: relative;

  @include breakpoint(medium down) {
    width: 92%;
  }

  @include breakpoint(phablet down) {
    box-shadow: 0 0 0 0;
    margin-bottom: #{$global-margin * 3};
    width: 100%;
  }
}

.infographic__tabs-container {
  height: 100%;
  width: 100%;

  @include breakpoint(phablet down) {
    box-shadow: 0 0 rem-calc(20) rgba($black, .2);
    height: 484px;

  }
}

.infographic__nav {
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 40px;
  position: absolute;
  top: 308px;
  z-index: 2;

  @include breakpoint(large down) {
    top: 248px;
  }

  @include breakpoint(medium down) {
    align-items: center;
    bottom: -40px;
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }

  @include breakpoint(phablet down) {
    bottom: -60px;
  }

  .infographic__nav-link {
    align-items: center;
    background-color: rgba($medium-blue, .8);
    color: $white;
    display: flex;
    font-size: rem-calc(18);
    font-weight: $global-weight-semibold;
    height: 60px;
    margin-bottom: 5px;
    padding: $global-padding;
    padding-left: #{$global-padding * 2};
    transition: background-color $global-transition-speed, color $global-transition-speed;
    width: 306px;

    .icon {
      font-size: .75em;
      margin-left: auto;
      margin-right: 0;
    }

    &:hover {
      background-color: $white;
      color: $orange;
    }

    @include breakpoint(medium down) {
      height: 53px;
    }
  }
}

.infographic__tabs-title {
  background: none;
}

.infographic__tabs-content {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 0;

  @include breakpoint(phablet down) {
    height: 484px;
    width: 100%;
  }

}

.infographic__content {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity .6s;
  width: 100%;

  &.is-active {
    opacity: 1;
  }
}

.infographic__content-1 {
  background-image: url('../img/hp/hp-sinai.jpg');
  opacity: 1 !important; // sass-lint:disable-line no-important
}

.infographic__content-2 {
  background-image: url('../img/hp/hp-northwest.jpg');
}

.infographic__content-3 {
  background-image: url('../img/hp/hp-carroll.jpg');
}

.infographic__content-4 {
  background-image: url('../img/hp/hp-levindale.jpg');
}

.infographic__buttons {
  bottom: 8px;
  left: calc(50% - 120px);
  position: absolute;
  z-index: 2;
}

.infographic__button {
  background-color: rgba($medium-blue, .9);
  border: 2px solid rgba($medium-blue, .9);
  margin-bottom: 5px;
  padding: 8px 14px;
  text-align: left;
  text-transform: none;
  transition: border $global-transition-speed;
  width: 240px;

  &:hover {
    background-color: $white;
    border: 2px solid $white;
    color: $dark-blue;

    .icon {
      background-color: $medium-blue;
      color: $white;
    }
  }
}

.infographic__image {
  background-image: url('../img/diagonals.png');
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .icon {
    background-color: $dark-blue;
    border-radius: 18px;
    height: 36px;
    position: relative;
    transition: background-color $global-transition-speed;
    width: 36px;

    &::before {
      background-position: 0 4px;
      left: 11px;
      position: absolute;
      top: 9px;
    }

    &.icon--find-a-doc {
      font-size: 17px;

      &::before {
        top: 10px;
      }
    }

    &.icon--make-an-appt {
      font-size: 15px;

    }
  }

  img {
    @include breakpoint(medium down) {
      width: 100%;
    }
  }


  &.infographic__image--right {
    &::before {
      border-bottom: 27px solid transparent;
      border-left: 27px solid $white;
      border-top: 27px solid transparent;
      content: '';
      left: 0;
      position: absolute;
      top: calc(50% - 27px);
      z-index: 2;

      @include breakpoint(phablet down) {
        border: 0;
      }
    }

    &::after {
      border-bottom: 0 solid transparent;
      border-left: 0 solid $white;
      border-top: 0 solid transparent;
      content: '';
      left: 0;
      position: absolute;
      top: 52px;

      @include breakpoint(phablet down) {
        border: 0;
      }
    }
  }

  &.infographic__image--left {
    &::before {
      border-bottom: 27px solid transparent;
      border-right: 27px solid $white;
      border-top: 27px solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 27px);
      z-index: 2;

      @include breakpoint(phablet down) {
        border: 0;
      }
    }

    &::after {
      border-bottom: 0 solid transparent;
      border-right: 0 solid $white;
      border-top: 0 solid transparent;
      content: '';
      position: absolute;
      right: 0;
      top: 52px;

      @include breakpoint(phablet down) {
        border: 0;
      }
    }
  }
}

.infographic__map {
  background-image: url('../img/hp/infographic-map-nopins.jpg');
  background-repeat: no-repeat;
}

.infographic__map-pin {
  height: 12px;
  position: absolute;
  width: 12px;
  z-index: 4;

  @include breakpoint(large up) {
    opacity: 0;
  }

  &.infographic__map-pin-1 {
    left: 25%;
    top: 12%;
  }

  &.infographic__map-pin-2 {
    left: 26%;
    top: 17%;
  }

  &.infographic__map-pin-3 {
    left: 26%;
    top: 20.5%;
  }

  &.infographic__map-pin-4 {
    left: 29%;
    top: 18.5%;
  }

  &.infographic__map-pin-5 {
    left: 37%;
    top: 15%;
  }

  &.infographic__map-pin-6 {
    left: 48%;
    top: 19%;
  }

  &.infographic__map-pin-7 {
    left: 47%;
    top: 22.5%;
  }

  &.infographic__map-pin-8 {
    left: 73%;
    top: 13.5%;
  }

  &.infographic__map-pin-9 {
    left: 75.5%;
    top: 16.5%;
  }

  &.infographic__map-pin-10 {
    left: 77%;
    top: 21%;
  }

  &.infographic__map-pin-11 {
    left: 3%;
    top: 29%;
  }

  &.infographic__map-pin-12 {
    left: 31%;
    top: 31%;
  }

  &.infographic__map-pin-13 {
    left: 39%;
    top: 28%;
  }

  &.infographic__map-pin-14 {
    left: 41.5%;
    top: 30%;
  }

  &.infographic__map-pin-15 {
    left: 44%;
    top: 33%;
  }

  &.infographic__map-pin-16 {
    left: 47.5%;
    top: 32%;
  }

  &.infographic__map-pin-17 {
    left: 51%;
    top: 27%;
  }

  &.infographic__map-pin-18 {
    left: 54%;
    top: 29%;
  }

  &.infographic__map-pin-19 {
    left: 56%;
    top: 32%;
  }

  &.infographic__map-pin-20 {
    left: 60%;
    top: 35%;
  }

  &.infographic__map-pin-21 {
    left: 64%;
    top: 35.5%;
  }

  &.infographic__map-pin-22 {
    left: 67%;
    top: 30%;
  }

  &.infographic__map-pin-23 {
    left: 34%;
    top: 47%;
  }

  &.infographic__map-pin-24 {
    left: 42%;
    top: 50%;
  }

  &.infographic__map-pin-25 {
    left: 52%;
    top: 48%;
  }

  &.infographic__map-pin-26 {
    left: 58%;
    top: 52%;
  }

  &.infographic__map-pin-27 {
    left: 63%;
    top: 67%;
  }

  &.infographic__map-pin-28 {
    left: 42.5%;
    top: 40.5%;
  }

  &.infographic__map-pin-29 {
    left: 45.5%;
    top: 42%;
  }

  &.infographic__map-pin-30 {
    left: 39%;
    top: 43%;
  }

  &.infographic__map-pin-31 {
    left: 44%;
    top: 36.5%;
  }
}

.pin-anim-1 {
  transition: opacity .5s .3s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-2 {
  transition: opacity .5s .5s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-3 {
  transition: opacity .5s .8s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-4 {
  transition: opacity .5s 1.4s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-5 {
  transition: opacity .5s 1.2s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-6 {
  transition: opacity .5s 1.7s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-7 {
  transition: opacity .5s 1.8s;

  &.is-animating {
    opacity: 1;
  }
}

.pin-anim-8 {
  transition: opacity .5s 2.3s;

  &.is-animating {
    opacity: 1;
  }
}


.infographic__stat-container {
  align-items: center;
  display: flex;

  @include breakpoint(phablet down) {
    box-shadow: 0 0 rem-calc(20) rgba($black, .2);
    margin-left: auto;
    margin-right: auto;
    margin-top: #{$global-margin * 3};
    width: 92%;
  }
}

.infographic__stat-container--tabs {
  align-items: center;
  display: flex;
  justify-content: center;

  @include breakpoint(phablet down) {
    margin-left: auto;
    margin-right: auto;
    margin-top: #{$global-margin * 3};
    width: 92%;
  }
}

.infographic__stat {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  @include breakpoint(medium down) {
    padding-bottom: $global-padding;
    padding-top: $global-padding;
  }

  &.infographic__stat--circle {
    background-color: $very-light-gray;
    border-radius: 50%;
    height: 220px;
    margin-bottom: #{$global-margin * 2};
    width: 220px;

    .infographic__stat-number {
      font-size: rem-calc(60);
    }
  }
}

.infographic__stat-small {
  font-weight: $global-weight-semibold;
  line-height: 1.2;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &.separator-center {

    &::after {
      border-color: $orange;
      border-width: 5px;
    }
  }
}

.infographic__stat-main {
  align-items: flex-end;
  color: $white;
  font-size: rem-calc(36);
  font-weight: $global-weight-semibold;
  left: 40px;
  line-height: 1.2;
  position: absolute;
  text-transform: uppercase;
  top: 30px;
  z-index: 1;

  @include breakpoint(medium only) {
    top: 15px;
  }

  @include breakpoint(medium down) {
    display: flex;
    font-size: rem-calc(25);
    justify-content: center;
    left: auto;
    position: relative;
  }

  .infographic__stat-main-text {
    bottom: 16px;
    left: 113px;
    position: absolute;

    @include breakpoint(medium down) {
      bottom: auto;
      left: auto;
      position: relative;
    }
  }

  .separator-left {

    &::after {
      border-color: $orange;
      border-width: 5px;
    }
  }
}

.infographic__stat-number {
  color: $medium-blue;
  display: inline-block;
  font-family: $body-font-family-alt;
  font-size: rem-calc(67);
  font-weight: $global-weight-bold;

  @include breakpoint(medium down) {
    font-size: rem-calc(47);
  }

  &.infographic__stat-number--small {
    font-size: rem-calc(48);
    line-height: 150%;
    position: relative;
    top: -7px;
  }
}

.infographic__stat-number--large {
  color: $white;
  font-size: rem-calc(180);
  font-weight: $global-weight-bold;
  line-height: rem-calc(180);

  @include breakpoint(medium down) {
    font-size: rem-calc(128);
    line-height: rem-calc(128);
  }
}
