/* YAPL
name: Table
notes: wrapping div with table-scroll allows horizontal scroll at small media queries
partial: sg-table
selector: .table.table--horizontal
*/

// sass-lint:disable no-important

@include foundation-table;

.table-scroll {
  width: 100.5%; // fix for firefox scrollbar issue

  table {
    margin-bottom: 0;
    min-width: 99.5%;
  }
}

.table {
  width: 100%;

  ul {
    margin-left: 0;

    li {
      margin-bottom: 0;
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  tr {

    th {
      height: 54px;
    }

    td {
      color: $medium-gray;
      font-size: 14px;

      &:first-child {
        font-weight: 700;
      }
    }
  }


  thead {
    tr {
      th {
        background-color: #c6a06c;
        font-size: 14px;
        text-transform: uppercase;

        &:first-child {
          background-color: #b98e5a;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        td {
          background-color: #fff;

          &:first-child {
            background-color: #f4f4f4;

          }
        }
      }

      &:nth-child(odd) {
        background-color: #f4f4f4;

        td {
          background-color: #f4f4f4;

          &:first-child {
            background-color: #e9e9e9;
          }
        }
      }

    }
  }
}
