.border--green {
  border-top: 8px solid $green-blue;
}

.border-brown-bottom {
  border-bottom: 8px solid $light-brown;
  padding-bottom: $global-padding;

  &.border-no-padding {
    padding-bottom: 0;
  }
}

.border-green-bottom {
  border-bottom: 8px solid $green-blue;
  padding-bottom: $global-padding;
}

.border-top {
  border-top: 1px solid #ccc;
  padding-top: 1.5rem;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1.5rem;
}
