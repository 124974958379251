.background--blue {
  background-color: $medium-blue;
}

.background--dark-blue {
  background-color: $dark-blue;
}

.background--offwhite {
  background-color: $offwhite;
}

.background--white {
  background-color: $white;
}

.background--light-gray {
  background-color: $very-light-gray;
}

.background--gray {
  background-color: $warm-gray;
}

.background--green-blue {
  background-color: $green-blue;
}

.background--orange {
  background-color: $orange;
}

.background-padding {
  padding-bottom: 60px;
  padding-top: 60px;

  @include breakpoint(phablet down) {
    padding-bottom: 30px;
    padding-left: $global-padding;
    padding-right: $global-padding;
    padding-top: 30px;
  }
}
