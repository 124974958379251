/* YAPL
name: Pagination
partial: pagination
selector: .pagination
*/


.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: #{$global-margin * 2} auto;

  a {
    border: 2px solid #bebebe;
    color: $caption-gray;
    font-size: 14px;
    font-weight: 600;
    height: 36px;
    line-height: 32px;
    margin-right: 6px;
    padding: 0 .625rem;
    text-align: center;
    transition: background-color $global-transition-speed, color $global-transition-speed, border $global-transition-speed;
    width: 36px;

    @include breakpoint(small only) {
      font-size: 13px;
      height: 30px;
      line-height: 26px;
      margin-right: 4px;
      padding: 0 .5rem;
      width: 30px;
    }

    &.active,
    &:hover {
      background-color: $orange;
      border: 2px solid $orange;
      color: $white;
    }

    &.inactive {
      color: #bebebe;
      pointer-events: none;
    }

    &.pagination--width-auto {
      width: auto;
    }
  }

  .pagination-ellipsis {
    margin-right: 6px;
    margin-top: 4px;
    text-align: center;
    width: 36px;

    @include breakpoint(small only) {
      display: none;
    }
  }
}
