/* YAPL
name: Quote
partial: sg-quote
selector: .quote
*/

/* YAPL
name: Quote with Image
partial: sg-quote-image
selector: .quote__image-container
*/

.quote {
  padding: 0 80px;

  @include breakpoint(phablet down) {
    margin-top: #{$global-margin * 2};
    padding: 0 $global-padding;
  }
}

.quote__text {
  font-size: rem-calc(24);
  line-height: 2.5rem;
  text-shadow: 1px 1px 10px rgba(10, 10, 10, .2);

  @include breakpoint(phablet down) {
    font-size: rem-calc(21);
    text-shadow: 0 0 0;
  }
}

.quote__author {
  color: $orange;
  font-size: rem-calc(15);
  font-style: italic;
  font-weight: 600;
  padding-left: 156px;
  position: relative;

  &::before {
    background-color: $orange;
    content: '';
    display: inline-block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 142px;
  }
}

.quote__more {
  align-items: center;
  color: $text-color;
  display: flex;
  font-size: rem-calc(14);
  font-weight: 600;
  text-transform: uppercase;
  transition: color $global-transition-speed;

  .icon {
    align-items: center;
    background-color: $orange;
    border-radius: 13px;
    color: $white;
    display: flex;
    font-size: 12px;
    height: 26px;
    justify-content: center;
    margin-right: 8px;
    width: 26px;
  }

  &:hover {
    color: $orange;
  }
}

.quote__image-container {
  align-items: center;
  border: 8px solid #cabc9d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  max-height: 200px;
  max-width: 200px;
  overflow: hidden;
  position: relative;

  @include breakpoint(phablet down) {
    margin-right: auto;
  }
}

.quote__image {

  border-radius: 50%;
}
