/* YAPL
name: Button
partial: sg-button
selector: .button
*/

/* YAPL
name: Button Hollow
notes: This has a white border and text so should be used on a color background
partial: sg-button-hollow
selector: .button.button--hollow
*/

/* YAPL
name: Button Hollow Light Brown
partial: sg-button-hollow--light-brown
selector: .button.button--hollow--light-brown
*/

/* YAPL
name: Button Hollow Blue
partial: sg-button-hollow--blue
selector: .button.button--hollow--blue.white
*/

/* YAPL
name: Button Microsite
partial: sg-button-microsite
selector: .button.button--microsite
*/

/* YAPL
name: Button Rounded
partial: sg-button-rounded
selector: .button.button--rounded
*/

/* YAPL
name: Button Icon
partial: sg-button-icon
selector: .button.button--icon
*/

.button {
  border: 2px solid $medium-blue;
  font-size: rem-calc(14);
  font-weight: $global-weight-semibold;
  padding: 1em 1.5em;
  text-transform: uppercase;
  transition: background-color $global-transition-speed, color $global-transition-speed;

  .icon {
    font-size: .75em;
  }

  &:hover {
    background-color: transparent;
    border: 2px solid $medium-blue;
    color: $medium-blue;
  }

  &.button--hollow {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
    display: inline-block;
    position: relative;
    z-index: 1;

    &:hover {
      background-color: $white;
      color: $orange;
    }
  }

  &.button--hollow--light-brown {
    background-color: transparent;
    border-color: $light-brown-border;
    color: $light-brown-text;

    &:hover {
      background-color: $light-brown;
      color: $white;
    }
  }

  &.button--hollow--blue {
    border-color: $medium-blue;
    color: $medium-blue;

    &:hover {
      background-color: $medium-blue;
      color: $white;
    }
  }

  &.button--icon {
    align-items: center;
    display: flex;
    max-width: 240px;
    padding: .75rem;
    text-align: left;
    text-transform: none;


    .icon {
      flex-shrink: 0;
      font-size: 1em;
      margin-left: 0;
      margin-right: .5rem;
    }

    &:hover {
      .icon {
        color: $white;
      }
    }

    @include breakpoint(small only) {
      padding: .5rem;

      .icon {
        margin-right: .25rem;
      }
    }
  }

  &.button--rounded {
    border-radius: 27px;
  }

  &.button--microsite {
    background-color: transparent;
    border-radius: 24px;
    color: $medium-blue;

    &:hover {
      background-color: $medium-blue;
      color: $white;
    }
  }

  &.button--no-margin {
    margin: 0;
  }
}

.button__container {
  align-items: center;
  display: flex;
  margin-bottom: $global-margin;

  a,
  button {
    margin-right: $global-margin
  }

  @include breakpoint(phablet down) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
}
