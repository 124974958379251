@charset 'utf-8';

// Open Sans font
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,500,500i,600,700');

@import '../../../node_modules/awesomplete/awesomplete';
@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;



//@include foundation-prototype-classes;

@include foundation-prototype-rounded;
@include foundation-prototype-bordered;
@include foundation-prototype-separator;
@include foundation-prototype-sizing;
@include foundation-prototype-spacing;

@include motion-ui-transitions;
@include motion-ui-animations;

//@import 'util/vertical-rhythm';

// project styles that components may depend on
@import 'base';
@import 'typography';
@import 'colors';
@import 'icons';
@import 'utility';

@import 'util/vertical-rhythm';
@include foundation-vertical-rhythm;

// layout components in alphabetical order

@import 'components/layout/breadcrumbs';
@import 'components/layout/footer';
@import 'components/layout/grid';
@import 'components/layout/header-main';
@import 'components/layout/main';
@import 'components/layout/nav-main';
@import 'components/layout/nav-mobile';
@import 'components/layout/nav-secondary';
@import 'components/layout/nav-utility';

// layout components in alphabetical order
@import 'components/accordion';
@import 'components/align';
@import 'components/alphabet-list';
@import 'components/article';
@import 'components/background';
@import 'components/back-to-top';
@import 'components/border';
@import 'components/box';
@import 'components/button';
@import 'components/careers-info';
@import 'components/careers-results';
@import 'components/chat';
@import 'components/colors';
@import 'components/contact';
@import 'components/css-columns';
@import 'components/cta';
@import 'components/diagonals';
@import 'components/divider';
@import 'components/doc-profile';
@import 'components/embed';
@import 'components/featured-stories';
@import 'components/figure';
@import 'components/filter';
@import 'components/follow';
@import 'components/forms';
@import 'components/future';
@import 'components/hamburger';
@import 'components/image';
@import 'components/hero';
@import 'components/hero-nav';
@import 'components/hot-jobs';
@import 'components/hp-portlets';
@import 'components/infographic';
@import 'components/link';
@import 'components/list';
@import 'components/locations';
@import 'components/mailing-list';
@import 'components/menu';
@import 'components/microsite-info';
@import 'components/more-section';
@import 'components/order';
@import 'components/padded-box';
@import 'components/pagination';
@import 'components/portlet';
@import 'components/quote';
@import 'components/reveal';
@import 'components/rounded-box';
@import 'components/rounded-corner';
@import 'components/rtf';
@import 'components/search';
@import 'components/separator';
@import 'components/table';
@import 'components/tabs';
@import 'components/tile';
@import 'components/typeahead';
@import 'components/upcoming-events';
@import 'components/updates';

@import 'styleguide';
@import 'print';
