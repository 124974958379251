.nav__secondary {
  border-bottom-left-radius: 40px;
  box-shadow: 0 0 rem-calc(20) rgba($black, .2);

  @include breakpoint(large up) {
    max-width: 346px;
  }
}

.nav-secondary__toggle {
  cursor: pointer;

  .is-expanded & {
    .icon {
      &::before {
        content: 'd';
      }
    }
  }
}


.nav__secondary-level {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
  //transition: height $global-transition-speed, opacity $global-transition-speed;

  @include breakpoint(medium down) {

    &.nav__secondary__level1 {
      height: auto;
      opacity: 1;
      padding: 8px 20px;

      li {
        align-items: center;
        display: flex;
      }
    }

    &.nav__secondary__level2,
    &.nav__secondary__level3,
    &.nav__secondary__level4,
    &.nav__secondary__level5,
    &.nav__secondary__level6 {
      border: 0;
      height: 0;
      opacity: 0;
      padding: 0;
      pointer-events: none;

      .expanded & {
        height: auto;
        opacity: 1;
        padding: 8px 20px;
        pointer-events: auto;
        transition: height $global-transition-speed, opacity $global-transition-speed;
      }

      &.nav__secondary__level2 {
        .expanded & {
          padding: 14px 20px;
        }
      }

      .nav__secondary-parent & {
        padding-bottom: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  li {

    a {
      color: inherit;
      display: block;
      width: 100%;

      &.is-active {
        color: $orange;
      }
    }

    span {

      @include breakpoint(medium down) {
        align-items: center;
        display: flex;
        height: 52px;
        justify-content: center;
        margin-left: auto;
        margin-right: 0;
        right: 0;
        top: 0;
        width: 52px;
      }

      .icon {
        font-size: rem-calc(20);
        margin-right: 0;
      }
    }
  }
}

.nav__secondary__level1 {
  background-color: $green-blue;
  color: $white;
  font-size: rem-calc(22);
  font-weight: $global-weight-semibold;
  padding: 14px 20px;

  @include breakpoint(medium down) {
    padding: 8px 20px;
  }
}

.nav__secondary__level2 {
  background-color: #edf8f7;
  color: #4ab6ad;
  font-size: rem-calc(18);
  font-weight: $global-weight-semibold;
  margin-top: 0;
  padding: 14px 20px;
}

.nav__secondary__level3 {
  border-top: 1px solid #ccc;
  color: $caption-gray;
  font-size: rem-calc(15);
  font-weight: $global-weight-semibold;
  margin-top: 0;
  padding: 0 20px 20px;

  li {
    border-bottom: 1px solid #cccccc;
    padding: 13px 0;

    &:hover {
      color: #e36018;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.nav__secondary__level4 {
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
  color: #505050;
  font-size: rem-calc(14);
  font-weight: $global-weight-semibold;
  margin-top: 14px;
  padding: 0 0 0 15px;

  li {
    border-bottom: 1px solid #cccccc;
    padding: 13px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.nav__secondary__level5 {
  background-color: #ececec;
  border-top: 1px solid #ccc;
  color: #242424;
  font-size: rem-calc(14);
  font-weight: $global-weight-normal;
  margin-top: 14px;
  padding: 0 0 0 15px;

  li {
    border-bottom: 1px solid #ccc;
    padding: 13px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.nav__secondary__level6 {
  background-color: #e2e2e2;
  border-top: 1px solid #ccc;
  color: #242424;
  font-size: rem-calc(14);
  font-weight: $global-weight-normal;
  margin-top: 14px;
  padding: 0 0 0 15px;

  li {
    border-bottom: 1px solid #ccc;
    padding: 13px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.nav__secondary-parent {
  padding-bottom: 0 !important;
}
