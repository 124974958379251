.header-main {
  position: relative;
  transition: .25s height linear;
  z-index: 10;

  @include breakpoint(medium down) {
    border-top: 8px solid $dark-blue;

    // sass-lint:disable no-important
    .is-nav-open & {
      height: 100vh !important;

      .header-main__sticky {
        height: 100vh !important;
      }

      .header-main__title-bar {
        border-bottom: rem-calc(1) solid $light-gray;
      }
    }

    &.header--careers,
    &.header--microsite,
    &.header--campaign {
      border-top: 0;
    }
  }
}

.header-main__sticky {
  background: $white;
  box-shadow: 0 0 rem-calc(25) rgba($black, .25);
  height: 146px;
  transition: height $global-transition-speed;
  z-index: 5;

  &.is-stuck {
    height: 85px;

    .is-expanded & {
      height: 146px;
    }
  }

  @include breakpoint(medium down) {
    height: $header-height-phablet;

    &.is-stuck {
      height: $header-height-phablet;
    }

    .sublocation & {
      .is-nav-open & {
        padding-top: 20px;
      }
    }
  }

  .header--careers & {
    border-top: 10px solid $dark-blue;
    height: 118px;

    &.is-stuck {
      height: 85px;

      .is-expanded & {
        height: 118px;
      }
    }

    @include breakpoint(phablet down) {
      height: 82px;

      .is-stuck & {
        border-top: 10px solid $dark-blue;
      }
    }
  }

  .header--campaign & {
    background-color: transparent;
    border-top: 10px solid $dark-blue;
    box-shadow: 0 0 0 0;
    height: 123px;
    transition: background-color $global-transition-speed;

    &.is-stuck {
      background-color: $dark-blue;

      .is-expanded & {
        height: 118px;
      }
    }

    @include breakpoint(medium down) {
      height: 82px;
    }
  }

  .header--microsite & {
    background-color: transparent;
    border-top: 10px solid $orange;
    box-shadow: 0 0 0 0;
    height: 157px;
    transition: background-color $global-transition-speed;

    &.is-stuck {
      background-color: $white;
      box-shadow: 0 0 rem-calc(25) rgba($black, .25);
      height: 137px;

      .is-expanded & {
        height: 137px;
      }
    }
  }

  .header-microsite--sub & {
    background-color: $white;
    box-shadow: 0 0 rem-calc(25) rgba($black, .25);
    height: 137px;
  }
}

.header-main__container {
  height: 108px;
  transition: height $global-transition-speed;
  will-change: height;

  .is-stuck & {
    height: 82px;

    .is-expanded & {
      height: 108px;
    }

    .header--microsite & {
      height: 108px;
    }

    .header--campaign & {
      height: 52px;
    }
  }

  .header--campaign & {

    .is-nav-open & {
      @include breakpoint(medium down) {
        background-color: $dark-blue;
      }
    }
  }



  @include breakpoint(medium down) {
    height: $header-height-phablet;

    .is-stuck & {
      height: $header-height-phablet;
    }
  }
}

.header-main__title {
  background-image: url('../img/logo.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 53px;
  margin-bottom: 0;
  margin-top: 0;
  min-width: 381px;
  transition: margin $global-transition-speed, height $global-transition-speed;
  will-change: height, margin;

  .is-stuck & {
    height: 40px;

    .sublocation & {
      height: 32px;
    }
  }

  @include breakpoint(medium down) {
    background-position: 12px 18px;
    background-size: 220px auto;
    height: 64px;
    min-width: auto;
    width: 240px;

    .is-stuck & {
      height: 53px;

      .sublocation & {
        height: 53px;
      }
    }
  }

  .sublocation & {
    background-image: url('../img/logo-sinai.svg');
    height: 43px;
    margin-top: 12px;
    min-width: 260px;

    @include breakpoint(medium down) {
      height: 64px;
      margin-top: 0;
    }
  }
}

.header-main__title--other {
  margin-bottom: 0;
  margin-top: 0;

  > img {
    max-height: 50px;
  }

  @include breakpoint(medium down) {
    margin-left: $global-margin;
    max-height: 50px;
  }
}

.header__logo--microsite {
  margin-top: 0 !important;

  @include breakpoint(medium down) {
    .is-stuck & {
      margin-top: 0;
    }
  }

  img {
    margin-top: #{$global-margin * 3};
    max-height: 120px;
    transition: max-height $global-transition-speed, margin-top $global-transition-speed;

    @include breakpoint(medium down) {
      margin-left: $global-margin;
      margin-top: $global-margin;

      .is-stuck & {
        margin-top: $global-margin !important;
      }
    }
  }

  .is-stuck & {
    img {
      margin-top: #{$global-margin * 2};
      max-height: 90px;
    }
  }

  .header-microsite--sub & {

    img {
      margin-top: 16px;
      max-height: 90px;
    }

    .is-stuck & {

      img {
        margin-top: 0;
      }
    }
  }


  @include breakpoint(phablet down) {
    margin-top: 0;

    img {
      max-height: 70px;
    }
  }
}

.header__logo--careers {
  margin-top: 0;
  position: relative;
  transition: margin-top $global-transition-speed;

  .is-stuck & {
    max-height: 90px;
  }

  @include breakpoint(medium down) {
    margin-left: $global-margin;
  }

  @include breakpoint(phablet down) {
    margin-top: $global-margin;

    img {
      max-height: 30px;
    }
  }
}

.header__back-link {
  font-size: rem-calc(14);
  font-weight: 600;
  margin-left: #{$global-margin * 2};
  position: absolute;

  .footer-main & {
    top: 8px;
    z-index: 10;

    &:hover {
      color: #41b5ff;

      .icon {
        color: #41b5ff;
      }
    }
  }

  .icon {
    color: $medium-blue;
    font-size: rem-calc(22);
    position: relative;
    top: -4px;
  }

  @include breakpoint(medium down) {
    display: none;

    .is-nav-open & {
      display: block;
      margin-left: 12px;
      margin-top: -20px;
    }

    .footer-main & {
      display: block;
      margin-left: 20px;
      top: 60px;
    }
  }
}
