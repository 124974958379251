.chat {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 10;

  @include breakpoint(medium down) {
    display: none;
  }
}
