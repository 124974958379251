h1 {
  color: $dark-blue;
  font-family: $body-font-family-alt;
  font-size: rem-calc(40);
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: $global-margin;
  margin-top: 32px;

  @include breakpoint(phablet down) {
    font-size: rem-calc(30);
  }
}

h2 {
  color: $off-black;
  font-size: rem-calc(32);
  font-weight: 600;
  line-height: 1.2;

  @include breakpoint(phablet down) {
    font-size: rem-calc(26);
  }
}

h3 {
  color: $orange;
  font-size: rem-calc(28);
  font-weight: 600;
  line-height: 1.2;

  @include breakpoint(phablet down) {
    font-size: rem-calc(24);
  }
}

h4 {
  color: $caption-gray;
  font-size: rem-calc(26);
  font-weight: 600;
  line-height: 1.2;

  @include breakpoint(phablet down) {
    font-size: rem-calc(20);
  }
}

h5  {
  color: $light-brown;
  font-size: rem-calc(20);
  font-weight: 600;
  line-height: 1.2;
}

h6  {
  font-weight: 600;
  line-height: 1.2;
}

// rich text styles
.rtf {

  > * {
    @extend %vertical-margin-1;

    &.icon,
    &:only-child,
    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(medium up) {
      &.u-float-left + p {
        margin-top: 0;
      }
    }
  }

  figure {
    margin-bottom: $global-margin;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 3rem;
  }

  h5 {
    margin-top: 1.5rem;
  }

  h6 {
    margin-top: 1rem;
  }

  h4,
  h5 {
    margin-bottom: 0;

    + p {
      @extend %vertical-margin-half;
    }
  }

  p {
    color: $text-color;
    line-height: 1.6;
    min-width: rem-calc(120);

    &.paragraph-tight {
      line-height: 1.3;
      margin-bottom: .5rem;
    }

    &.paragraph-extra-space {
      line-height: 1.9;
      margin-bottom: .5rem;
    }
  }

  a {
    &:hover {
      color: $orange;
    }
  }

  ol,
  ul {
    list-style: none;
    margin-left: #{$global-margin * 3};
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      line-height: 1.6;
      margin-bottom: $global-margin;
      padding-left: 1.75rem;
      position: relative;

      @include breakpoint(phablet down) {
        margin-bottom: .75rem;
      }

      &::before {
        color: $orange;
        font-weight: $global-weight-bold;
        left: 0;
        position: absolute;
        //top: -.05rem;
        width: 1.5rem;
      }
    }

    @include breakpoint(phablet down) {
      margin-left: $global-margin;
    }

    &+ h2,
    &+ h3,
    &+ h4,
    &+ h5,
    &+ h6,
     {
      margin-top: 2rem;
    }
  }

  ol {
    counter-reset: ol-counter;

    ol {
      margin-left: 0;
      margin-top: $global-margin;
    }

    li {
      &::before {
        content: counter(ol-counter) '.';
        counter-increment: ol-counter;
        line-height: 1.6;
        text-align: right;
      }

      li {

        &::before {
          content: counter(ol-counter, lower-alpha) '.';
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  ul {
    ul {
      margin-left: 0;
      margin-top: $global-margin;
    }

    li {

      margin-bottom: 1.1rem;

      @include breakpoint(phablet down) {
        margin-bottom: .75rem;
      }

      &::before {
        content: '•';
        display: flex;
        font-size: rem-calc(28px);
        line-height: .9;
        text-align: center;
      }

      li {
        padding-left: 1.25rem;

        &::before {
          content: '○';
          font-size: rem-calc(14px);
          line-height: 1.7;
        }

        &:last-child {
          margin-bottom: 0;
        }

        li {
          padding-left: 1.25rem;

          &::before {
            content: '•';
            font-size: rem-calc(16px);
            line-height: 1.6;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // override <a> styles for CTA's and buttons in
  // the context of rtf
  // @import 'components/badge';
  @import 'components/button';
  //@import 'components/cta';

  img {
    &.float-left,
    &.float-right {
      width: auto;
    }

    &.float-left {
      margin-right: #{$global-margin * 1.5};
    }

    &.float-right {
      margin-left: #{$global-margin * 1.5};
    }
  }

  @include breakpoint(medium down) {
    font-size: rem-calc(14);
  }

  @include breakpoint(phablet down) {
    h1,
    h2,
    h3,
    h4 {
      margin-top: #{$global-margin * 1.5};
    }

    h5,
    h6 {
      margin-top: #{$global-margin * 1.5};
    }
  }
}

//font-sizes helper classes
.small {
  font-size: rem-calc(14);
}

.medium {
  font-size: rem-calc(18);
}

.large {
  font-size: rem-calc(24);
}


//font-weight helper classes
.semibold {
  font-weight: $global-weight-semibold;
}

.bold {
  font-weight: $global-weight-bold;
}

.italic {
  font-style: italic;
}
