.orange {
  color: $orange;
}

.gray {
  color: $caption-gray;
}

.medium-brown {
  color: $medium-brown;
}

.red {
  color: $red;
}
