.nav-mobile {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: absolute;
  right: 12px;
  top: 20px;

  &.is-menu-open {
    .search__toggle {
      opacity: 0;
    }
  }

  @include breakpoint(medium down) {
    top: 16px;

    .header--campaign & {
      top: 10px;
    }
  }
}

.nav-mobile__button {
  color: $dark-gray;
  font-size: rem-calc(26);
  line-height: 1;
  margin-left: $global-margin;
  padding: 0 .25rem;

  .icon {
    &::before {
      display: block;
      font-size: inherit;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

@include breakpoint(small only) {
  .nav-mobile__button {
    font-size: rem-calc(20);
    margin-left: #{$global-margin / 2};
    padding: 0;
  }
}
