@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,500,500i,600,700");
.awesomplete [hidden] {
  display: none; }

.awesomplete .visually-hidden {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.awesomplete {
  display: inline-block;
  position: relative; }

.awesomplete > input {
  display: block; }

.awesomplete > ul {
  position: absolute;
  left: 0;
  z-index: 1;
  min-width: 100%;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff; }

.awesomplete > ul:empty {
  display: none; }

.awesomplete > ul {
  border-radius: .3em;
  margin: .2em 0 0;
  background: rgba(255, 255, 255, 0.9);
  background: linear-gradient(to bottom right, white, rgba(255, 255, 255, 0.8));
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0.05em 0.2em 0.6em rgba(0, 0, 0, 0.2);
  text-shadow: none; }

@supports (transform: scale(0)) {
  .awesomplete > ul {
    transition: 0.3s cubic-bezier(0.4, 0.2, 0.5, 1.4);
    transform-origin: 1.43em -.43em; }
  .awesomplete > ul[hidden],
  .awesomplete > ul:empty {
    opacity: 0;
    transform: scale(0);
    display: block;
    transition-timing-function: ease; } }

/* Pointer */
.awesomplete > ul:before {
  content: "";
  position: absolute;
  top: -.43em;
  left: 1em;
  width: 0;
  height: 0;
  padding: .4em;
  background: white;
  border: inherit;
  border-right: 0;
  border-bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.awesomplete > ul > li {
  position: relative;
  padding: .2em .5em;
  cursor: pointer; }

.awesomplete > ul > li:hover {
  background: #b8d3e0;
  color: black; }

.awesomplete > ul > li[aria-selected="true"] {
  background: #3d6d8f;
  color: white; }

.awesomplete mark {
  background: #eaff00; }

.awesomplete li:hover mark {
  background: #b5d100; }

.awesomplete li[aria-selected="true"] mark {
  background: #3d6b00;
  color: inherit; }

/*# sourceMappingURL=awesomplete.css.map */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 53.25em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&phablet=40em&medium=53.25em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #2d2c2c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  max-width: 75rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 53.25em) {
    .grid-container {
      padding-right: 0.5625rem;
      padding-left: 0.5625rem; } }
  .grid-container.fluid {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 53.25em) {
      .grid-container.fluid {
        padding-right: 0.5625rem;
        padding-left: 0.5625rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .phablet-shrink, .grid-x > .phablet-full, .grid-x > .phablet-1, .grid-x > .phablet-2, .grid-x > .phablet-3, .grid-x > .phablet-4, .grid-x > .phablet-5, .grid-x > .phablet-6, .grid-x > .phablet-7, .grid-x > .phablet-8, .grid-x > .phablet-9, .grid-x > .phablet-10, .grid-x > .phablet-11, .grid-x > .phablet-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 53.25em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .phablet-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .phablet-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .phablet-1 {
    width: 8.33333%; }
  .grid-x > .phablet-2 {
    width: 16.66667%; }
  .grid-x > .phablet-3 {
    width: 25%; }
  .grid-x > .phablet-4 {
    width: 33.33333%; }
  .grid-x > .phablet-5 {
    width: 41.66667%; }
  .grid-x > .phablet-6 {
    width: 50%; }
  .grid-x > .phablet-7 {
    width: 58.33333%; }
  .grid-x > .phablet-8 {
    width: 66.66667%; }
  .grid-x > .phablet-9 {
    width: 75%; }
  .grid-x > .phablet-10 {
    width: 83.33333%; }
  .grid-x > .phablet-11 {
    width: 91.66667%; }
  .grid-x > .phablet-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-x {
      margin-left: -0.5625rem;
      margin-right: -0.5625rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.125rem);
      margin-left: 0.5625rem;
      margin-right: 0.5625rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .phablet-auto {
      width: auto; }
    .grid-margin-x > .phablet-shrink {
      width: auto; }
    .grid-margin-x > .phablet-1 {
      width: calc(8.33333% - 1rem); }
    .grid-margin-x > .phablet-2 {
      width: calc(16.66667% - 1rem); }
    .grid-margin-x > .phablet-3 {
      width: calc(25% - 1rem); }
    .grid-margin-x > .phablet-4 {
      width: calc(33.33333% - 1rem); }
    .grid-margin-x > .phablet-5 {
      width: calc(41.66667% - 1rem); }
    .grid-margin-x > .phablet-6 {
      width: calc(50% - 1rem); }
    .grid-margin-x > .phablet-7 {
      width: calc(58.33333% - 1rem); }
    .grid-margin-x > .phablet-8 {
      width: calc(66.66667% - 1rem); }
    .grid-margin-x > .phablet-9 {
      width: calc(75% - 1rem); }
    .grid-margin-x > .phablet-10 {
      width: calc(83.33333% - 1rem); }
    .grid-margin-x > .phablet-11 {
      width: calc(91.66667% - 1rem); }
    .grid-margin-x > .phablet-12 {
      width: calc(100% - 1rem); } }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.125rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.125rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.125rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.125rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.125rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.125rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.125rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.125rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.125rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.125rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.125rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.125rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.125rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.125rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.125rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.125rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.125rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.125rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.125rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.125rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.125rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.125rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.125rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.125rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.125rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.125rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.125rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.125rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.125rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.125rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.125rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.125rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.125rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.125rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.125rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.125rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.5625rem;
      margin-left: -0.5625rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.5625rem;
      margin-left: -0.5625rem; } }

.grid-padding-x > .cell {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-padding-x > .cell {
      padding-right: 0.5625rem;
      padding-left: 0.5625rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .phablet-up-1 > .cell {
    width: 100%; }
  .phablet-up-2 > .cell {
    width: 50%; }
  .phablet-up-3 > .cell {
    width: 33.33333%; }
  .phablet-up-4 > .cell {
    width: 25%; }
  .phablet-up-5 > .cell {
    width: 20%; }
  .phablet-up-6 > .cell {
    width: 16.66667%; }
  .phablet-up-7 > .cell {
    width: 14.28571%; }
  .phablet-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 53.25em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.phablet-up-1 > .cell {
    width: calc(100% - 1rem); }
  .grid-margin-x.phablet-up-2 > .cell {
    width: calc(50% - 1rem); }
  .grid-margin-x.phablet-up-3 > .cell {
    width: calc(33.33333% - 1rem); }
  .grid-margin-x.phablet-up-4 > .cell {
    width: calc(25% - 1rem); }
  .grid-margin-x.phablet-up-5 > .cell {
    width: calc(20% - 1rem); }
  .grid-margin-x.phablet-up-6 > .cell {
    width: calc(16.66667% - 1rem); }
  .grid-margin-x.phablet-up-7 > .cell {
    width: calc(14.28571% - 1rem); }
  .grid-margin-x.phablet-up-8 > .cell {
    width: calc(12.5% - 1rem); } }

@media print, screen and (min-width: 53.25em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.125rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.125rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.125rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.125rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.125rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.125rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.125rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.125rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.125rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.125rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.125rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.125rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.125rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.125rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.125rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.125rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .phablet-1 {
      width: 8.33333%; }
    .small-margin-collapse > .phablet-2 {
      width: 16.66667%; }
    .small-margin-collapse > .phablet-3 {
      width: 25%; }
    .small-margin-collapse > .phablet-4 {
      width: 33.33333%; }
    .small-margin-collapse > .phablet-5 {
      width: 41.66667%; }
    .small-margin-collapse > .phablet-6 {
      width: 50%; }
    .small-margin-collapse > .phablet-7 {
      width: 58.33333%; }
    .small-margin-collapse > .phablet-8 {
      width: 66.66667%; }
    .small-margin-collapse > .phablet-9 {
      width: 75%; }
    .small-margin-collapse > .phablet-10 {
      width: 83.33333%; }
    .small-margin-collapse > .phablet-11 {
      width: 91.66667%; }
    .small-margin-collapse > .phablet-12 {
      width: 100%; } }
  @media print, screen and (min-width: 53.25em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .phablet-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .phablet-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .phablet-margin-collapse > .small-1 {
    width: 8.33333%; }
  .phablet-margin-collapse > .small-2 {
    width: 16.66667%; }
  .phablet-margin-collapse > .small-3 {
    width: 25%; }
  .phablet-margin-collapse > .small-4 {
    width: 33.33333%; }
  .phablet-margin-collapse > .small-5 {
    width: 41.66667%; }
  .phablet-margin-collapse > .small-6 {
    width: 50%; }
  .phablet-margin-collapse > .small-7 {
    width: 58.33333%; }
  .phablet-margin-collapse > .small-8 {
    width: 66.66667%; }
  .phablet-margin-collapse > .small-9 {
    width: 75%; }
  .phablet-margin-collapse > .small-10 {
    width: 83.33333%; }
  .phablet-margin-collapse > .small-11 {
    width: 91.66667%; }
  .phablet-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .phablet-margin-collapse > .phablet-1 {
    width: 8.33333%; }
  .phablet-margin-collapse > .phablet-2 {
    width: 16.66667%; }
  .phablet-margin-collapse > .phablet-3 {
    width: 25%; }
  .phablet-margin-collapse > .phablet-4 {
    width: 33.33333%; }
  .phablet-margin-collapse > .phablet-5 {
    width: 41.66667%; }
  .phablet-margin-collapse > .phablet-6 {
    width: 50%; }
  .phablet-margin-collapse > .phablet-7 {
    width: 58.33333%; }
  .phablet-margin-collapse > .phablet-8 {
    width: 66.66667%; }
  .phablet-margin-collapse > .phablet-9 {
    width: 75%; }
  .phablet-margin-collapse > .phablet-10 {
    width: 83.33333%; }
  .phablet-margin-collapse > .phablet-11 {
    width: 91.66667%; }
  .phablet-margin-collapse > .phablet-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .phablet-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .phablet-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .phablet-margin-collapse > .medium-3 {
    width: 25%; }
  .phablet-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .phablet-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .phablet-margin-collapse > .medium-6 {
    width: 50%; }
  .phablet-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .phablet-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .phablet-margin-collapse > .medium-9 {
    width: 75%; }
  .phablet-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .phablet-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .phablet-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .phablet-margin-collapse > .large-1 {
    width: 8.33333%; }
  .phablet-margin-collapse > .large-2 {
    width: 16.66667%; }
  .phablet-margin-collapse > .large-3 {
    width: 25%; }
  .phablet-margin-collapse > .large-4 {
    width: 33.33333%; }
  .phablet-margin-collapse > .large-5 {
    width: 41.66667%; }
  .phablet-margin-collapse > .large-6 {
    width: 50%; }
  .phablet-margin-collapse > .large-7 {
    width: 58.33333%; }
  .phablet-margin-collapse > .large-8 {
    width: 66.66667%; }
  .phablet-margin-collapse > .large-9 {
    width: 75%; }
  .phablet-margin-collapse > .large-10 {
    width: 83.33333%; }
  .phablet-margin-collapse > .large-11 {
    width: 91.66667%; }
  .phablet-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .phablet-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .phablet-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 53.25em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 53.25em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .medium-margin-collapse > .phablet-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .phablet-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .phablet-3 {
    width: 25%; }
  .medium-margin-collapse > .phablet-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .phablet-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .phablet-6 {
    width: 50%; }
  .medium-margin-collapse > .phablet-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .phablet-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .phablet-9 {
    width: 75%; }
  .medium-margin-collapse > .phablet-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .phablet-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .phablet-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .phablet-1 {
    width: 8.33333%; }
  .large-margin-collapse > .phablet-2 {
    width: 16.66667%; }
  .large-margin-collapse > .phablet-3 {
    width: 25%; }
  .large-margin-collapse > .phablet-4 {
    width: 33.33333%; }
  .large-margin-collapse > .phablet-5 {
    width: 41.66667%; }
  .large-margin-collapse > .phablet-6 {
    width: 50%; }
  .large-margin-collapse > .phablet-7 {
    width: 58.33333%; }
  .large-margin-collapse > .phablet-8 {
    width: 66.66667%; }
  .large-margin-collapse > .phablet-9 {
    width: 75%; }
  .large-margin-collapse > .phablet-10 {
    width: 83.33333%; }
  .large-margin-collapse > .phablet-11 {
    width: 91.66667%; }
  .large-margin-collapse > .phablet-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.5rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.5rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.5rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.5rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.5rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.5rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.5rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.5rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.5rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.5rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.5rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.5rem); }

@media print, screen and (min-width: 40em) {
  .phablet-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .phablet-offset-0 {
    margin-left: calc(0% + 0.5rem); }
  .phablet-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .phablet-offset-1 {
    margin-left: calc(8.33333% + 0.5rem); }
  .phablet-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .phablet-offset-2 {
    margin-left: calc(16.66667% + 0.5rem); }
  .phablet-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .phablet-offset-3 {
    margin-left: calc(25% + 0.5rem); }
  .phablet-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .phablet-offset-4 {
    margin-left: calc(33.33333% + 0.5rem); }
  .phablet-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .phablet-offset-5 {
    margin-left: calc(41.66667% + 0.5rem); }
  .phablet-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .phablet-offset-6 {
    margin-left: calc(50% + 0.5rem); }
  .phablet-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .phablet-offset-7 {
    margin-left: calc(58.33333% + 0.5rem); }
  .phablet-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .phablet-offset-8 {
    margin-left: calc(66.66667% + 0.5rem); }
  .phablet-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .phablet-offset-9 {
    margin-left: calc(75% + 0.5rem); }
  .phablet-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .phablet-offset-10 {
    margin-left: calc(83.33333% + 0.5rem); }
  .phablet-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .phablet-offset-11 {
    margin-left: calc(91.66667% + 0.5rem); } }

@media print, screen and (min-width: 53.25em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.5625rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.5625rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.5625rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.5625rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.5625rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.5625rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.5625rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.5625rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.5625rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.5625rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.5625rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.5625rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.5625rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.5625rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.5625rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.5625rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.5625rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.5625rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.5625rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.5625rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.5625rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.5625rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.5625rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.5625rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .phablet-shrink, .grid-y > .phablet-full, .grid-y > .phablet-1, .grid-y > .phablet-2, .grid-y > .phablet-3, .grid-y > .phablet-4, .grid-y > .phablet-5, .grid-y > .phablet-6, .grid-y > .phablet-7, .grid-y > .phablet-8, .grid-y > .phablet-9, .grid-y > .phablet-10, .grid-y > .phablet-11, .grid-y > .phablet-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 53.25em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .phablet-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .phablet-shrink {
      height: auto; }
    .grid-y > .phablet-1 {
      height: 8.33333%; }
    .grid-y > .phablet-2 {
      height: 16.66667%; }
    .grid-y > .phablet-3 {
      height: 25%; }
    .grid-y > .phablet-4 {
      height: 33.33333%; }
    .grid-y > .phablet-5 {
      height: 41.66667%; }
    .grid-y > .phablet-6 {
      height: 50%; }
    .grid-y > .phablet-7 {
      height: 58.33333%; }
    .grid-y > .phablet-8 {
      height: 66.66667%; }
    .grid-y > .phablet-9 {
      height: 75%; }
    .grid-y > .phablet-10 {
      height: 83.33333%; }
    .grid-y > .phablet-11 {
      height: 91.66667%; }
    .grid-y > .phablet-12 {
      height: 100%; } }
  @media print, screen and (min-width: 53.25em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.5625rem;
      margin-bottom: -0.5625rem; } }

.grid-padding-y > .cell {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-padding-y > .cell {
      padding-top: 0.5625rem;
      padding-bottom: 0.5625rem; } }

.grid-margin-y {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y {
      margin-top: -0.5625rem;
      margin-bottom: -0.5625rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1rem);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.125rem);
      margin-top: 0.5625rem;
      margin-bottom: 0.5625rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .phablet-auto {
      height: auto; }
    .grid-margin-y > .phablet-shrink {
      height: auto; }
    .grid-margin-y > .phablet-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .phablet-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .phablet-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .phablet-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .phablet-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .phablet-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .phablet-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .phablet-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .phablet-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .phablet-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .phablet-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .phablet-12 {
      height: calc(100% - 1rem); } }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.125rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.125rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.125rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .phablet-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .phablet-grid-frame {
    width: 100%; }
  .phablet-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .phablet-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .phablet-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .phablet-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 53.25em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.phablet-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 53.25em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.phablet-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 53.25em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y {
      margin-top: -0.5625rem;
      margin-bottom: -0.5625rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1rem);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.125rem);
      margin-top: 0.5625rem;
      margin-bottom: 0.5625rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .phablet-auto {
      height: auto; }
    .grid-margin-y > .phablet-shrink {
      height: auto; }
    .grid-margin-y > .phablet-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .phablet-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .phablet-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .phablet-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .phablet-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .phablet-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .phablet-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .phablet-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .phablet-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .phablet-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .phablet-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .phablet-12 {
      height: calc(100% - 1rem); } }
  @media print, screen and (min-width: 53.25em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.125rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.125rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.125rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.125rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.125rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.125rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.125rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.125rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.125rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.125rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.125rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.125rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.125rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.125rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1rem); } }
  @media print, screen and (min-width: 53.25em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.125rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.125rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.phablet-grid-frame {
    height: calc(100vh + 1rem); } }

@media print, screen and (min-width: 53.25em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.125rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.125rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: 700;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #2d2c2c; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 53.25em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #e36018; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #2d2c2c;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: 700; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #2d2c2c; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #2f2e2e; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #2f2e2e; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #2d2c2c;
  background-color: #e1e1e1;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e1e1e1;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #2f2e2e; }

.lead {
  font-size: 125%;
  line-height: 1.4; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .phablet-text-left {
    text-align: left; }
  .phablet-text-right {
    text-align: right; }
  .phablet-text-center {
    text-align: center; }
  .phablet-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 53.25em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #2f2e2e;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.78846rem;
  margin: 0 0 1rem;
  padding: 0.76923rem;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #b7b7b7;
    background-color: #fefefe;
    box-shadow: 0 0 5px #b7b7b7;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #454545; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e1e1e1;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8;
  color: #242424; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 6px 0 0 6px; }
  .input-group > :last-child > * {
    border-radius: 0 6px 6px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #2d2c2c;
  background: #e1e1e1;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.875rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #b7b7b7; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.78846rem;
  margin: 0 0 1rem;
  padding: 0.76923rem;
  appearance: none;
  border: 1px solid #b7b7b7;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2847, 46, 46%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #b7b7b7;
    background-color: #fefefe;
    box-shadow: 0 0 5px #b7b7b7;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e1e1e1;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e1e1e1; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
  background-color: #fefefe;
  color: #2d2c2c; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e1e1e1; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #2d2c2c; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #2d2c2c;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 53.25em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #2d2c2c; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #2d2c2c; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #2d2c2c; }
  .callout.success {
    background-color: #e1faea;
    color: #2d2c2c; }
  .callout.warning {
    background-color: #fff3d9;
    color: #2d2c2c; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #2d2c2c; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #2d2c2c; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e1e1e1; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #2f2e2e;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.phablet-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.phablet-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.phablet-expanded li {
      flex: 1 1 0px; }
    .menu.phablet-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 53.25em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #2d2c2c;
    box-shadow: 0 7px 0 #2d2c2c, 0 14px 0 #2d2c2c; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #2f2e2e;
    box-shadow: 0 7px 0 #2f2e2e, 0 14px 0 #2f2e2e; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #2d2c2c;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.phablet-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.phablet-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.phablet-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.phablet-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.phablet-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.phablet-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.phablet-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.phablet-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.phablet-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.phablet-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 53.25em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #2d2c2c;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e1e1e1; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e1e1e1; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-phablet {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-phablet .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-phablet {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-phablet ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-phablet {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-phablet .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-phablet {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-phablet ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-phablet {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-phablet .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-phablet {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-phablet ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-phablet {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-phablet .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-phablet {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-phablet ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 53.25em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-phablet {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-phablet.position-left, .off-canvas.in-canvas-for-phablet.position-right, .off-canvas.in-canvas-for-phablet.position-top, .off-canvas.in-canvas-for-phablet.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-phablet .close-button {
      display: none; } }

@media print, screen and (min-width: 53.25em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #2d2c2c; }
    .orbit-bullets button:hover {
      background-color: #2f2e2e; }
    .orbit-bullets button.is-active {
      background-color: #2f2e2e; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 53.25em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e1e1e1; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #2d2c2c;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #2d2c2c; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e1e1e1;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #2d2c2c;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #2d2c2c;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 53.25em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 53.25em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 53.25em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 53.25em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 53.25em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #2d2c2c;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 0;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 700; }
  table thead {
    background: #c6a16d;
    color: #fefefe; }
  table tfoot {
    background: #f1f1f1;
    color: #2d2c2c; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 700;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 0;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #c39c66; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid 0;
  background: transparent;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.5625rem 1.25rem 0;
    font-size: 1rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #fefefe;
      color: #2d2c2c; }

.tabs-content {
  border: 1px solid 0;
  border-top: 0;
  background: #fefefe;
  color: #2d2c2c;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid 0;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 2.5rem 1.25rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #2f2e2e;
  font-weight: 700;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e1e1e1; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 53.25em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 53.1875em) {
    .top-bar.stacked-for-phablet {
      flex-wrap: wrap; }
      .top-bar.stacked-for-phablet .top-bar-left,
      .top-bar.stacked-for-phablet .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-phablet {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-phablet {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 53.1875em) {
  .hide-for-phablet-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 53.25em) {
  .show-for-phablet-only {
    display: none !important; } }

@media print, screen and (min-width: 53.25em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 53.1875em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 53.1875em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .phablet-order-1 {
    order: 1; }
  .phablet-order-2 {
    order: 2; }
  .phablet-order-3 {
    order: 3; }
  .phablet-order-4 {
    order: 4; }
  .phablet-order-5 {
    order: 5; }
  .phablet-order-6 {
    order: 6; } }

@media print, screen and (min-width: 53.25em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .phablet-flex-container {
    display: flex; }
  .phablet-flex-child-auto {
    flex: 1 1 auto; }
  .phablet-flex-child-grow {
    flex: 1 0 auto; }
  .phablet-flex-child-shrink {
    flex: 0 1 auto; }
  .phablet-flex-dir-row {
    flex-direction: row; }
  .phablet-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .phablet-flex-dir-column {
    flex-direction: column; }
  .phablet-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 53.25em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.rounded {
  border-radius: 5000px !important; }

.radius {
  border-radius: 0.1875rem; }

.bordered {
  border: 0.0625rem solid #2d2c2c; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important; }

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

html,
body {
  min-height: 100%;
  overflow-x: hidden; }

h2:only-child,
h3:only-child,
ul:only-child {
  margin-bottom: 0; }

h4,
h5,
h6 {
  margin-bottom: 0.5rem; }

em {
  display: inline-block; }

button {
  border-radius: 0;
  color: inherit;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }

img:not([src]) {
  visibility: hidden; }

img[data-original] {
  opacity: 0;
  transition: opacity .5s; }

img.is-loaded {
  opacity: 1; }

iframe {
  max-width: 100%; }

hr {
  border-color: #ccc; }
  hr.hr--margin-small {
    margin: 0; }

@-moz-document url-prefix() {
  img:-moz-loading {
    visibility: hidden; } }

h1 {
  color: #0f2c52;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 32px; }
  @media screen and (max-width: 53.1875em) {
    h1 {
      font-size: 1.875rem; } }

h2 {
  color: #404040;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2; }
  @media screen and (max-width: 53.1875em) {
    h2 {
      font-size: 1.625rem; } }

h3 {
  color: #e36018;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2; }
  @media screen and (max-width: 53.1875em) {
    h3 {
      font-size: 1.5rem; } }

h4 {
  color: #242424;
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 1.2; }
  @media screen and (max-width: 53.1875em) {
    h4 {
      font-size: 1.25rem; } }

h5 {
  color: #c6a16d;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2; }

h6 {
  font-weight: 600;
  line-height: 1.2; }

.rtf {
  /* YAPL
name: Button
partial: sg-button
selector: .button
*/
  /* YAPL
name: Button Hollow
notes: This has a white border and text so should be used on a color background
partial: sg-button-hollow
selector: .button.button--hollow
*/
  /* YAPL
name: Button Hollow Light Brown
partial: sg-button-hollow--light-brown
selector: .button.button--hollow--light-brown
*/
  /* YAPL
name: Button Hollow Blue
partial: sg-button-hollow--blue
selector: .button.button--hollow--blue.white
*/
  /* YAPL
name: Button Microsite
partial: sg-button-microsite
selector: .button.button--microsite
*/
  /* YAPL
name: Button Rounded
partial: sg-button-rounded
selector: .button.button--rounded
*/
  /* YAPL
name: Button Icon
partial: sg-button-icon
selector: .button.button--icon
*/ }
  .rtf > *.icon, .rtf > *:only-child, .rtf > *:first-child {
    margin-top: 0; }
  @media screen and (min-width: 53.25em) {
    .rtf > *.u-float-left + p {
      margin-top: 0; } }
  .rtf figure {
    margin-bottom: 1rem; }
  .rtf h1,
  .rtf h2,
  .rtf h3,
  .rtf h4 {
    margin-top: 3rem; }
  .rtf h5 {
    margin-top: 1.5rem; }
  .rtf h6 {
    margin-top: 1rem; }
  .rtf h4,
  .rtf h5 {
    margin-bottom: 0; }
  .rtf p {
    color: #2d2c2c;
    line-height: 1.6;
    min-width: 7.5rem; }
    .rtf p.paragraph-tight {
      line-height: 1.3;
      margin-bottom: .5rem; }
    .rtf p.paragraph-extra-space {
      line-height: 1.9;
      margin-bottom: .5rem; }
  .rtf a:hover {
    color: #e36018; }
  .rtf ol,
  .rtf ul {
    list-style: none;
    margin-left: 3rem;
    overflow: hidden; }
    .rtf ol:last-child,
    .rtf ul:last-child {
      margin-bottom: 0; }
    .rtf ol li,
    .rtf ul li {
      line-height: 1.6;
      margin-bottom: 1rem;
      padding-left: 1.75rem;
      position: relative; }
      @media screen and (max-width: 53.1875em) {
        .rtf ol li,
        .rtf ul li {
          margin-bottom: .75rem; } }
      .rtf ol li::before,
      .rtf ul li::before {
        color: #e36018;
        font-weight: 700;
        left: 0;
        position: absolute;
        width: 1.5rem; }
    @media screen and (max-width: 53.1875em) {
      .rtf ol,
      .rtf ul {
        margin-left: 1rem; } }
    .rtf ol + h2,
    .rtf ol + h3,
    .rtf ol + h4,
    .rtf ol + h5,
    .rtf ol + h6,
    .rtf ul + h2,
    .rtf ul + h3,
    .rtf ul + h4,
    .rtf ul + h5,
    .rtf ul + h6 {
      margin-top: 2rem; }
  .rtf ol {
    counter-reset: ol-counter; }
    .rtf ol ol {
      margin-left: 0;
      margin-top: 1rem; }
    .rtf ol li::before {
      content: counter(ol-counter) ".";
      counter-increment: ol-counter;
      line-height: 1.6;
      text-align: right; }
    .rtf ol li li::before {
      content: counter(ol-counter, lower-alpha) "."; }
    .rtf ol li li:last-child {
      margin-bottom: 0; }
  .rtf ul ul {
    margin-left: 0;
    margin-top: 1rem; }
  .rtf ul li {
    margin-bottom: 1.1rem; }
    @media screen and (max-width: 53.1875em) {
      .rtf ul li {
        margin-bottom: .75rem; } }
    .rtf ul li::before {
      content: '•';
      display: flex;
      font-size: 1.75rem;
      line-height: .9;
      text-align: center; }
    .rtf ul li li {
      padding-left: 1.25rem; }
      .rtf ul li li::before {
        content: '○';
        font-size: 0.875rem;
        line-height: 1.7; }
      .rtf ul li li:last-child {
        margin-bottom: 0; }
      .rtf ul li li li {
        padding-left: 1.25rem; }
        .rtf ul li li li::before {
          content: '•';
          font-size: 1rem;
          line-height: 1.6; }
        .rtf ul li li li:last-child {
          margin-bottom: 0; }
  .rtf .button {
    border: 2px solid #0071b9;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1em 1.5em;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s; }
    .rtf .button .icon {
      font-size: .75em; }
    .rtf .button:hover {
      background-color: transparent;
      border: 2px solid #0071b9;
      color: #0071b9; }
    .rtf .button.button--hollow {
      background-color: transparent;
      border: 2px solid #fefefe;
      color: #fefefe;
      display: inline-block;
      position: relative;
      z-index: 1; }
      .rtf .button.button--hollow:hover {
        background-color: #fefefe;
        color: #e36018; }
    .rtf .button.button--hollow--light-brown {
      background-color: transparent;
      border-color: #c69f6d;
      color: #aa8a5d; }
      .rtf .button.button--hollow--light-brown:hover {
        background-color: #c6a16d;
        color: #fefefe; }
    .rtf .button.button--hollow--blue {
      border-color: #0071b9;
      color: #0071b9; }
      .rtf .button.button--hollow--blue:hover {
        background-color: #0071b9;
        color: #fefefe; }
    .rtf .button.button--icon {
      align-items: center;
      display: flex;
      max-width: 240px;
      padding: .75rem;
      text-align: left;
      text-transform: none; }
      .rtf .button.button--icon .icon {
        flex-shrink: 0;
        font-size: 1em;
        margin-left: 0;
        margin-right: .5rem; }
      .rtf .button.button--icon:hover .icon {
        color: #fefefe; }
      @media screen and (max-width: 39.9375em) {
        .rtf .button.button--icon {
          padding: .5rem; }
          .rtf .button.button--icon .icon {
            margin-right: .25rem; } }
    .rtf .button.button--rounded {
      border-radius: 27px; }
    .rtf .button.button--microsite {
      background-color: transparent;
      border-radius: 24px;
      color: #0071b9; }
      .rtf .button.button--microsite:hover {
        background-color: #0071b9;
        color: #fefefe; }
    .rtf .button.button--no-margin {
      margin: 0; }
  .rtf .button__container {
    align-items: center;
    display: flex;
    margin-bottom: 1rem; }
    .rtf .button__container a,
    .rtf .button__container button {
      margin-right: 1rem; }
    @media screen and (max-width: 53.1875em) {
      .rtf .button__container {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start; } }
  .rtf img.float-left, .rtf img.float-right {
    width: auto; }
  .rtf img.float-left {
    margin-right: 1.5rem; }
  .rtf img.float-right {
    margin-left: 1.5rem; }
  @media screen and (max-width: 63.9375em) {
    .rtf {
      font-size: 0.875rem; } }
  @media screen and (max-width: 53.1875em) {
    .rtf h1,
    .rtf h2,
    .rtf h3,
    .rtf h4 {
      margin-top: 1.5rem; }
    .rtf h5,
    .rtf h6 {
      margin-top: 1.5rem; } }

.small {
  font-size: 0.875rem; }

.medium {
  font-size: 1.125rem; }

.large {
  font-size: 1.5rem; }

.semibold {
  font-weight: 600; }

.bold {
  font-weight: 700; }

.italic {
  font-style: italic; }

.orange {
  color: #e36018; }

.gray {
  color: #242424; }

.medium-brown {
  color: #976f3f; }

.red {
  color: #f42020; }

.icon--phone:before {
  content: '\e800'; }

/* '' */
.icon--search:before {
  content: '\e801'; }

/* '' */
.icon--minus:before {
  content: '\e802'; }

/* '' */
.icon--plus:before {
  content: '\e803'; }

/* '' */
.icon--th:before {
  content: '\e804'; }

/* '' */
.icon--twitter:before {
  content: '\e805'; }

/* '' */
.icon--instagram:before {
  content: '\e806'; }

/* '' */
.icon--youtube-play:before {
  content: '\e807'; }

/* '' */
.icon--linkedin:before {
  content: '\e808'; }

/* '' */
.icon--briefcase:before {
  content: '\e809'; }

/* '' */
.icon--times:before {
  content: '\e80a'; }

/* '' */
.icon--appt:before {
  content: '\e80b'; }

/* '' */
.icon--arrow-right:before {
  content: '\e80c'; }

/* '' */
.icon--instagram-1:before {
  content: '\e80d'; }

/* '' */
.icon--location:before {
  content: '\e80e'; }

/* '' */
.icon--make-an-appt:before {
  content: '\e80f'; }

/* '' */
.icon--news:before {
  content: '\e810'; }

/* '' */
.icon--physician:before {
  content: '\e811'; }

/* '' */
.icon--facebook:before {
  content: '\e812'; }

/* '' */
.icon--play:before {
  content: '\e813'; }

/* '' */
.icon--view-all-event:before {
  content: '\e814'; }

/* '' */
.icon--chevron-right:before {
  content: '\e815'; }

/* '' */
.icon--find-a-doc:before {
  content: '\e816'; }

/* '' */
.icon--patient-story:before {
  content: '\e817'; }

/* '' */
.icon--chevron-down:before {
  content: '\e818'; }

/* '' */
.icon--envelope-o:before {
  content: '\e819'; }

/* '' */
.icon--treatment:before {
  content: '\e81a'; }

/* '' */
.icon--mail:before {
  content: '\e81b'; }

/* '' */
.icon--chevron-left:before {
  content: '\e81c'; }

/* '' */
.icon--check:before {
  content: '\e81d'; }

/* '' */
.icon--reset:before {
  content: '\e81e'; }

/* '' */
.icon--arrow-left:before {
  content: '\e81f'; }

/* '' */
.icon--chevron-up:before {
  content: '\e820'; }

/* '' */
.icon--print:before {
  content: '\e821'; }

/* '' */
@font-face {
  font-family: 'lifebridge';
  src: url("../fonts/fontello.eot?36924966");
  src: url("../fonts/fontello.eot?36924966#iefix") format("embedded-opentype"), url("../fonts/fontello.woff2?36924966") format("woff2"), url("../fonts/fontello.woff?36924966") format("woff"), url("../fonts/fontello.ttf?36924966") format("truetype"), url("../fonts/fontello.svg?36924966#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }


.icon {
  display: inline-block;
  font-size: 1em;
  margin-right: .3em;
  vertical-align: middle; }
  
  .icon::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'lifebridge' !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 1;
    speak: none;
    text-transform: none !important; }
  .cta
  .icon,
  .rtf
  .icon,
  p
  .icon {
    display: inline-block; }

.icon--small {
  font-size: 0.75rem; }

.icon--large {
  font-size: 1.5rem; }

.icon--very-large {
  font-size: 3rem; }

.icon--center {
  margin-right: 0; }

.icon--circle {
  border-radius: 28px;
  height: 56px;
  width: 56px; }

.icon--margin-right-half {
  margin-right: 0.5rem !important; }

.icon--reverse {
  transform: rotate(180deg); }

.icon__background {
  background-color: #e36018;
  color: #fefefe;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: background-color .3s; }
  .icon__background::before {
    position: absolute; }

.icon__background--large {
  border-radius: 25px;
  height: 50px;
  width: 50px; }
  .icon__background--large::before {
    left: calc(50% - 5px);
    top: calc(50% - 5px); }

.icon__background--medium {
  border-radius: 21px;
  height: 42px;
  width: 42px; }
  .icon__background--medium::before {
    left: calc(50% - 14px);
    position: absolute;
    top: calc(50% - 14px); }

.icon__background--small {
  border-radius: 18px;
  height: 36px;
  width: 36px; }
  .icon__background--small::before {
    left: calc(50% - 7px);
    top: calc(50% - 7px); }

.icon__background--very-small {
  border-radius: 12px;
  height: 24px;
  width: 24px; }
  .icon__background--very-small::before {
    font-size: 0.625rem;
    left: calc(50% - 4px);
    top: calc(50% - 5px); }

.icon--position-center {
  left: 50%;
  margin-right: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }
  .icon--position-center.icon--play {
    padding-left: 3px;
    padding-top: 4px; }

.u-block {
  display: block; }

@media screen and (max-width: 39.9375em) {
  .u-block--small {
    display: block; } }

.u-clearfix::before, .u-clearfix::after {
  content: '';
  display: table; }

.u-clearfix::after {
  clear: both; }

.u-uppercase {
  text-transform: uppercase !important; }

.u-no-margin {
  margin: 0 !important; }

.u-float-left {
  float: left; }

.u-float-right {
  float: right; }

@media screen and (min-width: 53.25em) {
  .u-float-right--medium {
    float: right; } }

.basic-tabs.tabs--vertical .tabs-title:first-child {
  margin-top: 0rem; }

.vertical-margin-0 {
  margin-top: 0rem !important; }

.vertical-padding-0 {
  padding-top: 0rem !important; }

.rtf h4 + p,
.rtf h5 + p, .basic-tabs.tabs--vertical .tabs-title {
  margin-top: 0.5rem; }

.vertical-margin-half {
  margin-top: 0.5rem !important; }

.vertical-padding-half {
  padding-top: 0.5rem !important; }

.rtf > * {
  margin-top: 1rem; }

.vertical-margin-1 {
  margin-top: 1rem !important; }

.vertical-padding-1 {
  padding-top: 1rem !important; }

.vertical-margin-2 {
  margin-top: 2rem !important; }

.vertical-padding-2 {
  padding-top: 2rem !important; }

.vertical-margin-3 {
  margin-top: 3rem !important; }

.vertical-padding-3 {
  padding-top: 3rem !important; }

.vertical-margin-4 {
  margin-top: 4rem !important; }

.vertical-padding-4 {
  padding-top: 4rem !important; }

.vertical-margin-5 {
  margin-top: 5rem !important; }

.vertical-padding-5 {
  padding-top: 5rem !important; }

.vertical-margin-6 {
  margin-top: 6rem !important; }

.vertical-padding-6 {
  padding-top: 6rem !important; }

.vertical-margin-7 {
  margin-top: 7rem !important; }

.vertical-padding-7 {
  padding-top: 7rem !important; }

.vertical-margin-8 {
  margin-top: 8rem !important; }

.vertical-padding-8 {
  padding-top: 8rem !important; }

.vertical-margin-9 {
  margin-top: 9rem !important; }

.vertical-padding-9 {
  padding-top: 9rem !important; }

.vertical-margin-10 {
  margin-top: 10rem !important; }

.vertical-padding-10 {
  padding-top: 10rem !important; }

.vertical-margin-11 {
  margin-top: 11rem !important; }

.vertical-padding-11 {
  padding-top: 11rem !important; }

.vertical-margin-12 {
  margin-top: 12rem !important; }

.vertical-padding-12 {
  padding-top: 12rem !important; }

.vertical-margin-0-small {
  margin-top: 0rem !important; }

.vertical-padding-0-small {
  padding-top: 0rem !important; }

.vertical-margin-half-1-small {
  margin-top: 0.5rem !important; }

.vertical-padding-half-1-small {
  padding-top: 0.5rem !important; }

.vertical-margin-1-small {
  margin-top: 1rem !important; }

.vertical-padding-1-small {
  padding-top: 1rem !important; }

.vertical-margin-2-small {
  margin-top: 2rem !important; }

.vertical-padding-2-small {
  padding-top: 2rem !important; }

.vertical-margin-3-small {
  margin-top: 3rem !important; }

.vertical-padding-3-small {
  padding-top: 3rem !important; }

.vertical-margin-4-small {
  margin-top: 4rem !important; }

.vertical-padding-4-small {
  padding-top: 4rem !important; }

.vertical-margin-5-small {
  margin-top: 5rem !important; }

.vertical-padding-5-small {
  padding-top: 5rem !important; }

.vertical-margin-6-small {
  margin-top: 6rem !important; }

.vertical-padding-6-small {
  padding-top: 6rem !important; }

.vertical-margin-7-small {
  margin-top: 7rem !important; }

.vertical-padding-7-small {
  padding-top: 7rem !important; }

.vertical-margin-8-small {
  margin-top: 8rem !important; }

.vertical-padding-8-small {
  padding-top: 8rem !important; }

.vertical-margin-9-small {
  margin-top: 9rem !important; }

.vertical-padding-9-small {
  padding-top: 9rem !important; }

.vertical-margin-10-small {
  margin-top: 10rem !important; }

.vertical-padding-10-small {
  padding-top: 10rem !important; }

.vertical-margin-11-small {
  margin-top: 11rem !important; }

.vertical-padding-11-small {
  padding-top: 11rem !important; }

.vertical-margin-12-small {
  margin-top: 12rem !important; }

.vertical-padding-12-small {
  padding-top: 12rem !important; }

@media print, screen and (min-width: 40em) {
  .vertical-margin-0-phablet {
    margin-top: 0rem !important; }
  .vertical-padding-0-phablet {
    padding-top: 0rem !important; }
  .vertical-margin-half-1-phablet {
    margin-top: 0.5rem !important; }
  .vertical-padding-half-1-phablet {
    padding-top: 0.5rem !important; }
  .vertical-margin-1-phablet {
    margin-top: 1rem !important; }
  .vertical-padding-1-phablet {
    padding-top: 1rem !important; }
  .vertical-margin-2-phablet {
    margin-top: 2rem !important; }
  .vertical-padding-2-phablet {
    padding-top: 2rem !important; }
  .vertical-margin-3-phablet {
    margin-top: 3rem !important; }
  .vertical-padding-3-phablet {
    padding-top: 3rem !important; }
  .vertical-margin-4-phablet {
    margin-top: 4rem !important; }
  .vertical-padding-4-phablet {
    padding-top: 4rem !important; }
  .vertical-margin-5-phablet {
    margin-top: 5rem !important; }
  .vertical-padding-5-phablet {
    padding-top: 5rem !important; }
  .vertical-margin-6-phablet {
    margin-top: 6rem !important; }
  .vertical-padding-6-phablet {
    padding-top: 6rem !important; }
  .vertical-margin-7-phablet {
    margin-top: 7rem !important; }
  .vertical-padding-7-phablet {
    padding-top: 7rem !important; }
  .vertical-margin-8-phablet {
    margin-top: 8rem !important; }
  .vertical-padding-8-phablet {
    padding-top: 8rem !important; }
  .vertical-margin-9-phablet {
    margin-top: 9rem !important; }
  .vertical-padding-9-phablet {
    padding-top: 9rem !important; }
  .vertical-margin-10-phablet {
    margin-top: 10rem !important; }
  .vertical-padding-10-phablet {
    padding-top: 10rem !important; }
  .vertical-margin-11-phablet {
    margin-top: 11rem !important; }
  .vertical-padding-11-phablet {
    padding-top: 11rem !important; }
  .vertical-margin-12-phablet {
    margin-top: 12rem !important; }
  .vertical-padding-12-phablet {
    padding-top: 12rem !important; } }

@media print, screen and (min-width: 53.25em) {
  .vertical-margin-0-medium {
    margin-top: 0rem !important; }
  .vertical-padding-0-medium {
    padding-top: 0rem !important; }
  .vertical-margin-half-1-medium {
    margin-top: 0.5rem !important; }
  .vertical-padding-half-1-medium {
    padding-top: 0.5rem !important; }
  .vertical-margin-1-medium {
    margin-top: 1rem !important; }
  .vertical-padding-1-medium {
    padding-top: 1rem !important; }
  .vertical-margin-2-medium {
    margin-top: 2rem !important; }
  .vertical-padding-2-medium {
    padding-top: 2rem !important; }
  .vertical-margin-3-medium {
    margin-top: 3rem !important; }
  .vertical-padding-3-medium {
    padding-top: 3rem !important; }
  .vertical-margin-4-medium {
    margin-top: 4rem !important; }
  .vertical-padding-4-medium {
    padding-top: 4rem !important; }
  .vertical-margin-5-medium {
    margin-top: 5rem !important; }
  .vertical-padding-5-medium {
    padding-top: 5rem !important; }
  .vertical-margin-6-medium {
    margin-top: 6rem !important; }
  .vertical-padding-6-medium {
    padding-top: 6rem !important; }
  .vertical-margin-7-medium {
    margin-top: 7rem !important; }
  .vertical-padding-7-medium {
    padding-top: 7rem !important; }
  .vertical-margin-8-medium {
    margin-top: 8rem !important; }
  .vertical-padding-8-medium {
    padding-top: 8rem !important; }
  .vertical-margin-9-medium {
    margin-top: 9rem !important; }
  .vertical-padding-9-medium {
    padding-top: 9rem !important; }
  .vertical-margin-10-medium {
    margin-top: 10rem !important; }
  .vertical-padding-10-medium {
    padding-top: 10rem !important; }
  .vertical-margin-11-medium {
    margin-top: 11rem !important; }
  .vertical-padding-11-medium {
    padding-top: 11rem !important; }
  .vertical-margin-12-medium {
    margin-top: 12rem !important; }
  .vertical-padding-12-medium {
    padding-top: 12rem !important; } }

@media print, screen and (min-width: 64em) {
  .vertical-margin-0-large {
    margin-top: 0rem !important; }
  .vertical-padding-0-large {
    padding-top: 0rem !important; }
  .vertical-margin-half-1-large {
    margin-top: 0.5rem !important; }
  .vertical-padding-half-1-large {
    padding-top: 0.5rem !important; }
  .vertical-margin-1-large {
    margin-top: 1rem !important; }
  .vertical-padding-1-large {
    padding-top: 1rem !important; }
  .vertical-margin-2-large {
    margin-top: 2rem !important; }
  .vertical-padding-2-large {
    padding-top: 2rem !important; }
  .vertical-margin-3-large {
    margin-top: 3rem !important; }
  .vertical-padding-3-large {
    padding-top: 3rem !important; }
  .vertical-margin-4-large {
    margin-top: 4rem !important; }
  .vertical-padding-4-large {
    padding-top: 4rem !important; }
  .vertical-margin-5-large {
    margin-top: 5rem !important; }
  .vertical-padding-5-large {
    padding-top: 5rem !important; }
  .vertical-margin-6-large {
    margin-top: 6rem !important; }
  .vertical-padding-6-large {
    padding-top: 6rem !important; }
  .vertical-margin-7-large {
    margin-top: 7rem !important; }
  .vertical-padding-7-large {
    padding-top: 7rem !important; }
  .vertical-margin-8-large {
    margin-top: 8rem !important; }
  .vertical-padding-8-large {
    padding-top: 8rem !important; }
  .vertical-margin-9-large {
    margin-top: 9rem !important; }
  .vertical-padding-9-large {
    padding-top: 9rem !important; }
  .vertical-margin-10-large {
    margin-top: 10rem !important; }
  .vertical-padding-10-large {
    padding-top: 10rem !important; }
  .vertical-margin-11-large {
    margin-top: 11rem !important; }
  .vertical-padding-11-large {
    padding-top: 11rem !important; }
  .vertical-margin-12-large {
    margin-top: 12rem !important; }
  .vertical-padding-12-large {
    padding-top: 12rem !important; } }

/* YAPL
name: Breadcrumb Navigation
partial: breadcrumbs
context: breadcrumbs.mastersub
selector: .breadcrumbs
*/
.breadcrumbs {
  align-items: center;
  background-color: #0071b9;
  display: flex;
  font-size: 16px;
  height: 56px;
  list-style-type: none;
  margin-bottom: 0; }
  @media screen and (max-width: 63.9375em) {
    .breadcrumbs {
      display: none; } }
  .breadcrumbs ul {
    list-style-type: none; }
    .breadcrumbs ul li {
      font-size: 14px;
      text-transform: capitalize; }
      .breadcrumbs ul li::after {
        content: '' !important; }
      .breadcrumbs ul li:last-child a {
        text-decoration: none; }
      .breadcrumbs ul li .icon {
        font-size: 10px; }
    .breadcrumbs ul a {
      color: #fefefe;
      padding-right: 20px;
      text-decoration: underline;
      text-decoration-color: #aaebe6;
      transition: 0.3s; }
      .breadcrumbs ul a:hover {
        color: #aaebe6; }

.footer-main {
  background-color: #0f2c52;
  color: #fefefe;
  min-height: 184px;
  padding: 0 0 73px;
  position: relative; }
  @media screen and (max-width: 53.1875em) {
    .footer-main {
      padding-bottom: 1rem; } }

.footer-main__container {
  position: relative; }
  @media screen and (max-width: 53.1875em) {
    .footer-main__container {
      padding-left: 0;
      padding-right: 0; } }

.footer-main__logo {
  background-image: url("../img/logo-reversed.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 48px;
  min-width: 440px;
  padding-right: 1rem; }
  @media screen and (max-width: 53.1875em) {
    .footer-main__logo {
      background-size: 100% auto;
      height: auto;
      min-width: auto;
      padding-bottom: 2rem;
      padding-top: 2rem;
      width: 330px; } }
  .sublocation .footer-main__logo {
    background-image: url("../img/logo-sinai-white.svg");
    min-width: 336px; }
    @media screen and (max-width: 53.1875em) {
      .sublocation .footer-main__logo {
        margin-top: 20px; } }

.footer__utility {
  background-color: #0c2547; }

.footer__utility-nav {
  align-items: center;
  color: #fefefe;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 85px; }
  .footer__utility-nav div {
    align-items: center;
    display: flex;
    justify-content: center; }
  .footer__utility-nav a {
    color: inherit;
    font-size: 0.8125rem; }
    .footer__utility-nav a:hover {
      color: #f48142; }
  .footer__utility-nav .icon {
    font-size: 0.75rem;
    margin-right: 2px;
    margin-top: 3px; }
  @media screen and (max-width: 63.9375em) {
    .footer__utility-nav {
      flex-direction: column;
      padding: 2rem 0; }
      .footer__utility-nav div {
        font-weight: 600;
        margin-bottom: 1.5rem; }
        .footer__utility-nav div:last-child {
          margin-bottom: 0; }
      .footer__utility-nav .icon {
        display: none; } }

.footer-main__info {
  align-items: center;
  display: flex;
  padding-top: 58px; }
  @media screen and (max-width: 53.1875em) {
    .footer-main__info {
      flex-direction: column;
      padding-top: 78px; } }

.footer-main__address {
  border-left: 2px solid #8796a9;
  padding-left: 1rem;
  text-align: center; }
  .footer-main__address h5 {
    color: #fefefe;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase; }
  .footer-main__address p {
    font-size: 0.9375rem;
    margin-bottom: 0; }
  @media screen and (min-width: 64em) {
    .footer-main__address {
      text-align: left; } }
  @media screen and (max-width: 53.1875em) {
    .footer-main__address {
      border-left: 0;
      border-top: 1px solid rgba(63, 86, 117, 0.6);
      margin-top: 1rem;
      padding-bottom: 1rem;
      padding-top: 1.5rem;
      width: 100%; } }

@media screen and (max-width: 63.9375em) {
  .grid-container {
    padding-left: 1rem;
    padding-right: 1rem; } }

@media screen and (max-width: 63.9375em) {
  .grid-full-small {
    padding-left: 0;
    padding-right: 0; } }

@media screen and (min-width: 53.25em) {
  .grid-margin-wide .cell.medium-6 {
    width: calc(50% - 2rem); }
    .grid-margin-wide .cell.medium-6:first-child {
      margin-left: 0;
      margin-right: 2rem; }
    .grid-margin-wide .cell.medium-6:last-child {
      margin-left: 2rem;
      margin-right: 0; } }

.header-main {
  position: relative;
  transition: .25s height linear;
  z-index: 10; }
  @media screen and (max-width: 63.9375em) {
    .header-main {
      border-top: 8px solid #0f2c52; }
      .is-nav-open .header-main {
        height: 100vh !important; }
        .is-nav-open .header-main .header-main__sticky {
          height: 100vh !important; }
        .is-nav-open .header-main .header-main__title-bar {
          border-bottom: 0.0625rem solid #e1e1e1; }
      .header-main.header--careers, .header-main.header--microsite, .header-main.header--campaign {
        border-top: 0; } }

.header-main__sticky {
  background: #fefefe;
  box-shadow: 0 0 1.5625rem rgba(10, 10, 10, 0.25);
  height: 146px;
  transition: height 0.3s;
  z-index: 5; }
  .header-main__sticky.is-stuck {
    height: 85px; }
    .is-expanded .header-main__sticky.is-stuck {
      height: 146px; }
  @media screen and (max-width: 63.9375em) {
    .header-main__sticky {
      height: 72px; }
      .header-main__sticky.is-stuck {
        height: 72px; }
      .is-nav-open .sublocation .header-main__sticky {
        padding-top: 20px; } }
  .header--careers .header-main__sticky {
    border-top: 10px solid #0f2c52;
    height: 118px; }
    .header--careers .header-main__sticky.is-stuck {
      height: 85px; }
      .is-expanded .header--careers .header-main__sticky.is-stuck {
        height: 118px; }
    @media screen and (max-width: 53.1875em) {
      .header--careers .header-main__sticky {
        height: 82px; }
        .is-stuck .header--careers .header-main__sticky {
          border-top: 10px solid #0f2c52; } }
  .header--campaign .header-main__sticky {
    background-color: transparent;
    border-top: 10px solid #0f2c52;
    box-shadow: 0 0 0 0;
    height: 123px;
    transition: background-color 0.3s; }
    .header--campaign .header-main__sticky.is-stuck {
      background-color: #0f2c52; }
      .is-expanded .header--campaign .header-main__sticky.is-stuck {
        height: 118px; }
    @media screen and (max-width: 63.9375em) {
      .header--campaign .header-main__sticky {
        height: 82px; } }
  .header--microsite .header-main__sticky {
    background-color: transparent;
    border-top: 10px solid #e36018;
    box-shadow: 0 0 0 0;
    height: 157px;
    transition: background-color 0.3s; }
    .header--microsite .header-main__sticky.is-stuck {
      background-color: #fefefe;
      box-shadow: 0 0 1.5625rem rgba(10, 10, 10, 0.25);
      height: 137px; }
      .is-expanded .header--microsite .header-main__sticky.is-stuck {
        height: 137px; }
  .header-microsite--sub .header-main__sticky {
    background-color: #fefefe;
    box-shadow: 0 0 1.5625rem rgba(10, 10, 10, 0.25);
    height: 137px; }

.header-main__container {
  height: 108px;
  transition: height 0.3s;
  will-change: height; }
  .is-stuck .header-main__container {
    height: 82px; }
    .is-expanded .is-stuck .header-main__container {
      height: 108px; }
    .header--microsite .is-stuck .header-main__container {
      height: 108px; }
    .header--campaign .is-stuck .header-main__container {
      height: 52px; }
  @media screen and (max-width: 63.9375em) {
    .is-nav-open .header--campaign .header-main__container {
      background-color: #0f2c52; } }
  @media screen and (max-width: 63.9375em) {
    .header-main__container {
      height: 72px; }
      .is-stuck .header-main__container {
        height: 72px; } }

.header-main__title {
  background-image: url("../img/logo.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 53px;
  margin-bottom: 0;
  margin-top: 0;
  min-width: 381px;
  transition: margin 0.3s, height 0.3s;
  will-change: height, margin; }
  .is-stuck .header-main__title {
    height: 40px; }
    .sublocation .is-stuck .header-main__title {
      height: 32px; }
  @media screen and (max-width: 63.9375em) {
    .header-main__title {
      background-position: 12px 18px;
      background-size: 220px auto;
      height: 64px;
      min-width: auto;
      width: 240px; }
      .is-stuck .header-main__title {
        height: 53px; }
        .sublocation .is-stuck .header-main__title {
          height: 53px; } }
  .sublocation .header-main__title {
    background-image: url("../img/logo-sinai.svg");
    height: 43px;
    margin-top: 12px;
    min-width: 260px; }
    @media screen and (max-width: 63.9375em) {
      .sublocation .header-main__title {
        height: 64px;
        margin-top: 0; } }

.header-main__title--other {
  margin-bottom: 0;
  margin-top: 0; }
  .header-main__title--other > img {
    max-height: 50px; }
  @media screen and (max-width: 63.9375em) {
    .header-main__title--other {
      margin-left: 1rem;
      max-height: 50px; } }

.header__logo--microsite {
  margin-top: 0 !important; }
  @media screen and (max-width: 63.9375em) {
    .is-stuck .header__logo--microsite {
      margin-top: 0; } }
  .header__logo--microsite img {
    margin-top: 3rem;
    max-height: 120px;
    transition: max-height 0.3s, margin-top 0.3s; }
    @media screen and (max-width: 63.9375em) {
      .header__logo--microsite img {
        margin-left: 1rem;
        margin-top: 1rem; }
        .is-stuck .header__logo--microsite img {
          margin-top: 1rem !important; } }
  .is-stuck .header__logo--microsite img {
    margin-top: 2rem;
    max-height: 90px; }
  .header-microsite--sub .header__logo--microsite img {
    margin-top: 16px;
    max-height: 90px; }
  .is-stuck .header-microsite--sub .header__logo--microsite img {
    margin-top: 0; }
  @media screen and (max-width: 53.1875em) {
    .header__logo--microsite {
      margin-top: 0; }
      .header__logo--microsite img {
        max-height: 70px; } }

.header__logo--careers {
  margin-top: 0;
  position: relative;
  transition: margin-top 0.3s; }
  .is-stuck .header__logo--careers {
    max-height: 90px; }
  @media screen and (max-width: 63.9375em) {
    .header__logo--careers {
      margin-left: 1rem; } }
  @media screen and (max-width: 53.1875em) {
    .header__logo--careers {
      margin-top: 1rem; }
      .header__logo--careers img {
        max-height: 30px; } }

.header__back-link {
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 2rem;
  position: absolute; }
  .footer-main .header__back-link {
    top: 8px;
    z-index: 10; }
    .footer-main .header__back-link:hover {
      color: #41b5ff; }
      .footer-main .header__back-link:hover .icon {
        color: #41b5ff; }
  .header__back-link .icon {
    color: #0071b9;
    font-size: 1.375rem;
    position: relative;
    top: -4px; }
  @media screen and (max-width: 63.9375em) {
    .header__back-link {
      display: none; }
      .is-nav-open .header__back-link {
        display: block;
        margin-left: 12px;
        margin-top: -20px; }
      .footer-main .header__back-link {
        display: block;
        margin-left: 20px;
        top: 60px; } }

.main {
  background-color: #fff;
  padding-top: 10px; }

@media screen and (max-width: 63.9375em) {
  .nav-main {
    background-color: #fefefe;
    border-top: 2px solid #b7b7b7;
    width: 100%; } }

.nav-main.nav--campaign {
  align-items: center;
  background-color: rgba(0, 113, 185, 0.8);
  display: flex;
  height: 41px;
  justify-content: space-around;
  width: 100%; }
  @media screen and (max-width: 63.9375em) {
    .nav-main.nav--campaign {
      align-items: flex-start; } }
  @media screen and (max-width: 63.9375em) {
    .is-nav-open .nav-main.nav--campaign {
      background-color: #0071b9; } }

.nav--campaign-wrap {
  height: 41px;
  width: 100%; }
  .is-stuck .nav--campaign-wrap {
    background-color: #0071b9;
    margin-top: 8px; }
  @media screen and (max-width: 63.9375em) {
    .nav--campaign-wrap {
      background-color: none;
      height: 0;
      margin: 0; } }

.nav-main__links {
  justify-content: flex-end;
  margin-right: 40px; }
  @media screen and (max-width: 63.9375em) {
    .nav-main__links {
      margin-right: 0; } }
  .nav--campaign .nav-main__links {
    justify-content: space-between;
    margin-right: 0;
    width: 100%; }
  .nav--microsite .nav-main__links {
    margin-right: 10px; }
    @media screen and (max-width: 63.9375em) {
      .nav--microsite .nav-main__links {
        margin-right: 0; } }
  .nav--careers .nav-main__links {
    margin-right: 10px; }

.nav-main__link {
  color: #2f2e2e;
  font-size: 1rem;
  font-weight: 700;
  margin-right: 12px;
  padding-left: 18px;
  text-transform: uppercase; }
  @media screen and (max-width: 63.9375em) {
    .nav-main__link {
      border-bottom: 1px solid #b7b7b7;
      padding-bottom: 6px;
      padding-top: 6px; } }
  .nav-main__link:hover {
    color: #e36018; }
  .nav-main__link > a {
    color: inherit;
    padding-left: 22px;
    padding-right: 0; }
  .nav-main__link .icon {
    font-size: .8em; }
  .nav--campaign .nav-main__link {
    color: #fefefe;
    font-size: 0.8125rem; }
    @media screen and (max-width: 63.9375em) {
      .nav--campaign .nav-main__link {
        color: #0a0a0a;
        font-size: 1rem; } }
  .nav--microsite .nav-main__link {
    color: #fefefe;
    font-size: 0.875rem;
    margin-right: 4px;
    padding-left: 10px;
    transition: color 0.3s; }
    .is-stuck .nav--microsite .nav-main__link,
    .header-microsite--sub .nav--microsite .nav-main__link {
      color: #2f2e2e; }
    @media screen and (max-width: 63.9375em) {
      .nav--microsite .nav-main__link {
        color: #0f2c52; } }
  .nav--careers .nav-main__link {
    margin-right: 4px;
    padding-left: 10px; }

@media screen and (max-width: 63.9375em) {
  .nav-main {
    background-color: #0f2c52; }
    .is-nav-open .nav-main {
      height: calc(100vh - 72px);
      overflow: auto;
      position: relative; }
  .nav-main__menu > .nav-main__utility {
    margin-top: 34px; }
  .nav-main__menu > .nav-main__utility ~ .nav-main__utility {
    margin-top: 0; }
  .nav-main__menu:last-child {
    padding-bottom: 0; }
  .nav-main__link {
    background-color: #fefefe; }
  .nav-main__utility {
    font-size: 0.9375rem;
    font-weight: 600;
    padding-bottom: 1px;
    padding-top: 1px;
    text-align: center; }
    .nav-main__utility a {
      background-color: #fefefe;
      color: #0564a4;
      height: 50px;
      line-height: 50px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      width: 80%; }
  .nav-main__menu-item {
    overflow: hidden; }
    .nav-main__menu-item:first-child {
      border-top: 0;
      margin-top: 34px; } }

.nav-mobile {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  position: absolute;
  right: 12px;
  top: 20px; }
  .nav-mobile.is-menu-open .search__toggle {
    opacity: 0; }
  @media screen and (max-width: 63.9375em) {
    .nav-mobile {
      top: 16px; }
      .header--campaign .nav-mobile {
        top: 10px; } }

.nav-mobile__button {
  color: #2f2e2e;
  font-size: 1.625rem;
  line-height: 1;
  margin-left: 1rem;
  padding: 0 .25rem; }
  .nav-mobile__button .icon::before {
    display: block;
    font-size: inherit; }
  .nav-mobile__button:first-child {
    margin-left: 0; }
  .nav-mobile__button:last-child {
    padding-right: 0; }

@media screen and (max-width: 39.9375em) {
  .nav-mobile__button {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    padding: 0; } }

.nav__secondary {
  border-bottom-left-radius: 40px;
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2); }
  @media screen and (min-width: 64em) {
    .nav__secondary {
      max-width: 346px; } }

.nav-secondary__toggle {
  cursor: pointer; }
  .is-expanded .nav-secondary__toggle .icon::before {
    content: 'd'; }

.nav__secondary-level {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0; }
  @media screen and (max-width: 63.9375em) {
    .nav__secondary-level.nav__secondary__level1 {
      height: auto;
      opacity: 1;
      padding: 8px 20px; }
      .nav__secondary-level.nav__secondary__level1 li {
        align-items: center;
        display: flex; }
    .nav__secondary-level.nav__secondary__level2, .nav__secondary-level.nav__secondary__level3, .nav__secondary-level.nav__secondary__level4, .nav__secondary-level.nav__secondary__level5, .nav__secondary-level.nav__secondary__level6 {
      border: 0;
      height: 0;
      opacity: 0;
      padding: 0;
      pointer-events: none; }
      .expanded .nav__secondary-level.nav__secondary__level2, .expanded .nav__secondary-level.nav__secondary__level3, .expanded .nav__secondary-level.nav__secondary__level4, .expanded .nav__secondary-level.nav__secondary__level5, .expanded .nav__secondary-level.nav__secondary__level6 {
        height: auto;
        opacity: 1;
        padding: 8px 20px;
        pointer-events: auto;
        transition: height 0.3s, opacity 0.3s; }
      .expanded .nav__secondary-level.nav__secondary__level2.nav__secondary__level2, .expanded .nav__secondary-level.nav__secondary__level3.nav__secondary__level2, .expanded .nav__secondary-level.nav__secondary__level4.nav__secondary__level2, .expanded .nav__secondary-level.nav__secondary__level5.nav__secondary__level2, .expanded .nav__secondary-level.nav__secondary__level6.nav__secondary__level2 {
        padding: 14px 20px; }
      .nav__secondary-parent .nav__secondary-level.nav__secondary__level2, .nav__secondary-parent .nav__secondary-level.nav__secondary__level3, .nav__secondary-parent .nav__secondary-level.nav__secondary__level4, .nav__secondary-parent .nav__secondary-level.nav__secondary__level5, .nav__secondary-parent .nav__secondary-level.nav__secondary__level6 {
        padding-bottom: 0 !important;
        padding-right: 0 !important; } }
  .nav__secondary-level li a {
    color: inherit;
    display: block;
    width: 100%; }
    .nav__secondary-level li a.is-active {
      color: #e36018; }
  @media screen and (max-width: 63.9375em) {
    .nav__secondary-level li span {
      align-items: center;
      display: flex;
      height: 52px;
      justify-content: center;
      margin-left: auto;
      margin-right: 0;
      right: 0;
      top: 0;
      width: 52px; } }
  .nav__secondary-level li span .icon {
    font-size: 1.25rem;
    margin-right: 0; }

.nav__secondary__level1 {
  background-color: #4dbeb5;
  color: #fefefe;
  font-size: 1.375rem;
  font-weight: 600;
  padding: 14px 20px; }
  @media screen and (max-width: 63.9375em) {
    .nav__secondary__level1 {
      padding: 8px 20px; } }

.nav__secondary__level2 {
  background-color: #edf8f7;
  color: #4ab6ad;
  font-size: 1.125rem;
  font-weight: 600;
  margin-top: 0;
  padding: 14px 20px; }

.nav__secondary__level3 {
  border-top: 1px solid #ccc;
  color: #242424;
  font-size: 0.9375rem;
  font-weight: 600;
  margin-top: 0;
  padding: 0 20px 20px; }
  .nav__secondary__level3 li {
    border-bottom: 1px solid #cccccc;
    padding: 13px 0; }
    .nav__secondary__level3 li:hover {
      color: #e36018; }
    .nav__secondary__level3 li:last-child {
      border-bottom: 0; }

.nav__secondary__level4 {
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
  color: #505050;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 14px;
  padding: 0 0 0 15px; }
  .nav__secondary__level4 li {
    border-bottom: 1px solid #cccccc;
    padding: 13px 0; }
    .nav__secondary__level4 li:last-child {
      border-bottom: 0; }

.nav__secondary__level5 {
  background-color: #ececec;
  border-top: 1px solid #ccc;
  color: #242424;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 14px;
  padding: 0 0 0 15px; }
  .nav__secondary__level5 li {
    border-bottom: 1px solid #ccc;
    padding: 13px 0; }
    .nav__secondary__level5 li:last-child {
      border-bottom: 0; }

.nav__secondary__level6 {
  background-color: #e2e2e2;
  border-top: 1px solid #ccc;
  color: #242424;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 14px;
  padding: 0 0 0 15px; }
  .nav__secondary__level6 li {
    border-bottom: 1px solid #ccc;
    padding: 13px 0; }
    .nav__secondary__level6 li:last-child {
      border-bottom: 0; }

.nav__secondary-parent {
  padding-bottom: 0 !important; }

.nav-utility {
  background-color: #0f2c52;
  font-weight: 600;
  height: 38px;
  position: relative;
  transition: height 0.3s; }
  .is-stuck .nav-utility {
    height: 12px; }
    .is-expanded .is-stuck .nav-utility {
      height: 38px; }

.nav-utility__inner {
  position: relative; }

.nav-utility__links {
  color: #fff;
  font-size: 0.75rem;
  padding-right: 267px; }
  .nav-utility__links .nav-utility__link {
    color: inherit; }
    .nav-utility__links .nav-utility__link:hover {
      color: #aaebe6; }
    @media screen and (min-width: 64em) {
      .nav-utility__links .nav-utility__link {
        height: 38px;
        line-height: 38px;
        padding: 0 .75rem; }
        .is-stuck .nav-utility__links .nav-utility__link {
          pointer-events: none; } }

.nav-utility__expand-tab {
  background-color: #0f2c52;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  display: none;
  font-size: 0.8125rem;
  height: 0;
  left: calc(50% - 30px);
  position: absolute;
  top: 10px;
  transition: height 0.3s;
  width: 60px; }
  .is-stuck .nav-utility__expand-tab {
    display: block;
    height: 18px; }
    .is-expanded .is-stuck .nav-utility__expand-tab {
      display: none; }
  .nav-utility__expand-tab .icon {
    color: #fefefe;
    left: calc(50% - 6px);
    position: absolute;
    top: -2px; }

/* YAPL
name: Accordion
partial: accordion
context: accordion.article
selector: .accordion
*/
.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }
  .accordion.accordion--microsite {
    box-shadow: 0 0 0.625rem rgba(10, 10, 10, 0.25); }

.accordion-item {
  border-left: 0.625rem solid #0071b9;
  box-shadow: 0 0 0.625rem rgba(10, 10, 10, 0.25);
  margin-top: .5rem;
  transition: border-left-color .25s; }
  .accordion-item:hover, .accordion-item:focus {
    border-left-color: #115b8c; }
    @media screen and (max-width: 63.9375em) {
      .accordion-item:hover, .accordion-item:focus {
        border-left-color: #0f2c52; } }
    .accordion--microsite .accordion-item:hover, .accordion--microsite .accordion-item:focus {
      border-bottom: 1px solid #e36018; }
      .accordion--microsite .accordion-item:hover.is-active, .accordion--microsite .accordion-item:focus.is-active {
        border-bottom: 1px solid #cecece; }
  .accordion-item.is-active {
    border-left-color: #0f2c52; }
  .accordion--microsite .accordion-item {
    border-bottom: 1px solid #cecece;
    border-left: 0;
    box-shadow: 0 0 0 0;
    margin-top: 0; }
    .accordion--microsite .accordion-item.is-active {
      border-top: 10px solid #e36018; }

.accordion-title {
  align-items: center;
  border: 0;
  display: flex;
  flex-flow: row wrap;
  font-size: 1rem;
  font-weight: 700;
  justify-content: space-between;
  line-height: 1.3;
  padding: 1.75rem 1rem;
  position: relative;
  transition: background-color 0.3s, color 0.3s; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #0071b9;
    color: #fefefe; }
    .accordion-title:hover .accordion-title__link, .accordion-title:focus .accordion-title__link {
      color: #115b8c; }
    .accordion--microsite .accordion-title:hover, .accordion--microsite .accordion-title:focus {
      background-color: #e36018;
      color: #fefefe !important; }
      .accordion--microsite .accordion-title:hover:hover::before, .accordion--microsite .accordion-title:focus:hover::before {
        color: #fefefe; }
  .accordion-title::before {
    font-size: 1.5rem;
    right: 24px;
    top: 37%; }
    .accordion--microsite .accordion-title::before {
      color: #e36018; }
  .is-active .accordion-title::before {
    font-size: 1.5rem;
    top: 34%; }
  .accordion--microsite .is-active .accordion-title {
    color: #e36018; }
  .accordion--microsite .accordion-title {
    color: #242424;
    font-size: 1.125rem; }

.accordion-title__text {
  flex: 1 1 0%;
  padding-right: 38px; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
  background-color: #fefefe;
  color: #2d2c2c;
  padding-bottom: 2rem;
  padding-top: 2rem; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e1e1e1; }
  .accordion-content::before, .accordion-content::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .accordion-content::after {
    clear: both; }
  .accordion--microsite .accordion-content {
    border-top: 0;
    padding-top: 0; }

@media screen and (max-width: 53.1875em) {
  .accordion-title,
  .accordion-content {
    padding: 0.5rem; }
  .accordion-title {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem; } }

.align-vertical {
  align-items: center; }

/* YAPL
name: Alphabet List
partial: alphabet-list
selector: .alphabet-list
*/
/* YAPL
name: Alphabet List Wide
partial: alphabet-list
context: alphabetList.wide
selector: alphabet-list.alphabet-list--wide
*/
/* YAPL
name: Alphabet List Mid
partial: alphabet-list
context: alphabetList.mid
selector: alphabet-list.alphabet-list--mid
*/
.alphabet-list {
  align-items: center;
  background: #fefefe;
  justify-content: space-around;
  margin-left: 0;
  padding: 20px 14px 2px; }
  .alphabet-list::before, .alphabet-list::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .alphabet-list::after {
    clear: both; }
  .alphabet-list li {
    background-color: #0071b9;
    border: 2px solid #0071b9;
    border-radius: 18px;
    color: #fefefe;
    float: left;
    height: 36px;
    list-style: none;
    margin-bottom: 8px;
    margin-right: 7px;
    text-align: center;
    width: 36px; }
    .alphabet-list li.is-active {
      background-color: #e36018;
      border: 2px solid #e36018; }
      .alphabet-list li.is-active:hover {
        background-color: #e36018;
        border: 2px solid #e36018;
        color: #fefefe; }
    .alphabet-list li a {
      color: inherit;
      display: block;
      line-height: 32px; }
    .alphabet-list li:hover {
      background: transparent;
      color: #0071b9; }
    .alphabet-list li:last-child {
      font-size: 13px; }
  .alphabet-list.alphabet-list--wide li {
    border-radius: 50%;
    height: 48px;
    margin-bottom: 18px;
    margin-right: 18px;
    width: 48px; }
    .alphabet-list.alphabet-list--wide li a {
      line-height: 44px; }
    @media screen and (max-width: 53.1875em) {
      .alphabet-list.alphabet-list--wide li {
        border-radius: 18px;
        height: 36px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: 36px; }
        .alphabet-list.alphabet-list--wide li a {
          line-height: 32px; } }
  .alphabet-list.alphabet-list--mid li {
    border-radius: 50%;
    height: 44px;
    margin-bottom: 18px;
    margin-right: 14px;
    width: 44px; }
    .alphabet-list.alphabet-list--mid li a {
      line-height: 40px; }
    @media screen and (max-width: 53.1875em) {
      .alphabet-list.alphabet-list--mid li {
        border-radius: 18px;
        height: 36px;
        margin-bottom: 8px;
        margin-right: 8px;
        width: 36px; }
        .alphabet-list.alphabet-list--mid li a {
          line-height: 32px; } }

.article__subheader {
  color: #c6a16d;
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: -1rem; }

.background--blue {
  background-color: #0071b9; }

.background--dark-blue {
  background-color: #0f2c52; }

.background--offwhite {
  background-color: #f5f5f5; }

.background--white {
  background-color: #fefefe; }

.background--light-gray {
  background-color: #f2f0ef; }

.background--gray {
  background-color: #ece9e8; }

.background--green-blue {
  background-color: #4dbeb5; }

.background--orange {
  background-color: #e36018; }

.background-padding {
  padding-bottom: 60px;
  padding-top: 60px; }
  @media screen and (max-width: 53.1875em) {
    .background-padding {
      padding-bottom: 30px;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 30px; } }

.back-to-top {
  color: #242424;
  display: block;
  font-weight: 600;
  text-align: right; }
  .back-to-top .icon {
    color: #0071b9; }
  .back-to-top:hover .icon {
    color: #e36018; }

.border--green {
  border-top: 8px solid #4dbeb5; }

.border-brown-bottom {
  border-bottom: 8px solid #c6a16d;
  padding-bottom: 1rem; }
  .border-brown-bottom.border-no-padding {
    padding-bottom: 0; }

.border-green-bottom {
  border-bottom: 8px solid #4dbeb5;
  padding-bottom: 1rem; }

.border-top {
  border-top: 1px solid #ccc;
  padding-top: 1.5rem; }

.border-bottom {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1.5rem; }

.box {
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2); }
  .box.box--padding {
    padding: 2rem; }
    @media screen and (max-width: 53.1875em) {
      .box.box--padding {
        padding: 1rem; } }
  .box.box--no-shadow {
    box-shadow: 0 0 0 0; }
  .box.box--small {
    max-width: 336px; }
  .box.box--margin-left {
    margin-left: 1rem; }
    @media screen and (max-width: 53.1875em) {
      .box.box--margin-left {
        margin-left: 0; } }
  .box.box--padding-bottom {
    padding-bottom: 2rem; }
  .box.box--center-small {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px; }

.box--small-text p {
  font-size: 0.9375rem;
  line-height: 1.9; }

.box--border-top {
  border-top: 8px solid #c6a16d; }

@media screen and (max-width: 53.1875em) {
  .box--hidden-small {
    box-shadow: 0 0 0 0 !important;
    border-radius: 0 !important; } }

/* YAPL
name: Button
partial: sg-button
selector: .button
*/
/* YAPL
name: Button Hollow
notes: This has a white border and text so should be used on a color background
partial: sg-button-hollow
selector: .button.button--hollow
*/
/* YAPL
name: Button Hollow Light Brown
partial: sg-button-hollow--light-brown
selector: .button.button--hollow--light-brown
*/
/* YAPL
name: Button Hollow Blue
partial: sg-button-hollow--blue
selector: .button.button--hollow--blue.white
*/
/* YAPL
name: Button Microsite
partial: sg-button-microsite
selector: .button.button--microsite
*/
/* YAPL
name: Button Rounded
partial: sg-button-rounded
selector: .button.button--rounded
*/
/* YAPL
name: Button Icon
partial: sg-button-icon
selector: .button.button--icon
*/
.button {
  border: 2px solid #0071b9;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 1em 1.5em;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s; }
  .button .icon {
    font-size: .75em; }
  .button:hover {
    background-color: transparent;
    border: 2px solid #0071b9;
    color: #0071b9; }
  .button.button--hollow {
    background-color: transparent;
    border: 2px solid #fefefe;
    color: #fefefe;
    display: inline-block;
    position: relative;
    z-index: 1; }
    .button.button--hollow:hover {
      background-color: #fefefe;
      color: #e36018; }
  .button.button--hollow--light-brown {
    background-color: transparent;
    border-color: #c69f6d;
    color: #aa8a5d; }
    .button.button--hollow--light-brown:hover {
      background-color: #c6a16d;
      color: #fefefe; }
  .button.button--hollow--blue {
    border-color: #0071b9;
    color: #0071b9; }
    .button.button--hollow--blue:hover {
      background-color: #0071b9;
      color: #fefefe; }
  .button.button--icon {
    align-items: center;
    display: flex;
    max-width: 240px;
    padding: .75rem;
    text-align: left;
    text-transform: none; }
    .button.button--icon .icon {
      flex-shrink: 0;
      font-size: 1em;
      margin-left: 0;
      margin-right: .5rem; }
    .button.button--icon:hover .icon {
      color: #fefefe; }
    @media screen and (max-width: 39.9375em) {
      .button.button--icon {
        padding: .5rem; }
        .button.button--icon .icon {
          margin-right: .25rem; } }
  .button.button--rounded {
    border-radius: 27px; }
  .button.button--microsite {
    background-color: transparent;
    border-radius: 24px;
    color: #0071b9; }
    .button.button--microsite:hover {
      background-color: #0071b9;
      color: #fefefe; }
  .button.button--no-margin {
    margin: 0; }

.button__container {
  align-items: center;
  display: flex;
  margin-bottom: 1rem; }
  .button__container a,
  .button__container button {
    margin-right: 1rem; }
  @media screen and (max-width: 53.1875em) {
    .button__container {
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start; } }

.careers-info {
  display: flex;
  justify-content: space-between;
  padding: 2rem; }
  @media screen and (max-width: 53.1875em) {
    .careers-info {
      flex-direction: column; } }

.careers-results {
  border-bottom: 1px solid #c6a16d; }
  .careers-results table {
    margin-top: 1rem;
    min-width: 600px; }
    .careers-results table thead {
      background-color: transparent;
      border-bottom: 1px solid #c6a16d;
      border-top: 1px solid #c6a16d;
      color: #976f3f; }
    .careers-results table tbody tr {
      background-color: transparent !important;
      border-bottom: 1px solid #b7b7b7 !important; }
    .careers-results table tbody td {
      padding-bottom: .9rem;
      padding-top: .9rem; }
    .careers-results table tbody h6 {
      margin-bottom: 0; }
    .careers-results table tbody p {
      line-height: 1.2;
      margin: 0; }

.chat {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 10; }
  @media screen and (max-width: 63.9375em) {
    .chat {
      display: none; } }

.dark-blue {
  color: #0f2c52 !important; }

.medium-blue {
  color: #0071b9 !important; }

.green-blue {
  color: #4dbeb5 !important; }

.light-brown {
  color: #c6a16d !important; }

.medium-light-gray {
  color: #b0b0b0 !important; }

.dark-gray {
  color: #242424 !important; }

.white {
  color: #fefefe !important; }

.contact {
  background-color: #0071b9;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: #fefefe;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.0625rem;
  font-weight: 700;
  height: 51px;
  line-height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  transition: height 0.3s;
  width: 239px; }
  .contact:hover {
    color: #fefefe; }
  @media screen and (min-width: 64em) {
    .contact {
      pointer-events: none; } }
  @media screen and (max-width: 53.1875em) {
    .footer-main .contact {
      left: calc(50% - 120px);
      right: auto; } }
  .is-stuck .contact {
    height: 0; }
    .is-expanded .is-stuck .contact {
      height: 51px; }
  .nav-main .contact {
    background-color: #0071b9;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    color: #fefefe;
    height: 51px;
    line-height: 51px;
    margin-bottom: 0;
    margin-top: 30px;
    padding: 0 !important;
    position: relative;
    width: 239px; }
    .nav-main .contact .icon {
      display: inline; }
  .sublocation .contact {
    background-color: #4dbeb5; }

@media screen and (min-width: 53.25em) {
  .css-columns-2 {
    column-count: 2;
    column-gap: 2rem;
    column-width: 50%; } }

/* YAPL
name: CTA Primary
partial: sg-cta
selector: .cta.cta--primary
*/
/* YAPL
name: CTA Secondary
partial: sg-cta-secondary
selector: .cta.cta--secondary
*/
/* YAPL
name: CTA Primary Microsite
partial: sg-cta-microsite
selector: .cta.cta--primary.cta--microsite
*/
/* YAPL
name: CTA Secondary Microsite
partial: sg-cta-secondary-microsite
selector: .cta.cta--secondary.cta--microsite
*/
.cta {
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2);
  display: block;
  padding: 26px 44px; }
  .cta .cta__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 0; }
    @media screen and (max-width: 53.1875em) {
      .cta .cta__title {
        font-size: 1.375rem; } }
  .cta .cta__rtf {
    font-size: 0.9375rem; }
  .cta .cta__link {
    color: #fefefe;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase; }
    .cta .cta__link .icon {
      border-radius: 12px;
      font-size: 10px;
      height: 24px;
      margin-right: 8px;
      position: relative;
      width: 24px; }
      .cta .cta__link .icon::before {
        left: 8px;
        position: absolute;
        top: 7px; }
  .cta.cta--microsite {
    border-radius: 30px; }

.cta--primary {
  background-color: #4dbeb5;
  transition: background-color 0.3s; }
  .cta--primary .cta__title {
    color: #fefefe; }
  .cta--primary .cta__rtf {
    color: #fefefe; }
  .cta--primary .icon {
    background-color: #0071b9;
    color: #fefefe;
    transition: background-color 0.3s, color 0.3s; }
  .cta--primary:hover {
    background-color: #0071b9; }
    .cta--primary:hover .icon {
      background-color: #fefefe;
      color: #0071b9; }

.cta--secondary {
  background-color: #fefefe; }
  .cta--secondary .cta__title {
    color: #e36018; }
  .cta--secondary .cta__rtf {
    color: #2d2c2c; }
  .cta--secondary .cta__link {
    color: #2d2c2c; }
  .cta--secondary .icon {
    background-color: #e36018;
    color: #fefefe; }
  .cta--secondary:hover .cta__link {
    color: #e36018; }

.cta-grid {
  display: flex;
  flex-flow: row wrap; }
  .cta-grid > .cta {
    width: calc(50% - 1rem);
    margin-right: 0.5rem;
    margin-left: 0.5rem; }

.cta-grid > .cta-grid-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  width: calc(50% - 3rem); }
  @media screen and (max-width: 53.1875em) {
    .cta-grid > .cta-grid-item {
      margin: 2rem 0 0;
      width: 100%; }
      .cta-grid > .cta-grid-item:first-child {
        margin-top: 0.5rem; } }

.diagonals {
  background-image: url("../img/diagonals.png");
  background-repeat: repeat;
  height: 100%;
  left: 0;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1; }
  .diagonals.is-active {
    opacity: .5; }
  .diagonals.diagonals--blue {
    background-color: rgba(15, 44, 82, 0.75); }
  .diagonals.diagonals--transparent {
    opacity: .5;
    transition: opacity 0.3s; }
    .hero .diagonals.diagonals--transparent {
      opacity: 0; }
      .hero .diagonals.diagonals--transparent.is-animating {
        opacity: .5; }
  .diagonals.diagonals--very-transparent {
    opacity: .2; }
  .hero .diagonals {
    z-index: -1; }

.diagonals--darken {
  background-color: #0a0a0a;
  background-repeat: repeat;
  height: 100%;
  left: 0;
  opacity: .35;
  position: absolute;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1; }
  .hero .diagonals--darken {
    z-index: -1; }

.divider {
  background-color: #ccc;
  height: 100%;
  left: 50%;
  position: relative;
  text-align: center;
  width: 1px; }
  .divider span {
    display: block;
    height: 50px;
    left: 100%;
    line-height: 50px;
    position: absolute;
    top: calc(50% - 25px);
    transform: translateX(-50px);
    width: 100px; }
  @media screen and (max-width: 53.1875em) {
    .divider {
      height: 1px;
      left: 0;
      margin-bottom: 1rem;
      margin-top: 1rem;
      width: 100%; }
      .divider span {
        left: 50%;
        top: -25px;
        transform: translateX(-25px);
        width: 50px; } }
  .divider.divider--horizontal {
    height: 1px;
    left: 0;
    margin-bottom: 3rem;
    margin-top: 3rem;
    width: 100%; }
    @media screen and (max-width: 53.1875em) {
      .divider.divider--horizontal {
        margin-bottom: 1rem;
        margin-top: 1rem; } }
  .divider.divider--small-margin {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem; }

/* YAPL
name: Doc Profile
partial: sg-doc-profile
selector: .doc-profile
*/
/* YAPL
name: Doc Profile Result
partial: sg-doc-profile-result
selector: .doc-profile.doc-profile-result
*/
/* YAPL
name: Doc Profile Small
partial: sg-doc-profile-small
selector: .doc__item
*/
/* YAPL
name: Doc Profile Small - 3 across
partial: sg-doc-profile-small-3
selector: .doc__item
wrapper: .doc__row.doc__row-3
*/
.doc-profile::before, .doc-profile::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.doc-profile::after {
  clear: both; }

@media screen and (max-width: 63.9375em) {
  .doc-profile {
    padding-bottom: .5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: .5rem !important; }
    .doc-profile.background--gray {
      padding: 1rem !important; } }

.doc-profile__image {
  float: left;
  margin-bottom: 1rem;
  margin-right: 24px;
  max-width: 150px; }

.doc-profile__image-link:hover {
  color: #e36018; }

.doc-profile__body {
  float: left;
  width: calc(100% - 174px); }
  @media screen and (max-width: 39.9375em) {
    .doc-profile__body {
      float: none;
      width: 100%; } }

.doc-profile__col {
  float: left;
  margin-right: 1%;
  width: 49%; }
  @media screen and (max-width: 53.1875em) {
    .doc-profile__col {
      float: none;
      width: 100%; } }
  .doc-profile__col.doc-profile__col--small {
    margin-right: 1%;
    width: 30%; }
    @media screen and (max-width: 63.9375em) {
      .doc-profile__col.doc-profile__col--small {
        width: 49%; } }
    @media screen and (max-width: 39.9375em) {
      .doc-profile__col.doc-profile__col--small {
        margin-right: 0;
        width: 100%; } }
  .doc-profile__col.doc-profile__col--badges {
    width: 37%; }
    @media screen and (max-width: 63.9375em) {
      .doc-profile__col.doc-profile__col--badges {
        margin-top: 1rem;
        width: 100%; } }

.doc-profile__badge {
  float: left;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem; }

.doc-profile__toggle {
  display: block;
  position: relative; }
  @media screen and (min-width: 64em) {
    .doc-profile__toggle {
      pointer-events: none; }
      .doc-profile__toggle .icon {
        display: none; } }
  .doc-profile__toggle .icon {
    font-size: 0.875rem;
    position: absolute;
    right: 0;
    top: 2px; }
    .expanded .doc-profile__toggle .icon::before {
      content: 'd' !important; }

@media screen and (min-width: 53.25em) {
  .doc-profile-result {
    padding: 2rem 2rem 1rem !important; } }

@media screen and (max-width: 63.9375em) {
  .doc-profile__filter-inner {
    display: none; }
    .expanded .doc-profile__filter-inner {
      display: block; } }

.doc-profile__refine-col {
  background-color: #f5f5f5;
  height: 100%; }

.doc__item {
  display: flex;
  float: left;
  margin-right: 1rem;
  width: calc(50% - 1rem); }
  .doc__item .doc-profile__image {
    margin-right: 12px; }
  @media screen and (max-width: 63.9375em) {
    .doc__item {
      float: none;
      width: 100%; } }
  .doc__row-3 .doc__item {
    margin-bottom: 2rem;
    width: calc(33% - 1rem); }
    .doc__row-3 .doc__item:nth-child(3n + 1) {
      clear: both; }
    @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
      .doc__row-3 .doc__item {
        float: left;
        width: calc(50% - 1rem); }
        .doc__row-3 .doc__item:nth-child(3n + 1) {
          clear: none; }
        .doc__row-3 .doc__item:nth-child(2n + 1) {
          clear: both; } }
    @media screen and (max-width: 53.1875em) {
      .doc__row-3 .doc__item {
        float: none;
        width: 100%; } }

.embed-container {
  height: 0;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

/* YAPL
name: Featured Stories
partial: hp-featured-stories
selector: .featured-stories
*/
.featured-stories {
  height: 100%; }

.featured-stories__body {
  padding: 1rem 2rem; }
  @media screen and (max-width: 53.1875em) {
    .featured-stories__body {
      padding: 1rem 1.5rem; } }

.featured-stories__main {
  background-color: #f5f5f5;
  border-bottom-left-radius: 60px;
  margin-top: 2rem; }
  .featured-stories__main img {
    width: 100%; }
  .featured-stories__main h5 {
    font-size: 1.25rem;
    font-weight: 700; }
    .featured-stories__main h5 a:hover {
      color: #e36018; }
  @media screen and (max-width: 53.1875em) {
    .featured-stories__main {
      margin-top: 1rem; } }

.featured-stories__side {
  margin-top: 2rem; }
  @media screen and (max-width: 53.1875em) {
    .featured-stories__side {
      padding-left: 2rem;
      padding-right: 2rem; } }

.featured-stories__item {
  border-top: 8px solid #c6a16d;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
  padding-top: 1rem; }
  @media screen and (max-width: 53.1875em) {
    .featured-stories__item {
      font-size: 1rem;
      margin-bottom: 2rem; } }
  .featured-stories__item a:hover {
    color: #e36018; }

.featured-stories__title {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase; }
  @media screen and (max-width: 53.1875em) {
    .featured-stories__title {
      font-size: 1.5625rem; } }

/* YAPL
name: Figure
notes: This can be floated either way with the addition or removal of .float-right and .float-left
partial: sg-figure
selector: .figure
*/
/* YAPL
name: Figure with Video
partial: sg-figure-video
selector: .figure.figure__video
*/
/* YAPL
name: Figure with Diagonals
notes: The diagonals div can be removed if needed. If so you can remove .image--overflow-hidden and .image--max-diagonals from the containing div
partial: sg-figure-small
selector: .figure.image--overflow-hidden.image--max-diagonals
*/
/* YAPL
name: Figure Right with Border
notes: Add .figure--no-border if you do not want the border
partial: sg-figure-right-border
selector: .figure.float-right.figure__image
*/
/* YAPL
name: Figure Max Width
notes: Max width is set in this file; this can be floated left or right with .float-left or .float-right on the containing div
partial: sg-figure-max-width
selector: .figure.figure--max-width
*/
.figure {
  margin-left: auto;
  margin-right: auto; }
  .figure.float-left {
    float: left;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 2rem;
    max-width: 100%;
    width: 45rem; }
  .figure.float-right {
    float: right;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 0;
    max-width: 100%;
    width: 45rem; }
  @media screen and (max-width: 74.9375em) {
    .medium-8 .figure.float-left {
      margin-left: 0; } }
  @media screen and (max-width: 63.9375em) {
    .figure.float-left {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%; }
    .figure.float-right {
      float: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%; } }

.figure__video {
  position: relative; }
  .figure__video img {
    border-bottom: 0; }
  .figure__video::before {
    background-color: #e36018;
    border-radius: 30px;
    content: '';
    height: 60px;
    left: calc(50% - 30px);
    pointer-events: none;
    position: absolute;
    top: calc(50% - 30px);
    transition: opacity 0.3s;
    width: 60px; }
  @media screen and (max-width: 63.9375em) {
    .figure__video.float-left, .figure__video.float-right {
      float: none;
      margin-left: auto !important;
      margin-right: auto !important; } }
  .figure__video .icon--play {
    font-size: 1.25rem;
    padding-left: 6px;
    padding-top: 7px;
    pointer-events: none; }
  @media screen and (min-width: 64em) {
    .figure__video.figure__video--max-width {
      max-width: 66%; } }

.figure__image {
  display: block;
  width: 100%; }
  .figure__image img {
    border-bottom: 8px solid #c6a16d; }
  .figure__image.figure--max-width {
    max-width: 348px; }
  .figure__image.figure--no-border {
    border: 0; }
  .figure__image.figure--flexible {
    width: auto; }

.figure__caption {
  background: #f2f0ef;
  border-bottom-left-radius: 30px;
  color: #242424;
  font-size: 0.875rem;
  padding: 22px 1rem; }
  .figure__caption p {
    margin-bottom: 0; }
  .figure__caption.figure__caption--white {
    background-color: #fefefe; }

.filter {
  align-items: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
  padding: 1rem; }
  .filter p {
    margin-bottom: 0; }

/* YAPL
name: Follow
partial: sg-follow
selector: .follow__icons
*/
/* YAPL
name: Follow - Footer
notes: .footer__social wrapping class makes icon background color blue
partial: sg-follow-footer
selector: .follow__icons
*/
/* YAPL
name: Follow - Share
notes: Share functionality javascript is in src/assets/js/share.js
partial: sg-follow-share
selector: .follow__icons
*/
.follow {
  padding: 0 2rem 1rem; }

.follow__title {
  background-color: #0f2c52;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fefefe;
  font-size: 1.1875rem;
  font-weight: 700;
  padding: 1rem;
  text-align: center; }

.follow__icons {
  margin: 1rem 0; }
  .follow__icons .icon {
    color: #fefefe;
    left: 8px;
    margin-right: 0;
    position: absolute;
    top: 2px; }

.follow__icon {
  background-color: #4dbeb5;
  border-radius: 18px;
  display: flex;
  font-size: 1.2em;
  height: 36px;
  justify-content: center;
  line-height: 36px;
  margin: 0 6px 1rem;
  position: relative;
  transition: background-color 0.3s;
  width: 36px; }
  .footer__social .follow__icon {
    background-color: #0071b9; }

.follow__icon--facebook {
  background-color: #47639c; }
  .follow__icon--facebook:hover {
    background-color: #2b59b8; }

.follow__icon--twitter {
  background-color: #2aa3ef; }
  .follow__icon--twitter:hover {
    background-color: #1aa7ff; }

.follow__icon--instagram {
  background-color: #d2327e; }
  .follow__icon--instagram:hover {
    background-color: #f1137c; }

.follow__icon--youtube {
  background-color: #dc301c; }
  .follow__icon--youtube:hover {
    background-color: #f81a00; }

.follow__icon--linkedin {
  background-color: #1178b3; }
  .follow__icon--linkedin:hover {
    background-color: #007dc4; }

.follow__facebook {
  align-items: center;
  display: flex;
  justify-content: center; }

.fb-page,
.fb-page span,
.fb-page span iframe[style] {
  width: 100% !important; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.78846rem;
  margin: 0 0 1rem;
  padding: 0.76923rem;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #b7b7b7;
    background-color: #fefefe;
    box-shadow: 0 0 5px #b7b7b7;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #454545; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e1e1e1;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8;
  color: #242424; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 6px 0 0 6px; }
  .input-group > :last-child > * {
    border-radius: 0 6px 6px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #2d2c2c;
  background: #e1e1e1;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.875rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #b7b7b7; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.78846rem;
  margin: 0 0 1rem;
  padding: 0.76923rem;
  appearance: none;
  border: 1px solid #b7b7b7;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2847, 46, 46%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #b7b7b7;
    background-color: #fefefe;
    box-shadow: 0 0 5px #b7b7b7;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e1e1e1;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

select,
input,
textarea {
  margin: .25rem 0; }

label.full-width,
legend.full-width {
  width: 100%; }

label.required,
legend.required {
  position: relative; }
  label.required .label-text::before,
  legend.required .label-text::before {
    color: #f42020;
    content: '*';
    display: inline-block;
    font-weight: 700;
    margin-right: .25rem; }

legend {
  font-weight: 600;
  line-height: 1.8; }

fieldset.radio-input label {
  font-weight: 400; }

.select-wrap {
  background: linear-gradient(#fffeff, #e4e4e4);
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  height: 44px;
  margin-bottom: 16px;
  position: relative; }
  .select-wrap select {
    background: transparent;
    border: 0;
    box-shadow: 0 0 0 0;
    margin: 0; }
  .select-wrap .icon {
    background-color: #0071b9;
    border-radius: 4px;
    color: #fefefe;
    font-size: 1rem;
    height: 32px;
    pointer-events: none;
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px; }
    .select-wrap .icon::before {
      left: 8px;
      position: relative;
      top: 3px; }

.checkbox-wrap {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-left: 35px;
  position: relative;
  user-select: none; }
  .checkbox-wrap input:checked ~ .checkbox .checkbox__check {
    display: block; }
  label .checkbox-wrap {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 400; }

input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute; }

input[type="radio"] {
  height: 1.25rem;
  margin: 0;
  width: 1.25rem; }

.checkbox {
  background: linear-gradient(#fffeff, #e4e4e4);
  border: 1px solid #b7b7b7;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  margin-top: -12px;
  top: 50%;
  width: 24px; }
  .checkbox .checkbox__check {
    color: #0071b9;
    display: none;
    font-size: 18px;
    left: 3px;
    position: absolute;
    top: -2px; }

.form__required {
  bottom: -21px;
  color: #f42020;
  font-size: 0.8125rem;
  position: absolute; }

.future {
  position: relative; }

.future__header {
  align-items: center;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 1rem;
  overflow: hidden;
  padding: 0 100px;
  position: relative;
  text-align: center; }
  @media screen and (min-width: 53.25em) {
    .future__header {
      height: 300px; } }
  @media screen and (max-width: 53.1875em) {
    .future__header {
      background-position: 100% 0;
      padding: 2rem 1rem; } }

.future__header-text {
  position: relative;
  z-index: 2; }
  .future__header-text h2 {
    color: #fefefe;
    font-size: 2.625rem;
    font-weight: 700; }
  .future__header-text p {
    font-size: 1.1875rem; }
  @media screen and (max-width: 53.1875em) {
    .future__header-text h2 {
      font-size: 2rem; }
    .future__header-text p {
      font-size: 0.875rem; } }

.future__item {
  margin-bottom: 1rem;
  opacity: 0;
  position: relative;
  transform: scale(0);
  transform-origin: 50% 50% 0;
  transition: transform .5s, opacity .5s; }
  .is-animating .future__item {
    opacity: 1;
    transform: scale(1); }
  @media screen and (max-width: 53.1875em) {
    .future__item {
      margin-left: auto;
      margin-right: auto;
      max-width: 300px;
      opacity: 1;
      text-align: center;
      transform: scale(1); } }
  .future__item.future__item--video {
    cursor: pointer; }
    .future__item.future__item--video::before {
      background-color: #e36018;
      border-radius: 25px;
      content: '';
      height: 50px;
      left: calc(50% - 25px);
      position: absolute;
      top: calc(50% - 25px);
      transition: opacity 0.3s;
      width: 50px; }
      @media screen and (max-width: 53.1875em) {
        .future__item.future__item--video::before {
          display: none; } }
    .future__item.future__item--video:hover::before {
      opacity: 0; }
    .future__item.future__item--video:hover .icon {
      opacity: 0; }
    .future__item.future__item--video .icon {
      color: #fefefe;
      left: calc(50% - 7px);
      position: absolute;
      top: calc(50% - 10px);
      transition: opacity 0.3s;
      z-index: 4; }
      @media screen and (max-width: 53.1875em) {
        .future__item.future__item--video .icon {
          display: none; } }
  .future__item.future__item--story {
    cursor: pointer;
    overflow: hidden; }
    .future__item.future__item--story img {
      transition: transform 0.3s; }
    .future__item.future__item--story .icon--future-story {
      background-image: url("../img/icons/icon-patient_story.svg");
      background-repeat: no-repeat;
      height: 48px;
      margin-bottom: 1rem;
      width: 56px; }
    .future__item.future__item--story .icon--arrow-right {
      position: relative; }
      @media screen and (min-width: 64em) {
        .future__item.future__item--story .icon--arrow-right {
          top: 80px;
          transition: 0.3s; } }
    .future__item.future__item--story .icon--news,
    .future__item.future__item--story .icon--patient-story {
      border-radius: 28px;
      height: 56px;
      margin-bottom: 1rem;
      position: relative;
      width: 56px; }
      .future__item.future__item--story .icon--news::before,
      .future__item.future__item--story .icon--patient-story::before {
        left: 15px;
        position: absolute;
        top: 15px; }
    .future__item.future__item--story .icon--patient-story::before {
      left: 17px; }
    @media screen and (min-width: 64em) {
      .future__item.future__item--story:hover img {
        transform: scale(1.2); }
      .future__item.future__item--story:hover .icon--arrow-right {
        transform: translateY(-80px); } }
  .future__item:hover .future__hidden-overlay {
    opacity: 1; }
    .future__item:hover .future__hidden-overlay .icon {
      opacity: 1; }

.future__video {
  display: none;
  max-width: 100%;
  position: absolute;
  width: 100%;
  z-index: 2; }
  .future__video.is-visible {
    display: block; }
  .future__video .close-button {
    background-color: rgba(10, 10, 10, 0.5);
    border-radius: 28px;
    color: #e36018;
    font-size: 44px;
    height: 56px;
    right: 20px;
    top: 40px;
    width: 56px; }

.future__hidden-overlay {
  align-items: center;
  background-color: rgba(15, 44, 82, 0.8);
  bottom: 0;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  padding: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  transition: opacity .5s;
  width: 100%; }
  @media screen and (max-width: 53.1875em) {
    .future__hidden-overlay {
      opacity: 1; }
      .future__hidden-overlay .icon {
        display: block !important; } }

.future__grid .grid-container {
  padding-left: 0;
  padding-right: 0; }

.future__grid .grid-margin-x > .cell {
  margin-left: .5rem;
  margin-right: .5rem; }

.future__grid .grid-margin-x > .medium-3 {
  width: calc(25% - 1rem); }

@media screen and (max-width: 39.9375em) {
  .future__grid .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); } }

.hamburger {
  cursor: pointer;
  display: block; }

.hamburger__line {
  stroke: #0071b9;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  transition: stroke .25s, transform .25s;
  will-change: stroke, background; }
  .hamburger__line:nth-child(1) {
    transform-origin: top right; }
  .hamburger__line:nth-child(3) {
    transform-origin: bottom right; }
  .is-active .hamburger__line:nth-child(1),
  .is-menu-open .hamburger__line:nth-child(1) {
    transform: rotate(-45deg); }
  .is-active .hamburger__line:nth-child(2),
  .is-menu-open .hamburger__line:nth-child(2) {
    stroke: transparent; }
  .is-active .hamburger__line:nth-child(3),
  .is-menu-open .hamburger__line:nth-child(3) {
    transform: rotate(45deg); }
  .orange .hamburger__line {
    stroke: #e36018; }

/* YAPL
name: Image Border
partial: sg-image-border
selector: .image--border
*/
/* YAPL
name: Image Portrait
partial: sg-image-portrait
selector: .image--portrait
*/
/* YAPL
name: Image Portrait Small
partial: sg-image-portrait-small
selector: .image--portrait--small
*/
/* YAPL
name: Image Max Width and Diagonals
notes: Same as example from figure component
partial: sg-image-max-diagonals
selector: .image--max-diagonals
*/
/* YAPL
name: Image Fit
notes: For use in cases where the image needs to fill the container; can be used in conjunction with image--max-540 to limit height; you could create new max classes for different situations
partial: sg-image-fit
selector: .image--fit
*/
/* YAPL
name: Image Max Width 540
notes: Used to limit max height of image
partial: sg-image-max-540
selector: .image--max-540
*/
.image--border {
  border-bottom: 8px solid #c6a16d; }

.image--float-left {
  float: left;
  margin-bottom: 1rem;
  margin-right: 1rem; }

.image--portrait {
  max-width: 150px; }

.image--portrait--small {
  max-width: 114px; }

.image--max-diagonals {
  max-width: 348px !important; }

.image--overflow-hidden {
  overflow: hidden;
  position: relative; }
  .image--overflow-hidden.image--no-hover:hover > img {
    transform: scale(1); }
  .image--overflow-hidden img {
    transition: transform 0.3s; }
  .image--overflow-hidden:hover + div > span {
    color: #0071b9; }
  .image--overflow-hidden:hover > img {
    transform: scale(1.1); }

.image--fill img {
  width: 100%; }

.image--fit {
  font-family: 'object-fit: cover; object-position: top';
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%; }

.image--max-540 {
  max-height: 540px; }

/* YAPL
name: Hero
notes: parallax scroll disabled in styleguide; z-index changes for styleGuide at bottom (look for .sg-block at bottom of this file)
partial: sg-hero
context: hero.homepage
selector: .hero
*/
/* YAPL
name: Hero Mastersub
notes: parallax scroll disabled in styleguide
partial: sg-hero
context: hero.mastersub
selector: .hero--sub
*/
/* YAPL
name: Hero Mastersub Message
notes: parallax scroll disabled in styleguide
partial: sg-hero
context: hero.locationDetail
selector: .hero--sub.hero--sub--message
*/
/* YAPL
name: Hero Campaign
notes: z-index changes and removed negative top margin for styleguide
partial: sg-hero
context: hero.campaign
selector: .hero--campaign
*/
/* YAPL
name: Hero Microsite
notes: z-index changes and removed negative top margin for styleguide
partial: sg-hero
context: hero.microsite
selector: .hero--campaign.hero--microsite
*/
.hero {
  display: flex;
  flex-flow: column nowrap;
  min-height: 15.625rem;
  position: relative; }
  @media screen and (min-width: 64em) {
    .hero {
      height: 550px; } }
  @media screen and (max-width: 53.1875em) {
    .hero {
      height: 178px;
      min-height: auto;
      padding: 0; } }
  @media screen and (min-width: 64em) {
    .hero--sub .hero {
      height: 336px; } }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero--sub .hero {
      height: 240px;
      min-height: 240px; } }
  @media screen and (max-width: 53.1875em) {
    .hero--sub .hero {
      height: auto;
      min-height: auto;
      padding: 0; } }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero--sub--message .hero {
      height: auto; } }
  @media screen and (min-width: 64em) {
    .hero--campaign .hero {
      height: 685px; } }
  @media screen and (max-width: 53.1875em) {
    .hero--campaign .hero {
      height: auto;
      min-height: auto;
      padding: 0; } }

.hero__wrap {
  position: relative; }
  @media screen and (min-width: 64em) {
    .hero__wrap {
      height: 550px; } }
  .hero__wrap.hero--sub {
    height: 336px; }
    @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
      .hero__wrap.hero--sub {
        height: 240px;
        min-height: 240px; }
        .hero__wrap.hero--sub.hero--sub--message {
          height: auto; } }
    @media screen and (max-width: 53.1875em) {
      .hero__wrap.hero--sub {
        height: auto;
        min-height: 130px; } }
  .hero__wrap.hero--campaign {
    height: 685px;
    margin-top: -118px; }
    @media screen and (max-width: 63.9375em) {
      .hero__wrap.hero--campaign {
        height: auto;
        min-height: 210px; } }
    .hero__wrap.hero--campaign.hero--microsite {
      margin-top: -157px; }

.hero__image {
  font-family: 'object-fit: cover; object-position: top';
  height: 550px;
  min-height: 550px;
  object-fit: cover;
  object-position: top;
  z-index: -2; }
  @media screen and (min-width: 64em) {
    .hero__image {
      opacity: 0;
      transition: opacity 1.5s; }
      .hero__image.is-animating {
        opacity: 1; } }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero__image {
      height: 400px;
      min-height: 400px; } }
  @media screen and (max-width: 53.1875em) {
    .hero__image {
      height: auto;
      min-height: 178px; } }
  .hero--sub .hero__image {
    height: 336px;
    min-height: 336px; }
    @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
      .hero--sub .hero__image {
        height: 240px;
        min-height: 240px; } }
    @media screen and (max-width: 53.1875em) {
      .hero--sub .hero__image {
        height: 130px;
        min-height: 130px; } }
  .hero--campaign .hero__image {
    height: 685px;
    min-height: 685px; }
    @media screen and (max-width: 53.1875em) {
      .hero--campaign .hero__image {
        height: 210px;
        min-height: 210px; } }

.hero__pos-state-wrap {
  position: relative;
  top: -85%;
  width: 100%;
  z-index: 5; }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero__pos-state-wrap {
      position: absolute;
      top: 30px; }
      .hero__pos-state-wrap.hero__pos-state-wrap--sub {
        position: relative; }
      .hero--sub--message .hero__pos-state-wrap {
        top: 0; } }
  @media screen and (max-width: 53.1875em) {
    .hero__pos-state-wrap {
      position: absolute;
      top: 0; }
      .hero__pos-state-wrap.hero__pos-state-wrap--sub {
        position: relative; } }
  .hero--campaign .hero__pos-state-wrap {
    top: -68%; }
    @media screen and (max-width: 63.9375em) {
      .hero--campaign .hero__pos-state-wrap {
        background-color: #fefefe;
        position: relative; } }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero--microsite .hero__pos-state-wrap,
    .hero--campaign .hero__pos-state-wrap {
      background-color: transparent;
      position: absolute;
      top: 220px; } }

.hero__pos-state {
  color: #0f2c52;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 2.8125rem;
  font-style: italic;
  font-weight: 500;
  height: 100%;
  line-height: 1.2;
  opacity: 1;
  position: relative;
  transition: margin-top 0.9s ease-in, opacity 0.9s ease-in;
  width: 45%;
  z-index: 3; }
  .hero__pos-state::after {
    background-color: #e36018;
    bottom: -27px;
    content: '';
    display: block;
    height: 10px;
    left: 0;
    position: relative;
    width: 142px; }
  @media screen and (min-width: 64em) {
    .hero__pos-state {
      opacity: 0;
      transition: opacity 2s; }
      .hero__pos-state.is-animating {
        opacity: 1; } }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .hero__pos-state {
      font-size: 2.5rem;
      margin-top: 24px !important; }
      .hero__pos-state::after {
        bottom: -18px;
        width: 100px; } }
  @media screen and (max-width: 53.1875em) {
    .hero__pos-state {
      font-size: 1.3125rem;
      left: 24px;
      margin-top: 12px !important;
      top: 16px;
      width: 60%; }
      .hero__pos-state::after {
        bottom: -10px;
        height: 6px;
        width: 64px; } }

.hero__textbox {
  background-color: rgba(77, 190, 181, 0.8);
  height: 100%;
  padding: 1.5rem 2rem;
  width: 40%; }
  .hero__textbox h2 {
    font-size: 37px;
    font-weight: 600; }
    .hero--campaign .hero__textbox h2 {
      color: #fefefe;
      font-size: 42px; }
      @media screen and (max-width: 53.1875em) {
        .hero--campaign .hero__textbox h2 {
          color: #0a0a0a;
          font-size: 28px; } }
  .hero__textbox p {
    font-weight: 600; }
    .hero--campaign .hero__textbox p {
      color: #fefefe;
      font-size: 20px; }
      @media screen and (max-width: 53.1875em) {
        .hero--campaign .hero__textbox p {
          color: #0a0a0a;
          font-size: 14px; } }
  .hero__textbox.hero__textbox--large-text h2 {
    font-size: 59px; }
    @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
      .hero__textbox.hero__textbox--large-text h2 {
        font-size: 42px; } }
    @media screen and (max-width: 53.1875em) {
      .hero__textbox.hero__textbox--large-text h2 {
        font-size: 31px; } }
  .hero__textbox.hero__textbox--large-text p {
    font-size: 24px; }
    @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
      .hero__textbox.hero__textbox--large-text p {
        font-size: 20px; } }
    @media screen and (max-width: 53.1875em) {
      .hero__textbox.hero__textbox--large-text p {
        font-size: 16px; } }
  @media screen and (max-width: 63.9375em) {
    .hero__textbox {
      background-color: #4dbeb5;
      border-bottom-left-radius: 20px;
      outline: 4px solid rgba(254, 254, 254, 0.4);
      position: relative;
      top: -26px;
      width: 100%; } }
  @media screen and (max-width: 39.9375em) {
    .hero__textbox {
      top: -26px; } }
  .hero__textbox.hero__textbox--transparent {
    background: transparent;
    width: 90%; }
    @media screen and (max-width: 63.9375em) {
      .hero__textbox.hero__textbox--transparent {
        outline: 0;
        width: 100%; } }
    @media screen and (max-width: 53.1875em) {
      .hero__textbox.hero__textbox--transparent {
        padding: 1rem;
        top: 0; } }
    .hero__textbox.hero__textbox--transparent .button:hover {
      background-color: #14679e;
      color: #fefefe; }
    @media screen and (max-width: 53.1875em) {
      .hero__textbox.hero__textbox--transparent h2,
      .hero__textbox.hero__textbox--transparent p {
        color: #242424 !important; } }
  .hero__textbox.hero__textbox--center {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }

.hero__textbox-border {
  background-color: #4dbeb5;
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 80px; }

.hero__directions-wrap {
  position: relative; }

.hero-directions {
  align-items: center;
  background-color: rgba(254, 254, 254, 0.7);
  bottom: 0;
  display: flex;
  padding: 1rem;
  position: absolute;
  right: 0;
  z-index: 5; }
  .hero-directions p {
    font-size: 0.9375rem;
    margin-bottom: 0; }
    @media screen and (max-width: 53.1875em) {
      .hero-directions p {
        font-size: 0.8125rem; } }
  .hero-directions a {
    font-size: 0.9375rem; }
    @media screen and (max-width: 53.1875em) {
      .hero-directions a {
        font-size: 0.8125rem; } }
  .hero-directions .icon {
    flex-shrink: 0;
    font-size: 1.75rem; }
    @media screen and (max-width: 63.9375em) {
      .hero-directions .icon {
        margin-left: .5rem;
        margin-right: .5rem; } }
  @media screen and (max-width: 63.9375em) {
    .hero-directions {
      margin-left: auto;
      margin-right: auto;
      max-width: 420px;
      position: relative;
      top: -18px;
      width: 100%; } }

.hero-directions__link {
  margin-left: 1rem; }
  @media screen and (max-width: 53.1875em) {
    .hero-directions__link {
      margin-left: 0.5rem; } }

.hero__overlay {
  margin-top: -150px;
  position: relative;
  z-index: 1; }
  .hero__overlay.hero__overlay--small {
    margin-top: -46px; }
  @media screen and (max-width: 63.9375em) {
    .hero__overlay.hero__overlay--microsite {
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (max-width: 53.1875em) {
    .hero__overlay {
      margin-top: 0; } }

.sg-block .hero__image {
  z-index: 0; }

.sg-block .hero .diagonals {
  opacity: .5;
  z-index: 2; }

.sg-block .hero .diagonals--darken {
  z-index: 1; }

.sg-block .hero-nav__container.hero-nav--campaign {
  z-index: 3; }

.sg-block .hero__wrap.hero--campaign {
  margin-top: 0; }

@media screen and (min-width: 64em) {
  .hero-nav__container {
    bottom: 154px;
    position: relative; } }

@media screen and (max-width: 63.9375em) {
  .hero-nav__container {
    background-color: #0f2c52;
    border-bottom-left-radius: 20px;
    bottom: auto;
    min-height: 156px;
    outline: 4px solid rgba(254, 254, 254, 0.4);
    padding: 1rem 0;
    position: relative;
    top: -36px;
    width: 92%; } }

.hero-nav__container.hero-nav--campaign {
  z-index: 1; }
  @media screen and (max-width: 63.9375em) {
    .hero-nav__container.hero-nav--campaign {
      background-color: #4dbeb5;
      margin-top: -80px;
      top: 0; } }
  @media screen and (max-width: 53.1875em) {
    .hero-nav__container.hero-nav--campaign {
      margin-top: 0px; } }

.hero-nav {
  align-items: center;
  background-color: rgba(254, 254, 254, 0.75);
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  transition: background-color 0.3s;
  width: 100%; }
  @media screen and (max-width: 63.9375em) {
    .hero-nav {
      background-color: #0f2c52;
      padding: 0 1rem; } }
  .hero-nav--campaign .hero-nav {
    background-color: rgba(62, 151, 144, 0.9); }
    @media screen and (max-width: 63.9375em) {
      .hero-nav--campaign .hero-nav {
        align-items: flex-start;
        background-color: #4dbeb5;
        flex-direction: column;
        justify-content: flex-start; } }

.hero-nav__section {
  align-items: center;
  border: 0;
  display: flex;
  height: 110px;
  justify-content: space-between; }
  .hero-nav__section:hover .hero-nav__link {
    color: #4dbeb5; }
  .hero-nav__section:hover .hero-nav__icon {
    background-color: #fefefe;
    border: 2px solid #4dbeb5;
    color: #4dbeb5; }
    .hero-nav__section:hover .hero-nav__icon::after {
      background: #4dbeb5;
      opacity: 0;
      transform: scale(1.4);
      transition: transform .3s, opacity .2s; }
  @media screen and (max-width: 63.9375em) {
    .hero-nav__section {
      background-color: #0f2c52;
      border: 0;
      color: #fefefe;
      height: auto;
      justify-content: flex-start;
      margin-bottom: 1rem;
      width: 25%; } }
  @media screen and (max-width: 53.1875em) {
    .hero-nav__section {
      width: 47%; } }
  @media screen and (max-width: 63.9375em) {
    .hero-nav--campaign .hero-nav__section {
      background-color: #4dbeb5;
      border-bottom: 1px solid rgba(254, 254, 254, 0.5);
      padding-bottom: 1rem;
      padding-top: 1rem; }
      .hero-nav--campaign .hero-nav__section:last-child {
        border-bottom: 0; } }
  .hero-nav--campaign .hero-nav__section p {
    color: #fefefe;
    line-height: 1.3;
    margin: 0; }
  .hero-nav--campaign .hero-nav__section a {
    color: #fefefe;
    padding: 0;
    text-decoration: underline; }
  .hero-nav__section.hero-nav__section--description {
    width: 40%; }
    @media screen and (max-width: 63.9375em) {
      .hero-nav__section.hero-nav__section--description {
        margin-bottom: 0;
        width: 100%; } }
  .hero-nav__section.hero-nav__section--info {
    padding-left: 10px;
    padding-right: 10px;
    width: 30%; }
    @media screen and (max-width: 63.9375em) {
      .hero-nav__section.hero-nav__section--info {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%; } }
  .hero-nav__section .hero-nav__link {
    color: #0f2c52;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.3em;
    padding-left: 8px;
    transition: color 0.3s; }
    @media screen and (max-width: 63.9375em) {
      .hero-nav__section .hero-nav__link {
        color: #fefefe;
        font-size: 0.875rem; } }
    @media screen and (max-width: 53.1875em) {
      .hero-nav__section .hero-nav__link {
        padding-left: 6px;
        padding-right: 12px; } }
    .hero-nav--campaign .hero-nav__section .hero-nav__link {
      color: #fefefe;
      font-size: 0.875rem; }
  .hero-nav__section .hero-nav__icon {
    background-color: #4dbeb5;
    border: 2px solid #4dbeb5;
    border-radius: 27px;
    color: #fefefe;
    display: block;
    flex-shrink: 0;
    float: left;
    height: 54px;
    position: relative;
    width: 54px;
    z-index: 1; }
    .hero-nav__section .hero-nav__icon::before {
      font-size: 1.8rem;
      left: 11px;
      position: absolute;
      top: 10px;
      z-index: 2; }
    .hero-nav__section .hero-nav__icon.icon--location::before {
      top: 11px; }
    .hero-nav__section .hero-nav__icon.icon--briefcase::before {
      top: 13px; }
    @media screen and (min-width: 64em) {
      .hero-nav__section .hero-nav__icon::after {
        background-color: #4dbeb5;
        border-radius: 50%;
        content: '';
        height: 54px;
        left: -2px;
        opacity: 1;
        pointer-events: none;
        position: absolute;
        top: -2px;
        transition: transform .3s, opacity .4s;
        width: 54px;
        z-index: 0; } }
    @media screen and (max-width: 63.9375em) {
      .hero-nav__section .hero-nav__icon {
        box-shadow: 0 0 0 0;
        height: 42px;
        width: 42px; }
        .hero-nav__section .hero-nav__icon::before {
          font-size: 1.4rem;
          left: 8px;
          position: absolute;
          top: 7px; }
        .hero-nav__section .hero-nav__icon.icon--location::before {
          top: 8px; }
        .hero-nav__section .hero-nav__icon.icon--briefcase::before {
          top: 9px; } }

@media screen and (min-width: 64em) {
  .hero-nav__section-container:hover .hero-nav {
    background-color: #fefefe; }
    .hero-nav--campaign .hero-nav__section-container:hover .hero-nav {
      background-color: #4dbeb5; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hero-nav__section-container {
    width: 100% !important; } }

@media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
  .hero-nav__section-container {
    flex-direction: column; } }

@media screen and (min-width: 40em) and (max-width: 53.1875em) {
  .hero-nav__section-container {
    flex-direction: row; } }

@media screen and (max-width: 39.9375em) {
  .hero-nav__section-container {
    flex-direction: column; } }

.hero-nav__section--looking {
  align-items: center;
  background-color: #0f2c52;
  border-bottom-left-radius: 20px;
  color: #fefefe;
  display: flex;
  font-size: 1.6875rem;
  font-weight: 700;
  height: 110px;
  justify-content: flex-end;
  margin-left: 0;
  padding-right: 31px;
  text-align: right;
  width: 240px; }
  .hero-nav__section--looking span {
    display: inline-block; }
    @media screen and (max-width: 63.9375em) {
      .hero-nav__section--looking span {
        display: inline; } }
  @media screen and (max-width: 63.9375em) {
    .hero-nav__section--looking {
      border-bottom-left-radius: 0;
      font-size: 1.375rem;
      font-weight: 700;
      height: auto;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 1rem;
      padding-right: 0;
      padding-top: 0;
      text-align: center;
      width: 100%; } }
  @media screen and (min-width: 40em) and (max-width: 53.1875em) {
    .hero-nav__section--looking {
      font-size: 20px;
      padding-right: 20px;
      text-align: right; } }
  .hero-nav--campaign .hero-nav__section--looking {
    background-color: #4dbeb5; }
    @media screen and (max-width: 63.9375em) {
      .hero-nav--campaign .hero-nav__section--looking {
        justify-content: flex-start;
        padding: 0 1rem; } }

@media screen and (min-width: 40em) and (max-width: 53.1875em) {
  .hero-nav__left {
    width: 25% !important; } }

@media screen and (min-width: 40em) and (max-width: 53.1875em) {
  .hero-nav__right {
    width: 75% !important; } }

.hot-jobs__item {
  border-bottom: 1px solid #b7b7b7;
  display: flex;
  padding: .75rem 0 1.25rem; }

.hot-jobs__job .hot-jobs__category {
  color: #c6a16d;
  font-weight: 400;
  margin-bottom: 0; }

.hot-jobs__job .hot-jobs__job-name {
  margin-bottom: 0;
  margin-top: .25rem; }

.hot-jobs__location {
  align-items: flex-end;
  display: flex;
  margin-left: auto;
  margin-right: 0; }

.hp-portlets .hp-portlets-cell {
  margin-bottom: 64px;
  transition: margin-top 1s; }
  .hp-portlets .hp-portlets-cell.is-animating {
    margin-top: -90px; }
  @media screen and (max-width: 53.1875em) {
    .hp-portlets .hp-portlets-cell {
      margin-bottom: 1rem; }
      .hp-portlets .hp-portlets-cell.is-animating {
        margin-top: 2rem; } }

.infographic {
  padding: 0;
  position: relative; }
  @media screen and (max-width: 63.9375em) {
    .infographic {
      width: 92%; } }
  @media screen and (max-width: 53.1875em) {
    .infographic {
      box-shadow: 0 0 0 0;
      margin-bottom: 3rem;
      width: 100%; } }

.infographic__tabs-container {
  height: 100%;
  width: 100%; }
  @media screen and (max-width: 53.1875em) {
    .infographic__tabs-container {
      box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2);
      height: 484px; } }

.infographic__nav {
  background: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 40px;
  position: absolute;
  top: 308px;
  z-index: 2; }
  @media screen and (max-width: 74.9375em) {
    .infographic__nav {
      top: 248px; } }
  @media screen and (max-width: 63.9375em) {
    .infographic__nav {
      align-items: center;
      bottom: -40px;
      left: auto;
      position: relative;
      top: auto;
      width: 100%; } }
  @media screen and (max-width: 53.1875em) {
    .infographic__nav {
      bottom: -60px; } }
  .infographic__nav .infographic__nav-link {
    align-items: center;
    background-color: rgba(0, 113, 185, 0.8);
    color: #fefefe;
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    height: 60px;
    margin-bottom: 5px;
    padding: 1rem;
    padding-left: 2rem;
    transition: background-color 0.3s, color 0.3s;
    width: 306px; }
    .infographic__nav .infographic__nav-link .icon {
      font-size: .75em;
      margin-left: auto;
      margin-right: 0; }
    .infographic__nav .infographic__nav-link:hover {
      background-color: #fefefe;
      color: #e36018; }
    @media screen and (max-width: 63.9375em) {
      .infographic__nav .infographic__nav-link {
        height: 53px; } }

.infographic__tabs-title {
  background: none; }

.infographic__tabs-content {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 0; }
  @media screen and (max-width: 53.1875em) {
    .infographic__tabs-content {
      height: 484px;
      width: 100%; } }

.infographic__content {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity .6s;
  width: 100%; }
  .infographic__content.is-active {
    opacity: 1; }

.infographic__content-1 {
  background-image: url("../img/hp/hp-sinai.jpg");
  opacity: 1 !important; }

.infographic__content-2 {
  background-image: url("../img/hp/hp-northwest.jpg"); }

.infographic__content-3 {
  background-image: url("../img/hp/hp-carroll.jpg"); }

.infographic__content-4 {
  background-image: url("../img/hp/hp-levindale.jpg"); }

.infographic__buttons {
  bottom: 8px;
  left: calc(50% - 120px);
  position: absolute;
  z-index: 2; }

.infographic__button {
  background-color: rgba(0, 113, 185, 0.9);
  border: 2px solid rgba(0, 113, 185, 0.9);
  margin-bottom: 5px;
  padding: 8px 14px;
  text-align: left;
  text-transform: none;
  transition: border 0.3s;
  width: 240px; }
  .infographic__button:hover {
    background-color: #fefefe;
    border: 2px solid #fefefe;
    color: #0f2c52; }
    .infographic__button:hover .icon {
      background-color: #0071b9;
      color: #fefefe; }

.infographic__image {
  background-image: url("../img/diagonals.png");
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .infographic__image .icon {
    background-color: #0f2c52;
    border-radius: 18px;
    height: 36px;
    position: relative;
    transition: background-color 0.3s;
    width: 36px; }
    .infographic__image .icon::before {
      background-position: 0 4px;
      left: 11px;
      position: absolute;
      top: 9px; }
    .infographic__image .icon.icon--find-a-doc {
      font-size: 17px; }
      .infographic__image .icon.icon--find-a-doc::before {
        top: 10px; }
    .infographic__image .icon.icon--make-an-appt {
      font-size: 15px; }
  @media screen and (max-width: 63.9375em) {
    .infographic__image img {
      width: 100%; } }
  .infographic__image.infographic__image--right::before {
    border-bottom: 27px solid transparent;
    border-left: 27px solid #fefefe;
    border-top: 27px solid transparent;
    content: '';
    left: 0;
    position: absolute;
    top: calc(50% - 27px);
    z-index: 2; }
    @media screen and (max-width: 53.1875em) {
      .infographic__image.infographic__image--right::before {
        border: 0; } }
  .infographic__image.infographic__image--right::after {
    border-bottom: 0 solid transparent;
    border-left: 0 solid #fefefe;
    border-top: 0 solid transparent;
    content: '';
    left: 0;
    position: absolute;
    top: 52px; }
    @media screen and (max-width: 53.1875em) {
      .infographic__image.infographic__image--right::after {
        border: 0; } }
  .infographic__image.infographic__image--left::before {
    border-bottom: 27px solid transparent;
    border-right: 27px solid #fefefe;
    border-top: 27px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: calc(50% - 27px);
    z-index: 2; }
    @media screen and (max-width: 53.1875em) {
      .infographic__image.infographic__image--left::before {
        border: 0; } }
  .infographic__image.infographic__image--left::after {
    border-bottom: 0 solid transparent;
    border-right: 0 solid #fefefe;
    border-top: 0 solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 52px; }
    @media screen and (max-width: 53.1875em) {
      .infographic__image.infographic__image--left::after {
        border: 0; } }

.infographic__map {
  background-image: url("../img/hp/infographic-map-nopins.jpg");
  background-repeat: no-repeat; }

.infographic__map-pin {
  height: 12px;
  position: absolute;
  width: 12px;
  z-index: 4; }
  @media screen and (min-width: 64em) {
    .infographic__map-pin {
      opacity: 0; } }
  .infographic__map-pin.infographic__map-pin-1 {
    left: 25%;
    top: 12%; }
  .infographic__map-pin.infographic__map-pin-2 {
    left: 26%;
    top: 17%; }
  .infographic__map-pin.infographic__map-pin-3 {
    left: 26%;
    top: 20.5%; }
  .infographic__map-pin.infographic__map-pin-4 {
    left: 29%;
    top: 18.5%; }
  .infographic__map-pin.infographic__map-pin-5 {
    left: 37%;
    top: 15%; }
  .infographic__map-pin.infographic__map-pin-6 {
    left: 48%;
    top: 19%; }
  .infographic__map-pin.infographic__map-pin-7 {
    left: 47%;
    top: 22.5%; }
  .infographic__map-pin.infographic__map-pin-8 {
    left: 73%;
    top: 13.5%; }
  .infographic__map-pin.infographic__map-pin-9 {
    left: 75.5%;
    top: 16.5%; }
  .infographic__map-pin.infographic__map-pin-10 {
    left: 77%;
    top: 21%; }
  .infographic__map-pin.infographic__map-pin-11 {
    left: 3%;
    top: 29%; }
  .infographic__map-pin.infographic__map-pin-12 {
    left: 31%;
    top: 31%; }
  .infographic__map-pin.infographic__map-pin-13 {
    left: 39%;
    top: 28%; }
  .infographic__map-pin.infographic__map-pin-14 {
    left: 41.5%;
    top: 30%; }
  .infographic__map-pin.infographic__map-pin-15 {
    left: 44%;
    top: 33%; }
  .infographic__map-pin.infographic__map-pin-16 {
    left: 47.5%;
    top: 32%; }
  .infographic__map-pin.infographic__map-pin-17 {
    left: 51%;
    top: 27%; }
  .infographic__map-pin.infographic__map-pin-18 {
    left: 54%;
    top: 29%; }
  .infographic__map-pin.infographic__map-pin-19 {
    left: 56%;
    top: 32%; }
  .infographic__map-pin.infographic__map-pin-20 {
    left: 60%;
    top: 35%; }
  .infographic__map-pin.infographic__map-pin-21 {
    left: 64%;
    top: 35.5%; }
  .infographic__map-pin.infographic__map-pin-22 {
    left: 67%;
    top: 30%; }
  .infographic__map-pin.infographic__map-pin-23 {
    left: 34%;
    top: 47%; }
  .infographic__map-pin.infographic__map-pin-24 {
    left: 42%;
    top: 50%; }
  .infographic__map-pin.infographic__map-pin-25 {
    left: 52%;
    top: 48%; }
  .infographic__map-pin.infographic__map-pin-26 {
    left: 58%;
    top: 52%; }
  .infographic__map-pin.infographic__map-pin-27 {
    left: 63%;
    top: 67%; }
  .infographic__map-pin.infographic__map-pin-28 {
    left: 42.5%;
    top: 40.5%; }
  .infographic__map-pin.infographic__map-pin-29 {
    left: 45.5%;
    top: 42%; }
  .infographic__map-pin.infographic__map-pin-30 {
    left: 39%;
    top: 43%; }
  .infographic__map-pin.infographic__map-pin-31 {
    left: 44%;
    top: 36.5%; }

.pin-anim-1 {
  transition: opacity .5s .3s; }
  .pin-anim-1.is-animating {
    opacity: 1; }

.pin-anim-2 {
  transition: opacity .5s .5s; }
  .pin-anim-2.is-animating {
    opacity: 1; }

.pin-anim-3 {
  transition: opacity .5s .8s; }
  .pin-anim-3.is-animating {
    opacity: 1; }

.pin-anim-4 {
  transition: opacity .5s 1.4s; }
  .pin-anim-4.is-animating {
    opacity: 1; }

.pin-anim-5 {
  transition: opacity .5s 1.2s; }
  .pin-anim-5.is-animating {
    opacity: 1; }

.pin-anim-6 {
  transition: opacity .5s 1.7s; }
  .pin-anim-6.is-animating {
    opacity: 1; }

.pin-anim-7 {
  transition: opacity .5s 1.8s; }
  .pin-anim-7.is-animating {
    opacity: 1; }

.pin-anim-8 {
  transition: opacity .5s 2.3s; }
  .pin-anim-8.is-animating {
    opacity: 1; }

.infographic__stat-container {
  align-items: center;
  display: flex; }
  @media screen and (max-width: 53.1875em) {
    .infographic__stat-container {
      box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2);
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      width: 92%; } }

.infographic__stat-container--tabs {
  align-items: center;
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 53.1875em) {
    .infographic__stat-container--tabs {
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      width: 92%; } }

.infographic__stat {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%; }
  @media screen and (max-width: 63.9375em) {
    .infographic__stat {
      padding-bottom: 1rem;
      padding-top: 1rem; } }
  .infographic__stat.infographic__stat--circle {
    background-color: #f2f0ef;
    border-radius: 50%;
    height: 220px;
    margin-bottom: 2rem;
    width: 220px; }
    .infographic__stat.infographic__stat--circle .infographic__stat-number {
      font-size: 3.75rem; }

.infographic__stat-small {
  font-weight: 600;
  line-height: 1.2;
  padding: 0 8px;
  position: relative;
  text-align: center;
  text-transform: uppercase; }
  .infographic__stat-small.separator-center::after {
    border-color: #e36018;
    border-width: 5px; }

.infographic__stat-main {
  align-items: flex-end;
  color: #fefefe;
  font-size: 2.25rem;
  font-weight: 600;
  left: 40px;
  line-height: 1.2;
  position: absolute;
  text-transform: uppercase;
  top: 30px;
  z-index: 1; }
  @media screen and (min-width: 53.25em) and (max-width: 63.9375em) {
    .infographic__stat-main {
      top: 15px; } }
  @media screen and (max-width: 63.9375em) {
    .infographic__stat-main {
      display: flex;
      font-size: 1.5625rem;
      justify-content: center;
      left: auto;
      position: relative; } }
  .infographic__stat-main .infographic__stat-main-text {
    bottom: 16px;
    left: 113px;
    position: absolute; }
    @media screen and (max-width: 63.9375em) {
      .infographic__stat-main .infographic__stat-main-text {
        bottom: auto;
        left: auto;
        position: relative; } }
  .infographic__stat-main .separator-left::after {
    border-color: #e36018;
    border-width: 5px; }

.infographic__stat-number {
  color: #0071b9;
  display: inline-block;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 4.1875rem;
  font-weight: 700; }
  @media screen and (max-width: 63.9375em) {
    .infographic__stat-number {
      font-size: 2.9375rem; } }
  .infographic__stat-number.infographic__stat-number--small {
    font-size: 3rem;
    line-height: 150%;
    position: relative;
    top: -7px; }

.infographic__stat-number--large {
  color: #fefefe;
  font-size: 11.25rem;
  font-weight: 700;
  line-height: 11.25rem; }
  @media screen and (max-width: 63.9375em) {
    .infographic__stat-number--large {
      font-size: 8rem;
      line-height: 8rem; } }

.link--cancel {
  display: block;
  font-size: 1rem;
  margin-bottom: 1.25rem; }

/* YAPL
name: List
partial: sg-list
selector: .rtf ul
wrapperClasses: rtf
*/
/* YAPL
name: List No Bullets
notes: Often used in conjunction with List No Indent
partial: sg-list-no-bullets
selector: .list--no-bullets
*/
/* YAPL
name: List No Indent
notes: Often used in conjunction with List No Bullets
partial: sg-list-no-indent
selector: .list--no-indent
*/
/* YAPL
name: List Double Spaced
partial: sg-list-double-spaced
selector: .list--double-spaced
*/
/* YAPL
name: List Portlet
partial: sg-list-portlet
selector: .list--portlet
*/
/* YAPL
name: List with Carets
notes: Use in conjunction with List No Bullets
partial: sg-list-carets
selector: .list--carets
*/
.list--no-bullets {
  list-style-type: none; }
  @media screen and (max-width: 63.9375em) {
    .list--no-bullets {
      margin-left: 0; } }

.list--no-indent {
  margin-left: 0; }

.list--double-spaced li {
  margin-bottom: 2rem; }

.list--portlet li {
  border-bottom: 1px solid #e1e1e1;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }
  .list--portlet li:last-child {
    border-bottom: 0; }

.list--carets li {
  background-image: url("../img/icon-list-caret.png");
  background-position: 0 5px;
  background-repeat: no-repeat;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-left: 20px; }
  .list--carets li a {
    color: #2d2c2c; }
    .list--carets li a:hover {
      color: #e36018; }
  @media screen and (max-width: 53.1875em) {
    .list--carets li {
      font-size: 0.875rem; } }

@media screen and (max-width: 63.9375em) {
  .list--carets {
    margin-left: 0; } }

.list--columns-2 {
  columns: 2; }
  @media screen and (max-width: 53.1875em) {
    .list--columns-2 {
      columns: 1; } }

.event__list h3 {
  color: #404040; }

.event__item {
  border-bottom: 1px solid #b7b7b7;
  margin-top: 1.5rem;
  padding-bottom: 2rem; }

.event__buttons a {
  margin-bottom: 0; }
  @media screen and (max-width: 53.1875em) {
    .event__buttons a {
      display: block;
      margin-top: 1rem; } }
  .event__buttons a:first-child {
    margin-right: 1rem; }
    @media print, screen and (min-width: 40em) {
      .event__buttons a:first-child {
        margin-right: 2rem; } }
  .event__buttons a.button {
    margin-bottom: 0; }

.event__date {
  align-items: center;
  background-color: #c6a16d;
  border-radius: 50%;
  color: #fefefe;
  display: flex;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.0625rem;
  font-weight: 700;
  height: 3.75rem;
  justify-content: center;
  line-height: 1.2;
  margin-right: 1rem;
  padding: 0 .75rem;
  text-align: center;
  text-transform: uppercase;
  width: 3.75rem; }
  @media print, screen and (min-width: 53.25em) {
    .event__date {
      font-size: 1.25rem;
      height: 4.875rem;
      margin-right: 2rem;
      padding: 0 1rem;
      width: 4.875rem; } }

@media print, screen and (min-width: 53.25em) {
  .event__details {
    margin-left: 1.5rem; } }

.event__details p {
  margin: 0; }

.locations__gmap {
  height: 300px;
  position: relative; }
  @media screen and (min-width: 40em) and (max-width: 53.1875em) {
    .locations__gmap {
      height: 450px; } }
  @media screen and (min-width: 53.25em) {
    .locations__gmap {
      height: 600px; } }

.locations__gmap-overlay,
.locations__gmap-render {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.locations__gmap-overlay {
  display: none; }

@media screen and (min-width: 40em) {
  .locations__gmap-overlay-container {
    display: none; } }

.locations__list-results {
  margin-top: 2rem; }
  @media screen and (min-width: 53.25em) {
    .locations__list-results {
      margin-top: 4rem; } }

.locations__list-results-count {
  border-bottom: 1px solid #ccc;
  border-top: 8px solid #4dbeb5;
  font-size: 1rem;
  font-weight: 600;
  padding: 1em 0; }

.locations__list-results-list {
  margin-left: 0; }

.locations__list-result {
  border-bottom: 1px solid #ccc;
  padding: 2rem 0; }
  @media screen and (max-width: 53.1875em) {
    .locations__list-result {
      flex-direction: column; } }
  @media screen and (min-width: 53.25em) {
    .locations__list-result {
      align-items: flex-start; } }

.locations__list-result-address,
.locations__list-result-phone {
  margin-bottom: 1em; }

.locations__list-result-address {
  font-style: normal; }

@media screen and (max-width: 53.1875em) {
  .locations__list-result-content {
    font-size: 14px; } }

@media screen and (min-width: 53.25em) {
  .locations__list-result-content {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px; } }

.locations__list-result-desc p {
  line-height: 1.5; }
  .locations__list-result-desc p:last-child {
    margin-bottom: 0; }

@media screen and (min-width: 53.25em) {
  .locations__list-result-desc {
    flex: 1 1 70%; } }

.locations__list-result-dir-link {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase; }
  .locations__list-result-dir-link .icon {
    margin-right: 0.5rem; }

.locations__list-result-img {
  flex: 0 0 auto; }
  .locations__list-result-img img {
    border-bottom: 8px solid #4dbeb5; }
  @media screen and (max-width: 53.1875em) {
    .locations__list-result-img {
      margin-bottom: 1.125rem; } }
  @media screen and (min-width: 53.25em) {
    .locations__list-result-img {
      border-bottom-width: 8px; } }

.locations__list-result-meta {
  line-height: 1.2;
  margin-bottom: 1rem; }
  @media screen and (min-width: 53.25em) {
    .locations__list-result-meta {
      flex: 0 0 30%;
      min-width: 210px;
      padding-right: 20px; } }

.locations__list-result-name {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.2; }
  .locations__list-result-name > a {
    color: inherit; }
  @media screen and (min-width: 53.25em) {
    .locations__list-result-name {
      font-size: 28px;
      margin-bottom: 18px;
      width: 100%; } }

.locations__list-search-connector {
  align-items: center;
  display: flex;
  font-weight: 600; }
  @media screen and (min-width: 40em) {
    .locations__list-search-connector {
      margin-left: .75rem;
      margin-right: .75rem;
      margin-top: 2rem; } }
  @media screen and (max-width: 39.9375em) {
    .locations__list-search-connector {
      margin-top: 1rem; } }

.locations__list-search-fields {
  max-width: 870px; }

@media screen and (min-width: 40em) {
  .locations__list-search-field {
    flex: 0 1 260px; }
    .locations__list-search-field--lg {
      flex: 0 1 500px; }
    .locations__list-search-field--md {
      flex: 0 1 330px; }
    .locations__list-search-field + .locations__list-search-field {
      margin-left: 1.5rem; } }

@media screen and (min-width: 40em) {
  .locations__list-search-row {
    align-items: center;
    display: flex; } }

.locations__map {
  border-top: 8px solid #4dbeb5;
  flex: 1 1 auto;
  padding-top: 1.25rem; }

.locations__map-key {
  margin-top: 1.25rem; }

.locations__map-key-items {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: 0; }

.locations__map-key-item {
  align-items: center;
  display: flex;
  flex: 0 0 230px;
  margin-top: 0.625rem;
  padding-right: 2.5rem; }
  @media screen and (max-width: 63.9375em) {
    .locations__map-key-item {
      flex: 0 1 50%; } }

.locations__map-key-item-icon {
  display: inline-block;
  flex: 0 0 auto;
  height: 3.3125rem;
  margin-right: 0.625rem;
  width: 3.3125rem; }
  @media screen and (max-width: 63.9375em) {
    .locations__map-key-item-icon:not(.locations__map-key-item-icon--sm) {
      height: 2.5rem;
      width: 2.5rem; } }
  .locations__map-key-item-icon--sm {
    height: 2rem;
    width: 2rem; }

.locations__map-key-item-label {
  font-weight: 600;
  line-height: 1.3; }
  @media screen and (max-width: 63.9375em) {
    .locations__map-key-item-label:not(.locations__map-key-item-label--sm) {
      font-size: 0.75rem; } }

.locations__map-loc-filter {
  border-bottom: 1px solid #cecece;
  margin-bottom: 2.375rem;
  padding-bottom: 0.25rem; }
  @media screen and (max-width: 53.1875em) {
    .locations__map-loc-filter {
      flex-direction: column; } }
  @media screen and (min-width: 53.25em) {
    .locations__map-loc-filter {
      align-items: center; } }

@media screen and (min-width: 53.25em) {
  .locations__map-loc-filter-address {
    flex: 0 1 56%; } }

.locations__map-loc-filter-address,
.locations__map-loc-filter-title {
  margin-right: 1rem; }

.locations__map-loc-filter-distance {
  margin-bottom: 1rem; }
  @media screen and (min-width: 53.25em) {
    .locations__map-loc-filter-distance {
      flex: 0 1 31%; } }

.locations__map-loc-filter-title {
  margin-bottom: 1rem;
  white-space: nowrap; }

.locations__map-refine {
  background-color: #4dbeb5;
  border-bottom-left-radius: 1.875rem;
  color: #fff;
  margin-bottom: 1.375rem;
  padding: 1.875rem; }

.locations__map-refine-btn.button {
  margin: 0; }
  .locations__map-refine-btn.button:hover {
    color: #0071b9; }

.locations__map-refine-title {
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1.25rem;
  text-transform: uppercase; }

.locations__map-results {
  flex: 1 1 auto;
  position: relative; }

.locations__map-result {
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 1.2;
  padding: 1.25rem 1.25rem 1.25rem 1.875rem;
  position: relative;
  transition: background-color 200ms; }
  .locations__map-result--active {
    background-color: #f9e0d2; }
  .locations__map-result:not(:first-child) {
    margin-top: 0.25rem; }

.locations__map-result-arrow {
  color: #0071b9;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%); }
  @media screen and (min-width: 53.25em) {
    .locations__map-result-arrow {
      display: none; } }

.locations__map-result-address,
.locations__map-result-phone {
  font-style: normal;
  margin-bottom: 1em; }

.locations__map-result-links {
  list-style: none;
  margin: 0; }
  .locations__map-result-links li {
    display: inline-block; }
    .locations__map-result-links li:not(:last-child)::after {
      content: '|'; }

.locations__map-result-name {
  font-size: 16px;
  margin-bottom: .25em; }

.locations__map-results-list {
  list-style: none;
  margin: 0; }

.locations__map-results-count {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1.25rem; }

.locations__map-search {
  background-color: #f5f5f5;
  border-bottom-left-radius: 1.875rem;
  flex: 0 0 auto; }

.locations__map-search-fields {
  padding: 0 1.5625rem 1.5625rem; }

.locations__list-search-title,
.locations__map-search-title {
  color: #4dbeb5;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase; }

.locations__map-search-title {
  margin: 0; }
  .locations__map-search-title button {
    color: inherit;
    cursor: pointer;
    font-size: inherit;
    font-weight: inherit;
    padding: 1.5625rem;
    position: relative;
    text-align: left;
    text-transform: inherit;
    width: 100%; }
    .locations__map-search-title button i {
      color: #0071b9;
      font-size: 13px;
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%); }

.locations__map-window {
  height: 0;
  position: absolute;
  width: 0; }
  @media screen and (max-width: 39.9375em) {
    .locations__map-window {
      display: none; } }

.locations__map-window-close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px; }
  .locations__map-window-close i {
    color: #e36018;
    font-size: 18px;
    margin-right: 0; }

.locations__map-window-content {
  background: rgba(255, 255, 255, 0.9);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.2;
  position: relative; }
  .locations__map-window-content .rtf {
    font-size: inherit; }
  .locations__gmap-overlay .locations__map-window-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto; }
  .locations__gmap-render .locations__map-window-content {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    margin-top: -80px;
    transform: translate(-50%, -100%);
    width: 400px; }
    .locations__gmap-render .locations__map-window-content::before {
      display: block;
      width: 0;
      height: 0;
      border: inset 42px;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: rgba(255, 255, 255, 0.9) transparent transparent;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 100%; }

ul.locations__map-window-list {
  margin-left: 0;
  margin-top: .25rem; }
  ul.locations__map-window-list li {
    margin-bottom: 0;
    padding-left: 1.5rem;
    overflow: hidden; }

.locations__map-window-inner {
  padding: 5%; }

.locations__map-window-meta-address {
  font-style: normal; }

.locations__map-window-meta-hours {
  color: #50940f; }

.locations__map-window-meta-name {
  font-size: 1.1667em;
  font-weight: 600; }

.locations__map-window-content--lg {
  font-size: 14px; }
  .locations__map-window-content--lg .locations__map-window-desc {
    flex: 0 1 auto; }
  .locations__map-window-content--lg .locations__map-window-inner {
    display: flex; }
  .locations__map-window-content--lg .locations__map-window-list {
    column-count: 2;
    column-gap: 20px;
    margin-bottom: 0; }
  .locations__map-window-content--lg .locations__map-window-meta {
    flex: 0 0 175px;
    padding-right: 20px; }
  .locations__map-window-content--lg .locations__map-window-meta-hours-container {
    margin-top: 1rem; }

.locations__map-window-content--sm .locations__map-window-desc {
  margin-top: 1rem; }

.locations__map-window-content--sm .locations__map-window-hide-sm {
  display: none; }

.locations__map-window-content--sm .locations__map-window-meta {
  display: flex;
  flex-wrap: wrap; }

.locations__map-window-content--sm .locations__map-window-meta-link,
.locations__map-window-content--sm .locations__map-window-meta-name {
  flex: 1 0 100%; }

.locations__map-window-content--sm .locations__map-window-meta-address-hours {
  flex: 0 1 40%; }

.locations__map-window-content--sm .locations__map-window-meta-address-phone {
  flex: 0 1 60%;
  padding-right: 15px; }

@media screen and (max-width: 53.1875em) {
  .locations__map-search--collapsed .locations__map-search-fields {
    display: none; }
  .locations__map-view {
    flex-direction: column; }
  .locations__sidebar {
    margin-bottom: 1.5625rem; } }

@media screen and (min-width: 53.25em) {
  .locations__map {
    margin-left: 4.375rem; }
  .locations__map-results-count,
  .locations__list-search-title,
  .locations__map-search-title {
    font-size: 22px; }
  .locations__map-results-list {
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .locations__map-search-title i {
    display: none; }
  .locations__sidebar {
    flex: 0 0 18.75rem; } }

.mailing-list__icon {
  align-items: center;
  background-color: #e36018;
  border-radius: 50%;
  color: #fefefe;
  display: flex;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  height: 3.75rem;
  justify-content: center;
  line-height: 1.2;
  margin-right: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  width: 3.75rem; }

.menu a:hover {
  transition: color 0.3s; }

.microsite-info {
  align-items: center;
  border-bottom: 1px solid #cecece;
  display: flex;
  min-height: 77px;
  overflow: hidden;
  position: relative; }

.microsite-info__body {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%; }

.microsite-info__heading h3 {
  color: #242424;
  font-size: 1.625rem; }

.microsite-info__subheading {
  color: #4dbeb5;
  font-weight: 600;
  margin-top: 4px; }

.microsite-info__divider {
  color: #cecece;
  font-size: 2.25rem;
  margin: 0 8px; }

/* YAPL
name: More Section
partial: more-section
selector: .more-section
*/
/* YAPL
name: More Section - Microsite
partial: more-section-microsite
selector: .more-section.more-section--microsite
*/
.more-section__block {
  align-items: center;
  background-color: #0071b9;
  border: 2px solid #0071b9;
  display: flex;
  margin-bottom: 8px;
  margin-top: 0;
  padding: 1rem;
  transition: background-color 0.3s, border 0.3s; }
  .more-section__block:hover {
    background-color: #fefefe;
    border: 2px solid #e36018; }
    .more-section__block:hover a {
      color: #e36018; }
      .more-section__block:hover a .icon {
        color: #e36018; }
  .more-section__block a {
    align-items: center;
    color: #fefefe;
    display: flex;
    font-size: 0.9375rem;
    font-weight: 600;
    padding-right: 30px;
    width: 100%; }
  .more-section__block .icon {
    color: #fefefe;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    right: -30px;
    top: 2px; }
  .more-section--microsite .more-section__block {
    border-radius: 32px;
    min-height: 64px; }

@media screen and (min-width: 53.25em) {
  .order-1-medium {
    order: 1; } }

@media screen and (min-width: 53.25em) {
  .order-2-medium {
    order: 2; } }

/* YAPL
name: Padded Box
notes: 1rem padding around content
partial: sg-padded-box
selector: .padded-box-1
*/
/* YAPL
name: Padded Box 1.5
notes: 1.5rem padding around content
partial: sg-padded-box-1-5
selector: .padded-box-1-5
*/
/* YAPL
name: Padded Box 2
notes: 2rem padding around content
partial: sg-padded-box-2
selector: .padded-box-2
*/
.padded-box-1 {
  padding: 1rem; }

.padded-box-1-5 {
  padding: 1.5rem; }

.padded-box-2 {
  padding: 2rem; }
  @media screen and (max-width: 53.1875em) {
    .padded-box-2 {
      padding: 1.5rem; } }

/* YAPL
name: Pagination
partial: pagination
selector: .pagination
*/
.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2rem auto; }
  .pagination a {
    border: 2px solid #bebebe;
    color: #242424;
    font-size: 14px;
    font-weight: 600;
    height: 36px;
    line-height: 32px;
    margin-right: 6px;
    padding: 0 .625rem;
    text-align: center;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    width: 36px; }
    @media screen and (max-width: 39.9375em) {
      .pagination a {
        font-size: 13px;
        height: 30px;
        line-height: 26px;
        margin-right: 4px;
        padding: 0 .5rem;
        width: 30px; } }
    .pagination a.active, .pagination a:hover {
      background-color: #e36018;
      border: 2px solid #e36018;
      color: #fefefe; }
    .pagination a.inactive {
      color: #bebebe;
      pointer-events: none; }
    .pagination a.pagination--width-auto {
      width: auto; }
  .pagination .pagination-ellipsis {
    margin-right: 6px;
    margin-top: 4px;
    text-align: center;
    width: 36px; }
    @media screen and (max-width: 39.9375em) {
      .pagination .pagination-ellipsis {
        display: none; } }

/* YAPL
name: Portlet
partial: sg-portlet
selector: .portlet
*/
/* YAPL
name: Portlet Rounded Corner
partial: sg-portlet-rounded
selector: .portlet.rounded-corner
*/
/* YAPL
name: Portlet with Image
partial: sg-portlet-image
selector: .portlet.portlet__image
*/
.portlet {
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2); }
  @media screen and (min-width: 40em) and (max-width: 53.1875em) {
    .portlet {
      border-bottom-left-radius: 30px; } }
  @media screen and (max-width: 39.9375em) {
    .portlet {
      border-bottom-left-radius: 60px;
      border-top: 8px solid #4dbeb5; } }
  .portlet.portlet--no-shadow {
    box-shadow: 0 0 0 0; }
  .portlet.portlet--no-rounded {
    border-bottom-left-radius: 0 !important; }

.portlet__image {
  border-bottom-left-radius: 60px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 39.9375em) {
    .portlet__image {
      border-bottom-left-radius: 0; } }
  @media screen and (min-width: 40em) and (max-width: 53.1875em) {
    .portlet__image {
      border-bottom-left-radius: 30px;
      width: 33.3333% !important; } }
  .portlet__image img {
    display: block;
    min-height: 100%;
    min-width: 100%; }
    @media screen and (min-width: 40em) {
      .portlet__image img {
        max-width: initial;
        object-fit: cover; } }

.portlet__title {
  font-size: 1.875rem;
  font-weight: 700; }
  @media screen and (max-width: 53.1875em) {
    .portlet__title {
      font-size: 1.625rem; } }

.portlet__text {
  padding: 1rem 2rem; }
  @media screen and (min-width: 53.25em) {
    .portlet__text {
      border-top: 8px solid #4dbeb5; } }
  @media screen and (min-width: 40em) and (max-width: 53.1875em) {
    .portlet__text {
      width: 66.6666% !important; } }
  @media screen and (max-width: 53.1875em) {
    .portlet__text {
      border-top: 0; } }

/* YAPL
name: Quote
partial: sg-quote
selector: .quote
*/
/* YAPL
name: Quote with Image
partial: sg-quote-image
selector: .quote__image-container
*/
.quote {
  padding: 0 80px; }
  @media screen and (max-width: 53.1875em) {
    .quote {
      margin-top: 2rem;
      padding: 0 1rem; } }

.quote__text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-shadow: 1px 1px 10px rgba(10, 10, 10, 0.2); }
  @media screen and (max-width: 53.1875em) {
    .quote__text {
      font-size: 1.3125rem;
      text-shadow: 0 0 0; } }

.quote__author {
  color: #e36018;
  font-size: 0.9375rem;
  font-style: italic;
  font-weight: 600;
  padding-left: 156px;
  position: relative; }
  .quote__author::before {
    background-color: #e36018;
    content: '';
    display: inline-block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 9px;
    width: 142px; }

.quote__more {
  align-items: center;
  color: #2d2c2c;
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: color 0.3s; }
  .quote__more .icon {
    align-items: center;
    background-color: #e36018;
    border-radius: 13px;
    color: #fefefe;
    display: flex;
    font-size: 12px;
    height: 26px;
    justify-content: center;
    margin-right: 8px;
    width: 26px; }
  .quote__more:hover {
    color: #e36018; }

.quote__image-container {
  align-items: center;
  border: 8px solid #cabc9d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  max-height: 200px;
  max-width: 200px;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 53.1875em) {
    .quote__image-container {
      margin-right: auto; } }

.quote__image {
  border-radius: 50%; }

.reveal.full {
  background-color: rgba(10, 10, 10, 0.8); }

.reveal iframe {
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%); }

.reveal .close-button {
  background-color: rgba(10, 10, 10, 0.5);
  border-radius: 28px;
  color: #e36018;
  font-size: 44px;
  height: 56px;
  right: 20px;
  top: 40px;
  width: 56px; }

.rounded-box {
  height: 100%; }
  .rounded-box.rounded-box__shadow {
    border-bottom-left-radius: 60px;
    box-shadow: 0 0 0.625rem rgba(10, 10, 10, 0.2); }

.rounded-box__heading {
  background-color: #0071b9;
  height: 173px;
  padding: 2rem;
  text-align: center; }
  .rounded-box--green .rounded-box__heading {
    background-color: #4dbeb5; }
  @media screen and (max-width: 53.1875em) {
    .rounded-box__heading {
      height: auto;
      padding: 1.5rem; } }
  .rounded-box--microsite .rounded-box__heading {
    align-items: center;
    background-color: #fefefe;
    border-bottom: 1px solid #cecece;
    border-top: 8px solid #0071b9;
    color: #0071b9;
    display: flex;
    height: 77px;
    padding: 1rem 2rem;
    text-align: left; }
  .rounded-box--microsite__green-blue .rounded-box__heading {
    border-top: 8px solid #4dbeb5; }

.rounded-box__body {
  background: #fefefe;
  border-bottom-left-radius: 60px;
  height: calc(100% - 173px);
  padding: 2rem; }
  @media screen and (max-width: 53.1875em) {
    .rounded-box__body {
      padding: 2rem 1rem; } }
  .rounded-box__body.rounded-box__shadow {
    box-shadow: 0 0 0.625rem rgba(10, 10, 10, 0.2); }
  .rounded-box--microsite .rounded-box__body {
    padding-top: 0; }

.rounded-box__body-title {
  font-size: 1.375rem;
  font-weight: 600;
  text-transform: uppercase; }
  @media screen and (max-width: 53.1875em) {
    .rounded-box__body-title {
      font-size: 1.1875rem; } }

.rounded-box__title {
  color: #fefefe;
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase; }
  @media screen and (max-width: 53.1875em) {
    .rounded-box__title {
      font-size: 1.5rem; } }
  .rounded-box--microsite .rounded-box__title {
    font-size: 1.5rem; }

.rounded-corner {
  border-bottom-left-radius: 60px; }

.rounded-corner--small {
  border-bottom-left-radius: 30px; }

.rounded-corner--top-right {
  border-top-right-radius: 60px; }
  @media screen and (max-width: 63.9375em) {
    .rounded-corner--top-right {
      border-top-right-radius: 0; } }

.rtf img {
  margin-right: 2rem; }

.search__toggle {
  background-color: #e1e1e1;
  border-radius: 40px;
  display: block;
  height: 40px;
  position: relative;
  transition: background-color 0.3s, color 0.3s;
  width: 40px; }
  @media screen and (min-width: 64em) {
    .search__toggle:hover {
      background-color: #e36018; }
      .search__toggle:hover .icon {
        color: #fefefe; } }
  .search__toggle .icon {
    font-size: 1.15em;
    left: 11px;
    position: absolute;
    top: 8px; }
  .search__toggle.is-close {
    background-color: #fefefe; }
    .search__toggle.is-close .icon::before {
      content: 's' !important; }
  .search__toggle.search--sublocation {
    background-color: #0071b9;
    color: #fefefe !important; }
    .search__toggle.search--sublocation:hover {
      background-color: #e36018; }
  .search__toggle.search__toggle--orange {
    background-color: #e36018;
    color: #fefefe !important; }
    .search__toggle.search__toggle--orange:hover {
      background-color: #0071b9; }

.search.search--main {
  background-color: #fefefe;
  border-top: 10px solid #0f2c52;
  display: none;
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  transition: height 0.3s;
  width: 100%;
  z-index: 4; }
  .search.search--main .close-button {
    font-size: 70px;
    position: absolute;
    right: 0;
    top: 30px; }
    .search.search--main .close-button:hover {
      color: #e36018; }
  .search.search--main.is-expanded {
    display: block;
    height: 146px;
    top: 0; }
  .header--microsite .search.search--main {
    border-top: 0; }

.search.search--mobile {
  background-color: #fefefe;
  display: none;
  position: absolute;
  top: 70px;
  width: 100%; }
  .search.search--mobile.is-expanded {
    display: block;
    left: 0; }

.search__input-group {
  position: relative;
  width: 100%; }
  .header-main .search__input-group {
    width: 90%; }
  .search__input-group label {
    width: 100%; }
  .search__input-group .search__input {
    background-color: #fefefe;
    border: 1px solid #b7b7b7;
    border-radius: 10px;
    height: 64px;
    padding: 1rem 1.5rem; }
    .search__input-group .search__input:focus {
      border: 1px solid #b7b7b7;
      box-shadow: 0 0 0 0; }
    .header-main .search__input-group .search__input {
      height: 71px;
      position: relative;
      top: 30px; }
    .search--mobile .search__input-group .search__input {
      height: 57px;
      left: 5%;
      top: 0;
      width: 90%; }
  .search__input-group .search__button {
    background-color: #e1e1e1;
    border: 0;
    border-radius: 20px;
    color: #e36018;
    height: 40px;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 12px;
    width: 40px; }
    .search__input-group .search__button:focus, .search__input-group .search__button:hover {
      background-color: #e36018;
      color: #fefefe; }
    .header-main .search__input-group .search__button {
      top: 8px; }
    .search--mobile .search__input-group .search__button {
      right: -8px;
      top: 4px; }
    .search__input-group .search__button .icon--search {
      font-size: 1em;
      position: relative;
      right: -3px;
      top: 0; }
      .search__input-group .search__button .icon--search::before {
        font-size: 1.15em; }

.search--main .input-group-button {
  position: absolute;
  right: 10%;
  top: 40px; }

.search--mobile .input-group-button {
  right: 0;
  top: 5px; }

.separator--green::after {
  border: 3px solid #4dbeb5; }

.separator-center::after {
  transition: width 2s;
  width: 0; }

.separator-center.is-animating::after,
.is-animating .separator-center::after {
  width: 3rem; }

@media screen and (max-width: 53.1875em) {
  .separator-center::after {
    margin-top: .5rem;
    width: 3rem; } }

/* YAPL
name: Table
notes: wrapping div with table-scroll allows horizontal scroll at small media queries
partial: sg-table
selector: .table.table--horizontal
*/
table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 0;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 700; }
  table thead {
    background: #c6a16d;
    color: #fefefe; }
  table tfoot {
    background: #f1f1f1;
    color: #2d2c2c; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: 700;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 0;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #c39c66; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.table-scroll {
  width: 100.5%; }
  .table-scroll table {
    margin-bottom: 0;
    min-width: 99.5%; }

.table {
  width: 100%; }
  .table ul {
    margin-left: 0; }
    .table ul li {
      margin-bottom: 0;
      padding-left: 0; }
      .table ul li::before {
        display: none; }
  .table tr th {
    height: 54px; }
  .table tr td {
    color: #2d2c2c;
    font-size: 14px; }
    .table tr td:first-child {
      font-weight: 700; }
  .table thead tr th {
    background-color: #c6a06c;
    font-size: 14px;
    text-transform: uppercase; }
    .table thead tr th:first-child {
      background-color: #b98e5a; }
  .table tbody tr:nth-child(even) td {
    background-color: #fff; }
    .table tbody tr:nth-child(even) td:first-child {
      background-color: #f4f4f4; }
  .table tbody tr:nth-child(odd) {
    background-color: #f4f4f4; }
    .table tbody tr:nth-child(odd) td {
      background-color: #f4f4f4; }
      .table tbody tr:nth-child(odd) td:first-child {
        background-color: #e9e9e9; }

/* YAPL
name: Tabs Horizontal
partial: sg-tabs-horizontal
selector: .basic-tabs.tabs
*/
/* YAPL
name: Tabs Vertical
partial: sg-tabs-vertical
selector: .basic-tabs.tabs.tabs--vertical
*/
.basic-tabs {
  border: 0; }
  .basic-tabs .tabs-content,
  .basic-tabs .tabs-title {
    box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2); }
  .basic-tabs .tabs-title {
    margin-right: 0.625rem; }
    .basic-tabs .tabs-title a {
      background-color: #fefefe;
      box-shadow: inset 0 10px 0 0 #1779ba;
      font-weight: 600;
      height: 3.75rem;
      text-align: center;
      text-transform: uppercase; }
      @media screen and (min-width: 40em) {
        .basic-tabs .tabs-title a {
          font-size: 1.125rem;
          min-width: 9.625rem; } }
      @media screen and (max-width: 39.9375em) {
        .basic-tabs .tabs-title a {
          font-size: 0.8125rem;
          padding-left: .5rem;
          padding-right: .5rem; } }
    .basic-tabs .tabs-title.is-active a {
      box-shadow: inset 0 10px 0 0 #0f2c52;
      position: relative;
      z-index: 1; }
      .basic-tabs .tabs-title.is-active a::after {
        background-color: #fefefe;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%; }
  .basic-tabs .tabs-content {
    position: relative; }
  .basic-tabs.tabs--contact-form .tabs-title a {
    text-transform: capitalize; }
  .basic-tabs.tabs--contact-form .tabs-panel {
    padding: 1rem 2rem 2rem; }
    @media print, screen and (min-width: 53.25em) {
      .basic-tabs.tabs--contact-form .tabs-panel {
        padding: 1rem 2.5rem 2.5rem; } }
  .basic-tabs.tabs--vertical .tabs-content {
    justify-content: flex-start;
    min-height: 400px; }
  .basic-tabs.tabs--vertical .tabs-title {
    align-items: center;
    border-left: 0.625rem solid #0071b9;
    box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2);
    display: flex;
    min-height: 76px;
    transition: border-left-color .25s;
    width: 100%; }
    .basic-tabs.tabs--vertical .tabs-title.is-active, .basic-tabs.tabs--vertical .tabs-title:hover, .basic-tabs.tabs--vertical .tabs-title:focus {
      border-left-color: #0f2c52; }
      .basic-tabs.tabs--vertical .tabs-title.is-active > a, .basic-tabs.tabs--vertical .tabs-title:hover > a, .basic-tabs.tabs--vertical .tabs-title:focus > a {
        color: #0f2c52; }
    .basic-tabs.tabs--vertical .tabs-title.is-active {
      box-shadow: 0 0 1.25rem rgba(10, 10, 10, 0.2);
      width: calc(100% + 1px); }
    .basic-tabs.tabs--vertical .tabs-title > a {
      border-top: 0;
      box-shadow: 0 0 0 0;
      color: #0071b9;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      justify-content: center;
      min-height: 76px;
      padding: 1rem;
      text-align: left;
      text-transform: capitalize;
      width: 100%; }
      .basic-tabs.tabs--vertical .tabs-title > a::after {
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%; }

.tile {
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 53.1875em) {
    .tile {
      margin-left: auto;
      margin-right: auto;
      max-width: 400px; }
      .tile.cell {
        margin-left: auto;
        margin-right: auto; } }
  .tile:hover .tile__image {
    transform: scale(1.1); }
  .tile:hover .tile__text {
    color: #e36018; }
    .tile:hover .tile__text .icon--arrow-right {
      color: #505050; }
  .tile.tile--shadow {
    box-shadow: 0 0 0.625rem rgba(10, 10, 10, 0.25); }

.tile--max-width {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 300px; }

.tile__header {
  color: #404040;
  font-size: 2.25rem; }
  @media screen and (max-width: 53.1875em) {
    .tile__header {
      font-size: 1.75rem;
      text-align: center; } }

.tile__image {
  overflow: hidden;
  position: relative;
  transition: transform 0.3s; }

.tile__tag {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #fefefe;
  color: #fefefe;
  font-size: 0.75rem;
  font-weight: 600;
  left: 18px;
  padding: 0.25rem 0.5rem;
  position: absolute;
  text-transform: uppercase;
  top: 20px;
  z-index: 9; }
  .tile__tag.tile__tag--blue {
    background-color: rgba(0, 113, 185, 0.9); }
  .tile__tag.tile__tag--orange {
    background-color: rgba(227, 96, 24, 0.9); }
  .tile__tag.tile__tag--green {
    background-color: rgba(77, 190, 181, 0.9); }
  .tile__tag.tile__tag--brown {
    background-color: rgba(151, 111, 63, 0.9); }

.tile__text {
  background-color: #fefefe;
  color: #2d2c2c;
  font-weight: 600;
  padding: 44px 2rem 10px;
  position: relative;
  text-align: center;
  transition: color 0.3s; }
  .tile__text .icon--arrow-right {
    transition: color 0.3s; }
  .tile__text.tile__text--equal-padding {
    font-weight: 400;
    padding: 1.5rem;
    text-align: left; }
  .tile__text h4 {
    color: #e36018;
    font-size: 1.25rem;
    font-weight: 700; }
  .tile__text p {
    font-size: 0.875rem; }

.tile__text-link {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase; }
  .tile__text-link .icon {
    margin-right: 0.5rem; }

.tile__icon {
  color: #fefefe;
  left: calc(50% - 28px);
  position: absolute;
  top: -28px;
  z-index: 1; }
  .tile__icon::before {
    left: 15px;
    position: absolute;
    top: 15px; }
  .tile__icon.icon--patient-story::before, .tile__icon.icon--treatment::before {
    left: 16px; }
  .tile__icon.icon--treatment::before {
    top: 16px; }

.tile__list {
  margin: 1rem 0; }
  .tile__list > a {
    border-bottom: 1px solid #b7b7b7;
    display: block;
    padding: 0.75rem 0; }
    .tile__list > a:last-child {
      border-bottom: 0; }
  .tile__list.tile__list--semibold > a {
    font-weight: 600; }
  .tile__list.tile__list--border-bottom > a:last-child {
    border-bottom: 1px solid #b7b7b7; }

.awesomplete {
  display: block; }

/* YAPL
name: Upcoming Events
partial: hp-upcoming-events
selector: .upcoming-events
*/
/* YAPL
name: Upcoming Events Microsite
partial: sg-upcoming-events-microsite
selector: .upcoming-events.upcoming-events--microsite
*/
.upcoming-events {
  margin-left: 0; }
  .upcoming-events li {
    margin-top: 2rem; }
    .upcoming-events li:first-child {
      margin-top: 0; }
  .upcoming-events .upcoming-events__item {
    align-items: center;
    display: flex; }
    .upcoming-events .upcoming-events__item:hover {
      color: #e36018; }
      .upcoming-events .upcoming-events__item:hover .upcoming-events__date {
        background-color: #e36018; }
  .upcoming-events .upcoming-events__item-detail {
    display: block;
    font-size: 1.25rem;
    line-height: 1.3;
    transition: color 0.3s; }
    .upcoming-events .upcoming-events__item-detail span {
      color: #2d2c2c;
      display: block;
      font-size: 0.9375rem;
      line-height: 1.6; }
    @media screen and (max-width: 63.9375em) {
      .upcoming-events .upcoming-events__item-detail {
        font-size: 1.125rem; }
        .upcoming-events .upcoming-events__item-detail span {
          font-size: 0.8125rem; } }
  .upcoming-events .upcoming-events__date {
    align-items: center;
    background-color: #c6a16d;
    border-radius: 50%;
    color: #fefefe;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    height: 78px;
    justify-content: center;
    line-height: 1;
    margin-right: 1rem;
    text-transform: uppercase;
    transition: background-color 0.3s;
    width: 78px; }
    .upcoming-events .upcoming-events__date span {
      display: block;
      font-size: 1.4375rem; }
  .upcoming-events.upcoming-events--microsite li {
    border-bottom: 1px dashed #cecece;
    margin-top: 1.5rem; }
    .upcoming-events.upcoming-events--microsite li:last-child {
      border-bottom: 0; }
  .upcoming-events.upcoming-events--microsite .upcoming-events__item {
    padding-bottom: 1.5rem; }
  .upcoming-events.upcoming-events--microsite .upcoming-events__date {
    background-color: #e36018;
    font-size: 1rem;
    height: 63px;
    width: 63px; }
    .upcoming-events.upcoming-events--microsite .upcoming-events__date span {
      font-size: 1rem; }
  .upcoming-events.upcoming-events--microsite .upcoming-events__item-detail {
    font-size: 1.125rem; }

.updates {
  position: fixed;
  right: -55px;
  top: 248px;
  z-index: 12; }
  @media screen and (max-width: 63.9375em) {
    .updates {
      display: none; } }

.updates__button {
  align-items: center;
  background-color: #e36018;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: #fefefe;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 52px;
  padding: 10px;
  text-transform: uppercase;
  transform: rotate(90deg);
  transition: background-color 0.3s;
  width: 162px; }
  .updates__button .icon {
    background-color: #fefefe;
    border-radius: 13px;
    color: #c6a16d;
    height: 26px;
    pointer-events: none;
    position: relative;
    transform: rotate(-90deg);
    transition: background-color 0.3s;
    width: 26px; }
    .updates__button .icon::before {
      left: 6px;
      position: absolute;
      top: 6px; }
  .updates__button:hover, .updates__button:focus {
    background-color: #ed7821;
    color: #fefefe; }

.updates__button-text {
  margin-left: 6px; }

.updates__form {
  background-color: #0071b9;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  color: #fefefe;
  display: none;
  padding: 2rem;
  position: fixed;
  right: 0;
  top: 0;
  width: 707px;
  z-index: 11; }
  .updates__form.is-open {
    display: block; }
  @media (max-height: 600px) {
    .updates__form {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      height: 100vh;
      overflow-y: auto; } }
  .updates__form .close-button {
    background-color: #fefefe;
    border-radius: 16px;
    color: #e36018;
    height: 32px;
    width: 32px; }

.updates__body * {
  color: #fefefe; }

.updates__body h3 {
  font-size: 1.5625rem; }

.updates__body h4 {
  font-size: 1.125rem; }

.updates__checkbox {
  align-items: flex-start;
  display: flex;
  margin-bottom: 4px; }
  .updates__checkbox label {
    font-weight: 600;
    margin-top: -5px; }

.updates__input-wrap {
  position: relative; }
  .updates__input-wrap input:focus + label {
    display: none; }
  .updates__input-wrap input:valid + label {
    display: none; }
  .updates__input-wrap label {
    color: #656565;
    font-weight: 500;
    left: 12px;
    position: absolute;
    top: 10px;
    z-index: 1; }
    .updates__input-wrap label span {
      color: #e36018; }

.updates__input {
  border: 1px solid #b7b7b7;
  border-radius: 8px;
  color: #656565;
  height: 46px; }

.updates__legal {
  font-size: 13px; }

.sg-block__list {
  list-style-type: none;
  margin-left: 0; }
  .sg-block__list > li h3 {
    font-size: 1.25rem; }
  .sg-block__list > li h4 {
    font-size: 1rem; }
  .sg-block__list .sg-block__module-list {
    margin-left: 0; }

.sg-header__left img {
  width: 300px; }

@media print {
  .show-for-print {
    display: block !important;
    height: auto;
    position: relative; }
  .hide-for-print {
    display: none !important; }
  a {
    text-decoration: none !important; }
    a [href]::after {
      content: ''; }
  .accordion__arrow,
  .accordion__button,
  .accordion--search,
  .alphabet-list,
  .button,
  .button__container,
  .chat,
  .cta,
  .diagonals,
  .doc-profile__refine-col,
  .filter,
  .follow,
  .follow__facebook,
  .follow__icons,
  .footer-main__logo,
  .footer__social,
  .footer__utility,
  .future,
  .header__back-link,
  .hero__image,
  .hero-nav__section-container,
  .hp-portlets,
  .infographic,
  .infographic__stat-container--tabs,
  .more-section,
  .more-section__block,
  .nav-main,
  .nav__secondary,
  .nav-utility,
  .nav-breadcrumbs__mobile-toggle,
  .nav-breadcrumbs__submenu,
  .nav-breadcrumbs__icon,
  .nav-breadcrumbs__submenu.js-dropdown-active,
  .pagination,
  .portlet,
  .quote,
  .quote__image-container,
  .search__toggle,
  .social,
  .tile__header,
  .tile__header + div,
  .updates,
  .video,
  .view-more {
    display: none !important; }
  .white,
  .orange,
  .gray,
  .medium-brown,
  .red,
  .green-blue,
  .medium-blue {
    color: #000 !important; }
  .align-center {
    justify-content: flex-start; }
  .accordion-content {
    border: 0 !important;
    display: block !important;
    padding: 0; }
  .accordion-item {
    border-left: 0; }
  .accordion-title {
    border: 0 !important;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-top: 0;
    text-decoration: none; }
    .accordion-title::before {
      content: '' !important; }
  .accordion--microsite .accordion-item.is-active {
    border-color: #000 !important; }
  .article__header {
    padding-bottom: 1rem;
    padding-left: 0;
    padding-top: 1rem; }
    .article__header > .row {
      margin-left: 0;
      margin-right: 0; }
    .article__header h1 {
      margin-top: 0; }
  .background-padding {
    padding: 0; }
  .breadcrumbs {
    margin: 0 auto;
    max-width: 75rem; }
  .border--green {
    border: 0; }
  .border-green-bottom {
    border-color: #000 !important; }
  .box {
    padding: 1rem 0 !important; }
  .box--padding {
    padding: 1rem 0 !important; }
  .box.box--margin-left {
    margin-left: 0 !important; }
  *[class*='background--'],
  *[class*='bordered-element--'] {
    background: none !important;
    background-color: #fefefe !important;
    border: 0; }
  .careers-info {
    display: block;
    padding: 0; }
  .careers-results table tr td:nth-child(1) {
    display: none; }
  .figure__caption {
    max-width: 400px !important;
    padding: 1rem 0; }
  .figure__image {
    border-bottom: 0;
    max-width: 400px !important; }
  .figure__video {
    display: none; }
  .figure.float-left,
  .figure--small,
  .figure.float-left.figure--offset {
    float: none;
    margin-bottom: 1rem;
    margin-left: 0;
    width: auto; }
  .figure.float-right {
    float: none;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0; }
  .footer-main {
    margin-top: 3rem; }
  .footer-main__address {
    border-left: 0; }
  .footer-main__info {
    display: block;
    padding: 0;
    text-align: center; }
  .footer-main .contact {
    display: block;
    margin: 0 auto;
    position: relative;
    right: auto;
    top: auto; }
  .grid-margin-x > .cell {
    width: 100%; }
  .grid-margin-x.medium-up-2 > .cell {
    width: 100%; }
  .header-main__sticky {
    border: 0 !important;
    top: 0 !important; }
  .hero,
  .hero__wrap {
    height: auto !important;
    min-height: 0 !important; }
  .hero-directions {
    padding-left: 0;
    padding-right: 0;
    position: relative; }
  .hero__overlay {
    margin-top: 0; }
  .hero__textbox {
    padding-left: 0;
    padding-right: 0;
    width: auto; }
  .microsite-info {
    border-bottom: 0; }
  .rounded-box__heading {
    height: auto;
    margin-top: 2rem;
    text-align: left; }
  .rounded-box__heading,
  .rounded-box__body {
    border-color: #000 !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0; }
  .rtf h1,
  .rtf h2,
  .rtf h3,
  .rtf h4,
  .rtf h5,
  .rtf h6 {
    margin-top: 1rem; }
  .rtf ul {
    color: #000;
    list-style-type: circle !important;
    margin-left: 0; }
    .rtf ul li {
      list-style-type: disc !important; }
      .rtf ul li::before {
        content: ''; }
      .rtf ul li li::before {
        content: ''; }
  .rtf ol li::before {
    color: #000; }
  .tabs .cell {
    width: 100% !important; }
  .tabs-title {
    display: none !important; }
  .tabs-panel {
    display: block !important;
    padding-left: 0;
    padding-right: 0; }
  *[class*='vertical-margin-'] {
    margin-top: 1rem !important; } }
