.nav-main {

  @include breakpoint(medium down) {
    background-color: $white;
    border-top: 2px solid $border-gray;
    width: 100%;
  }

  &.nav--campaign {
    align-items: center;
    background-color: rgba($medium-blue, .8);
    display: flex;
    height: 41px;
    justify-content: space-around;
    width: 100%;

    @include breakpoint(medium down) {
      align-items: flex-start;
    }

    .is-nav-open & {
      @include breakpoint(medium down) {
        background-color: $medium-blue;
      }
    }
  }
}

.nav--campaign-wrap {
  height: 41px;
  width: 100%;

  .is-stuck & {
    background-color: $medium-blue;
    margin-top: 8px;
  }

  @include breakpoint(medium down) {
    background-color: none;
    height: 0;
    margin: 0;
  }
}

.nav-main__links {
  justify-content: flex-end;
  margin-right: 40px;

  @include breakpoint(medium down) {
    margin-right: 0;
  }

  .nav--campaign & {
    justify-content: space-between;
    margin-right: 0;
    width: 100%;
  }

  .nav--microsite & {
    margin-right: 10px;

    @include breakpoint(medium down) {
      margin-right: 0;
    }
  }

  .nav--careers & {
    margin-right: 10px;
  }
}

.nav-main__link {
  color: $dark-gray;
  font-size: rem-calc(16);
  font-weight: $global-weight-bold;
  margin-right: 12px;
  padding-left: 18px;
  text-transform: uppercase;

  @include breakpoint(medium down) {
    border-bottom: 1px solid $border-gray;
    padding-bottom: 6px;
    padding-top: 6px;
  }

  &:hover {
    color: $orange;
  }

  > a {
    color: inherit;
    padding-left: 22px;
    padding-right: 0;
  }

  .icon {
    font-size: .8em;
  }

  .nav--campaign & {
    color: $white;
    font-size: rem-calc(13);

    @include breakpoint(medium down) {
      color: $black;
      font-size: rem-calc(16);
    }
  }

  .nav--microsite & {
    color: $white;
    font-size: rem-calc(14);
    margin-right: 4px;
    padding-left: 10px;
    transition: color $global-transition-speed;

    .is-stuck &,
    .header-microsite--sub & {
      color: $dark-gray;
    }

    @include breakpoint(medium down) {
      color: $dark-blue;
    }

  }

  .nav--careers & {
    margin-right: 4px;
    padding-left: 10px;
  }
}

@include breakpoint(medium down) {
  .nav-main {
    background-color: $dark-blue;

    .is-nav-open & {
      height: calc(100vh - #{$header-height-phablet});
      overflow: auto;
      position: relative;
    }
  }

  .nav-main__menu {
    > .nav-main__utility { //select the first
      margin-top: 34px;
    }

    > .nav-main__utility ~ .nav-main__utility { //select all but the first
      margin-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .nav-main__link {
    background-color: $white;
  }

  .nav-main__utility {
    font-size: rem-calc(15);
    font-weight: $global-weight-semibold;
    padding-bottom: 1px;
    padding-top: 1px;
    text-align: center;

    a {
      background-color: $white;
      color: $medium-dark-blue;
      height: 50px;
      line-height: 50px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      width: 80%;
    }
  }


  .nav-main__menu-item {
    overflow: hidden;

    &:first-child {
      border-top: 0;
      margin-top: 34px;
    }
  }
}
