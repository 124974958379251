.microsite-info {
  align-items: center;
  border-bottom: 1px solid #cecece;
  display: flex;
  min-height: 77px;
  overflow: hidden;
  position: relative;
}

.microsite-info__body {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.microsite-info__heading {
  h3 {
    color: $caption-gray;
    font-size: rem-calc(26);
  }
}

.microsite-info__subheading {
  color: $green-blue;
  font-weight: $global-weight-semibold;
  margin-top: 4px;
}

.microsite-info__divider {
  color: #cecece;
  font-size: rem-calc(36);
  margin: 0 8px;
}
