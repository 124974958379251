/* YAPL
name: Accordion
partial: accordion
context: accordion.article
selector: .accordion
*/


.accordion {
  @include accordion-container;

  &.accordion--microsite {
    box-shadow: 0 0 rem-calc(10) rgba($black, .25);
  }
}

.accordion-item {
  border-left: rem-calc(10) solid $medium-blue;
  box-shadow: 0 0 rem-calc(10) rgba($black, .25);
  margin-top: .5rem;
  transition: border-left-color .25s;

  &:hover,
  &:focus {
    border-left-color: smart-scale($primary-color, 25%);

    @include breakpoint(medium down) {
      border-left-color: $dark-blue;
    }

    .accordion--microsite & {
      border-bottom: 1px solid $orange;

      &.is-active {
        border-bottom: 1px solid #cecece;
      }
    }
  }

  &.is-active {
    border-left-color: $dark-blue;
  }

  .accordion--microsite & {
    border-bottom: 1px solid #cecece;
    border-left: 0;
    box-shadow: 0 0 0 0;
    margin-top: 0;

    &.is-active {
      border-top: 10px solid $orange;
    }
  }
}

.accordion-title {
  align-items: center;
  border: 0;
  display: flex;
  flex-flow: row wrap;
  font-size: rem-calc(16);
  font-weight: $global-weight-bold;
  justify-content: space-between;
  line-height: 1.3;
  padding: 1.75rem $global-padding;
  position: relative;
  transition: background-color $global-transition-speed, color $global-transition-speed;

  &:hover,
  &:focus {
    background-color: $medium-blue;
    color: $white;

    .accordion-title__link {
      color: smart-scale($primary-color, 25%);
    }

    .accordion--microsite & {
      background-color: $orange;
      color: $white !important;

      &:hover {
        &::before {
          color: $white;
        }
      }
    }
  }

  &::before {
    font-size: rem-calc(24);
    right: 24px;
    top: 37%;

    .accordion--microsite & {
      color: $orange;
    }
  }

  .is-active & {
    &::before {
      font-size: rem-calc(24);
      top: 34%;
    }

    .accordion--microsite & {
      color: $orange;
    }
  }

  .accordion--microsite & {
    color: $caption-gray;
    font-size: rem-calc(18);
  }
}

.accordion-title__text {
  flex: 1 1 0%;
  padding-right: 38px;
}

.accordion-content {
  @include accordion-content;
  @include clearfix;
  padding-bottom: #{$global-padding * 2};
  padding-top: #{$global-padding * 2};

  .accordion--microsite & {
    border-top: 0;
    padding-top: 0;
  }
}

@include breakpoint(phablet down) {
  .accordion-title,
  .accordion-content {
    padding: #{$global-padding / 2};
  }

  .accordion-title {
    font-size: rem-calc(16);
    font-weight: $global-weight-semibold;
    padding-bottom: #{$global-padding * 1.5};
    padding-top: #{$global-padding * 1.5};
  }
}
