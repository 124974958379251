.sg-block__list {
  list-style-type: none;
  margin-left: 0;

  > li {
    h3 {
      font-size: 1.25rem;
    }

    h4 {
      font-size: 1rem;
    }
  }

  .sg-block__module-list {
    margin-left: 0;
  }
}


.sg-header__left {
  img {
    width: 300px;
  }
}
