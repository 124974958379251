.footer-main {
  background-color: $dark-blue;
  color: $white;
  min-height: 184px;
  padding: 0 0 73px;
  position: relative;

  @include breakpoint (phablet down) {
    padding-bottom: $global-padding;
  }
}

.footer-main__container {
  position: relative;

  @include breakpoint (phablet down) {
    padding-left: 0;
    padding-right: 0;
  }
}

.footer-main__logo {
  background-image: url('../img/logo-reversed.svg');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 48px;
  min-width: 440px;
  padding-right: $global-padding;

  @include breakpoint (phablet down) {
    background-size: 100% auto;
    height: auto;
    min-width: auto;
    padding-bottom: #{$global-padding * 2};
    padding-top: #{$global-padding * 2};
    width: 330px;
  }

  .sublocation & {
    background-image: url('../img/logo-sinai-white.svg');
    min-width: 336px;

    @include breakpoint (phablet down) {
      margin-top: 20px;
    }
  }
}

.footer__utility {
  background-color: $very-dark-blue;
}

.footer__utility-nav {
  align-items: center;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-height: 85px;

  div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  a {
    color: inherit;
    font-size: rem-calc(13);

    &:hover {
      color: #f48142;
    }
  }

  .icon {
    font-size: rem-calc(12);
    margin-right: 2px;
    margin-top: 3px;
  }

  @include breakpoint (medium down) {
    flex-direction: column;
    padding: #{$global-padding * 2} 0;

    div {
      font-weight: $global-weight-semibold;
      margin-bottom: #{$global-padding * 1.5};

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon {
      display: none;
    }
  }
}

.footer-main__info {
  align-items: center;
  display: flex;
  padding-top: 58px;

  @include breakpoint (phablet down) {
    flex-direction: column;
    padding-top: 78px;
  }
}

.footer-main__address {
  border-left: 2px solid $divider-gray;
  padding-left: $global-padding;
  text-align: center;

  h5 {
    color: $white;
    font-size: rem-calc(18);
    font-weight: $global-weight-bold;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    font-size: rem-calc(15);
    margin-bottom: 0;
  }

  @include breakpoint(large up) {
    text-align: left;
  }

  @include breakpoint (phablet down) {
    border-left: 0;
    border-top: 1px solid $divider-footer;
    margin-top: $global-margin;
    padding-bottom: $global-padding;
    padding-top: #{$global-padding * 1.5};
    width: 100%;
  }
}
