/* YAPL
name: Portlet
partial: sg-portlet
selector: .portlet
*/

/* YAPL
name: Portlet Rounded Corner
partial: sg-portlet-rounded
selector: .portlet.rounded-corner
*/

/* YAPL
name: Portlet with Image
partial: sg-portlet-image
selector: .portlet.portlet__image
*/

.portlet {
  box-shadow: 0 0 1.25rem rgba(10, 10, 10, .2);

  @include breakpoint(phablet only) {
    border-bottom-left-radius: 30px;
  }

  @include breakpoint(small only) {
    border-bottom-left-radius: 60px;
    border-top: 8px solid $green-blue;
  }

  &.portlet--no-shadow {
    box-shadow: 0 0 0 0;
  }

  &.portlet--no-rounded {
    border-bottom-left-radius: 0 !important;
  }
}

.portlet__image {
  border-bottom-left-radius: 60px;
  overflow: hidden;
  position: relative;

  @include breakpoint(small only) {
    border-bottom-left-radius: 0;
  }

  @include breakpoint(phablet only) {
    border-bottom-left-radius: 30px;
    width: 33.3333% !important; // sass-lint:disable-line no-important
  }

  img {
    display: block;
    min-height: 100%;
    min-width: 100%;

    @include breakpoint(phablet up) {
      max-width: initial;
      object-fit: cover;
    }
  }
}

.portlet__title {
  font-size: rem-calc(30);
  font-weight: $global-weight-bold;

  @include breakpoint(phablet down) {
    font-size: rem-calc(26);
  }
}

.portlet__text {

  padding: $global-padding #{$global-padding * 2};

  @include breakpoint (medium up) {
    border-top: 8px solid $green-blue;
  }
  @include breakpoint(phablet only) {
    width: 66.6666% !important; // sass-lint:disable-line no-important
  }

  @include breakpoint(phablet down) {
    border-top: 0;
  }
}
