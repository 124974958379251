// sass-lint:disable-block no-important

//adding a class of hide-for-print to any element will hide that element when printing
@media print {

  .show-for-print {
    display: block !important;
    height: auto;
    position: relative;
  }

  .hide-for-print {
    display: none !important;
  }

  a {
    text-decoration: none !important;

    [href] {

      &::after {
        content: '';
      }
    }
  }

  //elements not related to main content to be hidden while printing
  .accordion__arrow,
  .accordion__button,
  .accordion--search,
  .alphabet-list,
  .button,
  .button__container,
  .chat,
  .cta,
  .diagonals,
  .doc-profile__refine-col,
  .filter,
  .follow,
  .follow__facebook,
  .follow__icons,
  .footer-main__logo,
  .footer__social,
  .footer__utility,
  .future,
  .header__back-link,
  .hero__image,
  .hero-nav__section-container,
  .hp-portlets,
  .infographic,
  .infographic__stat-container--tabs,
  //.locations__map-canvas,
  .more-section,
  .more-section__block,
  .nav-main,
  .nav__secondary,
  .nav-utility,
  .nav-breadcrumbs__mobile-toggle,
  .nav-breadcrumbs__submenu,
  .nav-breadcrumbs__icon,
  .nav-breadcrumbs__submenu.js-dropdown-active,
  .pagination,
  .portlet,
  .quote,
  .quote__image-container,
  .search__toggle,
  .social,
  .tile__header,
  .tile__header + div,
  .updates,
  .video,
  .view-more {
    display: none !important;
  }

  .white,
  .orange,
  .gray,
  .medium-brown,
  .red,
  .green-blue,
  .medium-blue {
    color: #000 !important;
  }


  .align-center {
    justify-content: flex-start;
  }

  .accordion-content {
    border: 0 !important;
    display: block !important;
    padding: 0;
  }

  .accordion-item {
    border-left: 0;
  }

  .accordion-title {
    border: 0 !important;
    padding-bottom: $global-padding;
    padding-left: 0;
    padding-top: 0;
    text-decoration: none;

    &::before {
      content: '' !important;
    }
  }

  .accordion--microsite .accordion-item.is-active {
    border-color: #000 !important;
  }

  .article__header {
    padding-bottom: 1rem;
    padding-left: 0;
    padding-top: 1rem;

    > .row {
      margin-left: 0;
      margin-right: 0;
    }

    h1 {
      margin-top: 0;
    }
  }

  .background-padding {
    padding: 0;
  }

  .breadcrumbs {
    margin: 0 auto;
    max-width: 75rem;
  }

  .border--green {
    border: 0;
  }

  .border-green-bottom {
    border-color: #000 !important;
  }

  .box {
    padding: 1rem 0 !important;
  }

  .box--padding {
    padding: 1rem 0 !important;
  }

  .box.box--margin-left {
    margin-left: 0 !important;
  }

  *[class*='background--'],
  *[class*='bordered-element--'] {
    background: none !important;
    background-color: $white !important;
    border: 0;
  }

  .careers-info {
    display: block;
    padding: 0;
  }

  //hide checkbox on jobs results for print
  .careers-results table tr td:nth-child(1) {
    display: none;
  }

  .figure__caption {
    max-width: 400px !important;
    padding: 1rem 0;
  }

  .figure__image {
    border-bottom: 0;
    max-width: 400px !important;
  }

  .figure__video {
    display: none;
  }

  .figure.float-left,
  .figure--small,
  .figure.float-left.figure--offset {
    float: none;
    margin-bottom: $global-margin;
    margin-left: 0;
    width: auto;
  }

  .figure.float-right {
    float: none;
    margin-bottom: $global-margin;
    margin-left: 0;
    margin-right: 0;
  }

  .footer-main {
    margin-top: 3rem;
  }

  .footer-main__address {
    border-left: 0;
  }

  .footer-main__info {
    display: block;
    padding: 0;
    text-align: center;
  }

  .footer-main {
    .contact {
      display: block;
      margin: 0 auto;
      position: relative;
      right: auto;
      top: auto;
    }
  }

  .grid-margin-x > .cell {
    width: 100%;
  }

  .grid-margin-x.medium-up-2 > .cell {
    width: 100%;
  }

  .header-main__sticky {
    border: 0 !important;
    top: 0 !important;
  }

  .hero,
  .hero__wrap {
    height: auto !important;
    min-height: 0 !important;
  }

  .hero-directions {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }

  .hero__overlay {
    margin-top: 0;
  }

  .hero__textbox {
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }

  .microsite-info {
    border-bottom: 0;
  }

  .rounded-box__heading {
    height: auto;
    margin-top: 2rem;
    text-align: left;
  }

  .rounded-box__heading,
  .rounded-box__body {
    border-color: #000 !important;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .rtf {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: $global-margin;
    }

    ul {
      color: #000;
      list-style-type: circle !important;
      margin-left: 0;

      li {
        list-style-type: disc !important;

        &::before {
          content: '';
        }

        li {
          &::before {
            content: '';
          }
        }
      }
    }

    ol {
      li {
        &::before {
          color: #000;
        }
      }
    }
  }

  .tabs {
    .cell {
        width: 100% !important;
    }
  }

  .tabs-title {
    display: none !important;
  }

  .tabs-panel {
    display: block !important;
    padding-left: 0;
    padding-right: 0;
  }


  *[class*='vertical-margin-'] {
    margin-top: 1rem !important;
  }



}
