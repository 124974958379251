.updates {
  position: fixed;
  right: -55px;
  top: 248px;
  z-index: 12;

  @include breakpoint(medium down) {
    display: none;
  }
}

.updates__button {
  align-items: center;
  background-color: $orange;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  color: $white;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 52px;
  padding: 10px;
  text-transform: uppercase;
  transform: rotate(90deg);
  transition: background-color $global-transition-speed;
  width: 162px;

  .icon {
    background-color: $white;
    border-radius: 13px;
    color: $light-brown;
    height: 26px;
    pointer-events: none;
    position: relative;
    transform: rotate(-90deg);
    transition: background-color $global-transition-speed;
    width: 26px;

    &::before {
      left: 6px;
      position: absolute;
      top: 6px;
    }
  }

  &:hover,
  &:focus {
    background-color: #ed7821;
    color: $white;
  }
}

.updates__button-text {
  margin-left: 6px;
}

.updates__form {
  background-color: $medium-blue;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  color: $white;
  display: none;
  padding: #{$global-padding * 2};
  position: fixed;
  right: 0;
  top: 0;
  width: 707px;
  z-index: 11;

  &.is-open {
    display: block;
  }

  @media (max-height: 600px) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: 100vh;
    overflow-y: auto;
  }

  .close-button {
    background-color: $white;
    border-radius: 16px;
    color: $orange;
    height: 32px;
    width: 32px;
  }
}

.updates__body {
  * {
    color: $white;
  }

  h3 {
    font-size: rem-calc(25);
  }

  h4 {
    font-size: rem-calc(18);
  }
}

.updates__checkbox {
  align-items: flex-start;
  display: flex;
  margin-bottom: 4px;

  label {
    font-weight: 600;
    margin-top: -5px;
  }
}

.updates__input-wrap {
  position: relative;

  input {

    &:focus  + label {
      display: none;
    }

    &:valid + label {
      display: none;
    }
  }

  label {
    color: #656565;
    font-weight: 500;
    left: 12px;
    position: absolute;
    top: 10px;
    z-index: 1;

    span {
      color: $orange;
    }
  }
}

.updates__input {
  border: 1px solid $border-gray;
  border-radius: 8px;
  color: #656565;
  height: 46px;
}

.updates__legal {
  font-size: 13px;
}
