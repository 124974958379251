.separator--green {

  &::after {
    border: 3px solid $green-blue;
  }
}


.separator-center {
  &::after {
    transition: width 2s;
    width: 0;
  }

  &.is-animating,
  .is-animating & {
    &::after {
      width: 3rem;
    }
  }

  @include breakpoint(phablet down) {
    &::after {
      margin-top: .5rem;
      width: 3rem;
    }
  }
}
