/* YAPL
name: Upcoming Events
partial: hp-upcoming-events
selector: .upcoming-events
*/

/* YAPL
name: Upcoming Events Microsite
partial: sg-upcoming-events-microsite
selector: .upcoming-events.upcoming-events--microsite
*/


.upcoming-events {
  margin-left: 0;

  li {
    margin-top: #{$global-margin * 2};

    &:first-child {
      margin-top: 0;
    }
  }

  .upcoming-events__item {
    align-items: center;
    display: flex;

    &:hover {
      color: $orange;

      .upcoming-events__date {
        background-color: $orange;
      }
    }
  }

  .upcoming-events__item-detail {
    display: block;
    font-size: rem-calc(20);
    line-height: 1.3;
    transition: color $global-transition-speed;

    span {
      color: $text-color;
      display: block;
      font-size: rem-calc(15);
      line-height: 1.6;
    }

    @include breakpoint(medium down) {
      font-size: rem-calc(18);

      span {
        font-size: rem-calc(13);
      }
    }
  }

  .upcoming-events__date {
    align-items: center;
    background-color: $light-brown;
    border-radius: 50%;
    color: $white;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-family: $body-font-family-alt;
    font-size: rem-calc(20);
    font-weight: $global-weight-bold;
    height: 78px;
    justify-content: center;
    line-height: 1;
    margin-right: $global-margin;
    text-transform: uppercase;
    transition: background-color $global-transition-speed;
    width: 78px;

    span {
      display: block;
      font-size: rem-calc(23);
    }
  }

  &.upcoming-events--microsite {

    li {
      border-bottom: 1px dashed #cecece;
      margin-top: #{$global-margin * 1.5};

      &:last-child {
        border-bottom: 0;
      }
    }
    .upcoming-events__item {
      padding-bottom: #{$global-padding * 1.5};
    }

    .upcoming-events__date {
      background-color: $orange;
      font-size: rem-calc(16);
      height: 63px;
      width: 63px;

      span {
        font-size: rem-calc(16);
      }
    }

    .upcoming-events__item-detail {
      font-size: rem-calc(18);
    }
  }
}
