@include foundation-forms;

select,
input,
textarea {
  margin: .25rem 0;
}

label,
legend {

  &.full-width {
    width: 100%;
  }

  &.required {
    position: relative;

    .label-text {

      &::before {
        color: $red;
        content: '*';
        display: inline-block;
        font-weight: $global-weight-bold;
        margin-right: .25rem;
      }
    }
  }
}

legend {
  font-weight: 600;
  line-height: 1.8;
}

fieldset.radio-input {
  label {
    font-weight: 400;
  }
}

.select-wrap {
  background: linear-gradient(#fffeff, #e4e4e4);
  border: 1px solid $border-gray;
  border-radius: 6px;
  height: 44px;
  margin-bottom: 16px;
  position: relative;

  select {
    background: transparent;
    border: 0;
    box-shadow: 0 0 0 0;
    margin: 0;
  }

  .icon {
    background-color: $medium-blue;
    border-radius: 4px;
    color: $white;
    font-size: rem-calc(16);
    height: 32px;
    pointer-events: none;
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;

    &::before {
      left: 8px;
      position: relative;
      top: 3px;
    }
  }
}

.checkbox-wrap {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-left: 35px;
  position: relative;
  user-select: none;

  input:checked ~ .checkbox .checkbox__check {
    display: block;
  }

  label & {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 400;
  }
}

input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

input[type="radio"] {
  height: rem-calc(20);
  margin: 0;
  width: rem-calc(20);
}

.checkbox {
  background: linear-gradient(#fffeff, #e4e4e4);
  border: 1px solid $border-gray;
  border-radius: 4px;
  height: 24px;
  left: 0;
  position: absolute;
  margin-top: -12px;
  top: 50%;
  width: 24px;

  .checkbox__check {
    color: $medium-blue;
    display: none;
    font-size: 18px;
    left: 3px;
    position: absolute;
    top: -2px;
  }
}

.form__required {
  bottom: -21px;
  color: $red;
  font-size: rem-calc(13);
  position: absolute;
}
